<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  videoId: {
    type: String,
    default: "DyTCOwB0DVw",
  },
});

const player = ref(null);
const observer = ref(null);
const container = ref(null);

onMounted(async () => {
  const Plyr = await import("plyr");
  const newPlayer = new Plyr.default(player.value, {
    controls: [
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "fullscreen",
    ],
  });

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };
  observer.value = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (container.value) {
        if (entry.isIntersecting) {
          //video is in view
        } else {
          //video is not in view
          newPlayer.pause();
        }
      }
    });
  }, options);
  observer.value.observe(container.value);
});

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});
</script>

<template>
  <client-only>
    <div class="youtube-player" ref="container">
      <div
        ref="player"
        id="player"
        class="player"
        data-plyr-provider="youtube"
        :data-plyr-embed-id="videoId"
        playsinline
        controls
        crossorigin=""
      ></div>
    </div>
  </client-only>
</template>

<style lang="postcss" scoped>
.youtube-player {
  --plyr-color-main: var(--loud-indigo);
  --plyr-range-track-height: units(0.2);
}
</style>
