<script setup>
const props = defineProps({
  quote: Object,
  media: Object,
  layout: String,
  caption: String,
});
const captionRef = ref(null);
const getCaptionHeight = computed(() => {
  if (captionRef.value) {
    return captionRef.value.offsetHeight;
  }
  return 0;
});

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>
<template>
  <div class="section split-quote" :class="{
    'left-image': layout === 'left-image',
    'can-run': isVisible
  }" :style="`--caption-height:${getCaptionHeight}px`" v-view="(e) => runIntroAnimation(e)">
    <div class="split-quote__content reveal">
      <div class="split-quote__content-icon"><nuxt-icon name="quote" /></div>
      <div class="split-quote__content-desc">
        <component v-if="quote.headline" :is="quote.heading_tag?.value || 'h2'" class="ts-large-serif">{{ quote.headline
        }}</component>
        <div class="split-quote__content-title">
          <p v-if="quote.name" class="ts-caps-bold">{{ quote.name }}</p>
          <p v-if="quote.role" class="ts-caption">{{ quote.role }}</p>
        </div>
      </div>
    </div>
    <div class="split-quote__image reveal">
      <Media :media="media" :cover="true" class="media" :class="{ 'media-left': layout === 'left-image' }" />
      <p class="split-quote__image-legend ts-caption-italic" ref="captionRef">
        {{ caption }}
      </p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.split-quote {
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  display: grid;

  @media (--until-md) {
    background: #f7f6f5;
    gap: calc(units(6) + var(--caption-height));
  }

  @media (--md) {
    grid-template-columns: 1fr 1fr;
  }

  padding-bottom: calc(var(--section-margin) + var(--caption-height));

  &.left-image {
    .split-quote__image {
      @media (--md) {
        order: 1;
      }

      &-legend {
        padding-left: var(--page-margin);
        padding-right: 0;
      }
    }

    .split-quote__content {
      @media (--md) {
        order: 2;
        padding-right: var(--page-margin);
        padding-left: units(6);
      }
    }
  }

  &__image {
    position: relative;
    height: 100%;
    order: 1;
    transition-delay: 0.1s;

    @media (--md) {
      order: 2;
    }

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .media {
      @media (--md) {
        /* border-radius: units(0.5) 0px 0px units(0.5); */
        overflow: hidden;

        &-left {
          /* border-radius: 0px units(0.5) units(0.5) 0px; */
        }
      }
    }

    &-legend {
      padding-right: var(--page-margin);
      padding-top: units(1);

      @media (--until-md) {
        padding-left: var(--page-margin);
        padding-right: 0;
      }
    }
  }


  &__content {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: units(2);
    padding: units(2);

    @media (--md) {
      background: #f7f6f5;

      order: 1;
      padding: units(6);
      z-index: 2;
    }

    &-icon {
      color: var(--loud-indigo);
    }

    &-desc {
      color: var(--grey-4);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: units(5);
    }
  }
}
</style>
