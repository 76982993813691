<script setup>
const props = defineProps({
  trumpet: String,
  headline: String,
  copy: String,
  articles: Array,
});
const nuxtApp = useNuxtApp();

const images = computed(() => props.articles.map((article) => article.image));
const activeIndex = ref(0);

const setActiveIndex = (index) => {
  activeIndex.value = index;
};

onMounted(() => {
  setActiveIndex(0);
});

const openOverlay = async (path) => {
  const { getArticle } = useWpApi();
  const { data, error } = await getArticle(path, nuxtApp);
  useEvent("overlay:open", {
    data: data.value,
    type: "article",
  });
};

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin articles-list"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <ElementsIntro
      v-bind="{ trumpet, headline, copy }"
      class="articles-list__intro"
    />
    <div class="articles-list__inner">
      <div
        v-if="!$viewport.isLessThan('desktopView')"
        class="articles-list__images reveal"
      >
        <ElementsMediaStack :images="images" :activeIndex="activeIndex" />
      </div>

      <div class="articles-list__content">
        <ul class="articles-list__list">
          <li
            class="articles-list__item reveal"
            :class="{ active: activeIndex === index }"
            :style="`transition-delay: ${index * 0.1}s`"
            v-for="(article, index) in articles"
            @click="openOverlay(article.relative_link)"
            @mouseover="setActiveIndex(index)"
          >
            <div
              v-if="$viewport.isLessThan('desktopView')"
              class="articles-list__media"
            >
              <Media :media="{ image: article.image }" :cover="true" />
            </div>

            <div class="articles-list__content">
              <h3 class="articles-list__title ts-h4">
                {{ article.title }}
              </h3>
              <p v-if="!$viewport.isLessThan('desktopView')" class="ts-p1">
                {{ article.excerpt }}
              </p>
            </div>
            <div class="icon">
              <SvgOpenOverlay />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.articles-list {
  --line-color: var(--grey-1);
  --txt-color: var(--grey-4);
  --icon-rotation: 0deg;
  --icon-color: var(--black);
  --translate-title: units(1.5);
  --opacity-title: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--section-margin);

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* padding-top: units(3); */

    @media (--md) {
      display: grid;
      column-gap: units(2);
      grid-template-columns: repeat(12, 1fr);
      /* padding-top: units(9); */
    }
  }

  &__images {
    position: relative;
    height: fit-content;
    margin-bottom: units(3);
    aspect-ratio: 1;

    @media (--md) {
      grid-column: span 6;
      margin: 0 units(6) 0 units(2);
    }
  }

  &__media {
    width: units(8);
    /* height: units(8); */
    aspect-ratio: 1/1;
    /* margin-right: units(2); */
    flex-shrink: 0;
    border-radius: units(1);
    overflow: hidden;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: fit-content;
    color: var(--txt-color);

    @media (--md) {
      grid-column: span 6;
    }

    p {
      margin-top: units(2);
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
    }
  }

  &__list {
    @media (--md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: units(2);
    }
  }

  &__title {
    margin-right: units(2);
    transition: color 0.3s ease-in-out;
  }

  &__item {
    cursor: pointer;
    padding: units(2) 0 units(2) 0;
    position: relative;
    display: flex;
    gap: units(2);
    align-items: center;

    @media (--md) {
      padding: units(2) 0 units(6) 0;
      align-items: start;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background: var(--line-color);
      transition: background 0.3s ease-in-out;
    }

    &:hover {
      --line-color: var(--loud-indigo);
      --txt-color: var(--loud-indigo);
      --icon-rotation: 45deg;
      --icon-color: var(--loud-indigo);
    }
  }

  .icon {
    transition: transform 0.3s ease-in-out;
    transform: rotate(var(--icon-rotation));
    line-height: 0;

    svg {
      transition: color 0.3s ease-in-out;
      width: 20px;
      color: var(--icon-color);
    }
  }
}

.active {
  --line-color: var(--loud-indigo);
  --txt-color: var(--loud-indigo);
  --icon-rotation: 45deg;
  --icon-color: var(--loud-indigo);
}
</style>
