<script setup>
import gsap from "gsap";

const props = defineProps({
  events: {
    type: [Array, Object],
  },
  years: {
    type: [Array, Object],
  },
  currentEvent: {
    type: [Array, Object],
  },
});
const timelineContainer = ref(null);
const defaultHeight = "7px";
const expandedHeight = "60px";

const createWaveEffect = (index, limit = 20) => {
  const lines = timelineContainer.value.querySelectorAll(".line");
  const clickedLine = lines[index];

  //right direction
  let rightCounter = 0;
  for (let i = index - 1; i >= 0; i--) {
    if (rightCounter >= limit) break;
    const line = lines[i];
    const delay = (index - i) * 0.1;
    gsap.to(line, {
      scaleY:
        line.style.height > defaultHeight
          ? line.style.height * Math.random()
          : 10 * Math.random(),
      transformOrigin: "bottom",
      duration: 0.4,
      ease: "sine.inOut",
      delay: delay,
      onComplete: () => {
        gsap.to(line, {
          scaleY: 1,
          duration: 0.4,
        });
      },
    });
    rightCounter++;
  }

  //left direction
  let leftCounter = 0;
  for (let i = index + 1; i < lines.length; i++) {
    if (leftCounter >= limit) break;
    const line = lines[i];
    const delay = (i - index) * 0.1;
    gsap.to(line, {
      scaleY: 10 * Math.random(),
      transformOrigin: "bottom",
      duration: 0.4,
      ease: "sine.inOut",
      delay: delay,
      onComplete: () => {
        gsap.to(line, {
          scaleY: 1,
          duration: 0.4,
        });
      },
    });
    leftCounter++;
  }
};

const emit = defineEmits(["clicked-event"]);

function clickedEvent(item) {
  emit("clicked-event", item);
}

const getLineStyle = (year) => {
  if (!process.client) return;
  const event = props.events?.find((event) => event.year === year);
  if (event) {
    const randomHeight = Math.floor(Math.random() * (30 - 15 + 1)) + 15;

    if (props.currentEvent?.year === event.year) {
      return {
        height: `${expandedHeight}`,
      };
    } else {
      return {
        height: `${randomHeight}px`,
      };
    }
  }
  return {
    height: `${defaultHeight}`,
  };
};
const viewport = useViewport();
const timeline = ref(null);
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}

watch(
  () => isVisible.value,
  () => {
    createWaveEffect(Math.floor((props.years.length - 1) / 2), 30);
  }
);

const findActiveYear = () => {
  const activeYear = props.years?.find((year) => {
    const event = props.events?.find((event) => event.year === year);
    if (event) {
      return event?.year === props.currentEvent.year;
    }
    return false;
  });
  return activeYear;
};
const findActiveYearLineElement = () => {
  const activeYear = findActiveYear();
  const activeYearElement = timelineContainer.value.querySelector(
    `.line:nth-child(${props.years.indexOf(activeYear) + 1})`
  );
  return activeYearElement;
};
const scrollToActiveYear = () => {
  const activeYearElement = findActiveYearLineElement();
  if (activeYearElement) {
    const activeYearElementPosition = activeYearElement.offsetLeft;

    timeline.value.scrollTo({
      left: activeYearElementPosition - timeline.value.offsetWidth / 2,
      behavior: "smooth",
    });
  }
};

watch(
  () => props.currentEvent,
  () => {
    if (!process.client) return;
    viewport.isLessThan("desktopView") ? scrollToActiveYear() : null;
  }
);
</script>

<template>
  <div class="timeline" :class="{
    'can-run': isVisible,
  }" ref="timeline" v-view="(e) => runIntroAnimation(e)">
    <div class="timeline-container" ref="timelineContainer">
      <div class="line" v-for="(year, index) in years" :key="year" :class="{
        'active-year': currentEvent?.year === year,
        'has-event': events?.find((event) => event.year === year),
      }" @click="
          {
  const event = events?.find((event) => event.year === year);
  if (event) {
    currentEvent = event;
    createWaveEffect(index);
    clickedEvent(event);
  }
}
  " :style="events?.find((event) => event.year === year)
    ? getLineStyle(year)
    : null
    ">

        <div class="inner">
          <div class="inner-line"></div>
          <div class="tooltip ts-p1"
            v-if="events?.find((event) => event.year === year) && !$viewport.isLessThan('desktopView')">{{ year }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.timeline {
  --tooltip-opacity: 0;
  position: relative;
  width: 100%;
  height: units(8);

  position: relative;
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 50% units(2) 50%;


  @media (--md) {
    padding: 0;
    overflow-x: unset;
    overflow-y: unset;
    padding: unset;
  }

  &-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: units(1);
    align-items: flex-end;
    scroll-behavior: smooth;
    height: 100%;
    width: fit-content;

    @media (--md) {
      justify-content: space-around;
      width: 100%;
      gap: unset;
    }
  }

  .line .inner {
    width: 100%;
    height: 100%;
  }



  .line:has(+ .line:hover) .inner-line,
  .line:hover+.line .inner-line {
    @media (--md) {
      transform: scale(1.2) translateY(-3px);
    }
  }

  .line:hover .inner-line {
    @media (--md) {
      transform: scale(1.6) translateY(-8px);

    }
  }

  .line:hover {
    @media (--md) {
      --tooltip-opacity: 1;

      .tooltip {

        transform: translateY(-150%) translateX(-50%);
      }
    }
  }

  .inner {
    &-line {
      height: 100%;
      width: 100%;
      background-color: var(--white);
      border-radius: units(1);

      transition: all 0.2s linear;
      transform-origin: bottom;
    }
  }

  .tooltip {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    width: units(5);
    height: units(3);
    background-color: white;
    opacity: var(--tooltip-opacity);
    transition: all .2s ease-in-out;
    z-index: 9;
    border-radius: units(5);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    pointer-events: none;


    color: var(--loud-indigo);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line {
    transition: all 0.2s linear;
    position: relative;
    width: 2px;
    height: 7px;
    transform-origin: bottom;

    &:before {
      content: "";
      position: absolute;
      width: 500%;
      height: 1000%;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      z-index: 1;
      /* border: 1px solid green; */
    }
  }

  .has-event {
    cursor: pointer;
  }
}
</style>
