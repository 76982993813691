<script setup>
const props = defineProps({
  media: [Object, null],
  cover: Boolean,
  loop: Boolean,
  paused: Boolean,
  isNotLazyVideo: Boolean,
  lazyImage: {
    type: Boolean,
    default: true,
  },
  posterVisible: {
    type: Boolean,
    default: false,
  },
  useThumbnail: {
    type: Boolean,
    default: false,
  },
});

const type = computed(() =>
  props.media.video &&
  (props.media.video?.mp4_video || props.media.video?.youtube_url)
    ? "video"
    : "image"
);

const components = {
  image: resolveComponent("image"),
  video: resolveComponent("video"),
};

provide("loop", props.loop);
provide("paused", props.paused);
provide("isNotLazyVideo", props.isNotLazyVideo);
provide("lazyImage", props.lazyImage);
provide("poster", props.media?.video?.poster_image.url);
provide("posterVisible", props.posterVisible);
provide("useThumbnail", props.useThumbnail);

const element = ref(null);
</script>

<template>
  <div class="media" :class="{ 'media--cover': cover }">
    <component
      ref="element"
      v-if="media"
      :media="media[type]"
      :is="components[type]"
    />
  </div>
</template>

<style lang="postcss" scoped>
.media {
  height: 100%;

  &--cover {
    &:deep(video) {
      object-fit: cover;
    }

    &:deep(img) {
      object-fit: cover;
      height: 100%;
    }
  }
}
</style>
