<script setup>
const config = useRuntimeConfig();
const ssApi = config.public.apiSs;

const props = defineProps({
  dataType: String, // "articles"/"press-releases"
  pagination: [Number, null],
  searchEnabled: {
    type: Boolean,
    default: false,
  },
  paginationEnabled: {
    type: Boolean,
    default: true,
  },
  shouldBeCarousel: {
    type: Boolean,
    default: false,
  },
});

const searchInput = ref(null);
const searchTerm = ref(null);
const page = ref(1);
const paginationInteger = ref(props.pagination);
const paginationActive = ref(props.paginationEnabled);
const limit = ref(page.value * paginationInteger.value);

const initialLoad = ref(true);
const somethingIsLoading = ref(false);

const pendingGhostContent = ref([{}, {}, {}]);

const {
  data: listData,
  error,
  pending,
} = await useLazyAsyncData(
  `listData-${props.dataType}`,
  () =>
    $fetch(`${ssApi}/${props.dataType}`, {
      method: "GET",
      params: {
        limit: limit.value,
        // limit: 10,
        // offset: page.value * props.pagination,
        // page: page.value,
        search: searchTerm.value,
      },
    }),
  {
    watch: [page, searchTerm],
  }
);

const loadMoreShown = computed(
  () =>
    paginationActive.value &&
    listData.value?.items.length &&
    page.value * paginationInteger.value < listData.value?.settings.totalCount
);

const loadMore = (e) => {
  e.preventDefault();
  page.value++;
  limit.value = page.value * paginationInteger.value;
};

watch(pending, () => {
  if (!pending.value && initialLoad.value) {
    initialLoad.value = false;
  }
  somethingIsLoading.value = !!(pending.value && !initialLoad.value);
});

watch(searchTerm, () => {
  if (searchTerm.value.length > 0) {
    page.value = 0;
    paginationInteger.value = 0;
    paginationActive.value = false;
    limit.value = 0;
  } else {
    // COMMENT: revert to initial state
    page.value = 1;
    paginationActive.value = props.paginationEnabled;
    paginationInteger.value = props.pagination;
    limit.value = page.value * paginationInteger.value;
  }
});

const search = ref(null);

const searchTotalResultsCount = computed(() => {
  if (searchTerm.value && listData.value) {
    return listData.value.settings.totalCount;
  }
  return 0;
});

const noResults = computed(
  () => !listData.value?.items?.length && searchTerm.value?.length === 0
);
const noSearchResults = computed(
  () => !listData.value?.items?.length && searchTerm.value?.length > 0
);

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive ? (isVisible.value = true) : null;
}
const updateInputChange = (value) => {
  searchTerm.value = value;
  search.value = searchTerm.value.value;
  // console.log("updateInputChange", searchTerm.value.value, search.value);
};
const updatePerformSearch = (value) => {
  searchTerm.value = value;
  search.value = searchTerm.value.value;
};
</script>

<template>
  <div
    class="list-data"
    :class="{
      'can-run': isVisible,
      'has-cta': loadMoreShown,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <ElementsSearch
      v-if="searchEnabled"
      class="search reveal"
      :search="search"
      @perform-search="updatePerformSearch"
      @input-change="updateInputChange"
    />

    <transition name="fade" mode="out-in">
      <div v-if="pending && initialLoad">
        <div v-if="dataType === 'press-releases'">
          <ElementsGrid :columns="1" :gap="0">
            <template #items>
              <ElementsPressReleaseCardGhost
                v-for="(content, index) in pendingGhostContent"
                class="reveal"
                :style="`transition-delay: ${index * 0.1}s`"
                :key="index"
              />
            </template>
          </ElementsGrid>
        </div>
        <div v-if="dataType === 'articles'">
          <ElementsGrid :columns="$viewport.isLessThan('desktopView') ? 1 : 3">
            <template #items>
              <ElementsCardNewsGhost
                v-for="(content, index) in pendingGhostContent"
                class="reveal"
                :style="`transition-delay: ${index * 0.1}s`"
                :key="index"
              />
            </template>
          </ElementsGrid>
        </div>
      </div>
      <div v-else>
        <div
          class="list"
          :class="{
            '-loading': somethingIsLoading,
            'has-cta': loadMoreShown,
          }"
        >
          <ElementsGrid
            :columns="1"
            :gap="0"
            v-if="dataType === 'press-releases'"
          >
            <template #items>
              <ElementsPressReleaseCard
                v-for="(pressRelease, index) in listData.items"
                class="reveal"
                :style="`transition-delay: ${index * 0.1}s`"
                :key="index"
                v-bind="pressRelease"
              />
            </template>
          </ElementsGrid>

          <ElementsGrid
            :columns="$viewport.isLessThan('desktopView') ? 1 : 3"
            v-if="dataType === 'articles'"
          >
            <template #items>
              <ElementsCardNews
                v-for="(article, index) in listData.items"
                class="reveal"
                :style="`transition-delay: ${index * 0.1}s`"
                :key="index"
                :acf="article?.acf"
                :post_title="article?.post_title"
                :link="article?.link"
              />
            </template>
          </ElementsGrid>

          <!-- <standard-carousel v-else>
              <template v-slot:slide>
                <ElementsCardNews
                  v-for="(article, index) in listData.items"
                  :key="index"
                  :acf="article?.acf"
                  :post_title="article?.post_title"
                  :link="article?.link"
                  class="standard-carousel__gallery-item cards-item"
                />
              </template>
            </standard-carousel> -->

          <ButtonCTA
            class="cta reveal"
            type="primary"
            label="Load more"
            :hasIcon="true"
            icon="arrowsimple"
            @click="loadMore($event)"
            v-if="loadMoreShown"
          />
        </div>

        <transition name="fade">
          <div class="no-results" v-if="noResults">No results</div>
        </transition>
        <transition name="fade">
          <div class="no-results" v-if="noSearchResults">
            No results from the current search parameters
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.list-data {
  position: relative;

  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  transition: padding-bottom 0.25s ease;
  padding-bottom: units(3);
  @media (--md) {
    padding-bottom: units(6);
  }

  &.has-cta {
    padding-bottom: 0;
  }
}

.search {
  @media (--until-md) {
    margin-top: units(-3);
    margin-bottom: units(4);
  }

  @media (--md) {
    position: absolute;
    top: units(-10);
    right: 0;
    text-align: right;
  }

  /* padding-right: units(2); */
}

.no-results {
  text-align: center;
  padding: units(6);
}

.list {
  color: var(--grey-4);
  transition: opacity 0.25s ease;
  opacity: 1;
  padding-bottom: units(3);

  &.has-cta {
    display: flex;
    flex-direction: column;
    gap: units(3);
    margin-bottom: units(3);
  }

  &.-loading {
    opacity: 0.6;
    pointer-events: none;
  }

  @media (--md) {
    padding-bottom: 0;
    grid-template-columns: repeat(2, 1fr);

    &.three-columns {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .cta {
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: units(2);
    padding-bottom: units(3);
    padding-top: units(2);

    @media (--md) {
      padding: units(6) 0;
    }

    &.no-title {
      align-items: flex-start;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background: var(--grey-1);
    }

    .icon {
      max-width: 30px;
    }
  }

  &__content {
    p {
      margin-top: units(2);
    }
  }

  .standard-carousel__gallery-item {
    padding: 0;

    &:deep(.card-news) {
      height: 100%;
    }

    &:deep(.link) {
      height: 100%;
    }

    &:deep(.card-news-wrapper) {
      height: 100%;
    }
  }
}
</style>
