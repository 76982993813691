<script setup>
import { ref, defineComponent, h, useAttrs, useSlots } from "vue";
import RTE from "@/components/common/RTE.vue";

const props = defineProps({
  trumpet: String,
  headline: String,
  heading_tag: [Object, Boolean, null],
  copy: String,
  source: String,
  narrow: {
    type: Boolean,
    default: false,
  },
});

const headingTag = computed(() => {
  return props.heading_tag ? props.heading_tag.value : "h2";
});
const DynamicComponent = defineComponent({
  render() {
    return h(
      headingTag.value,
      { class: "ts-h2 headline reveal" },
      {
        default: () => [
          props.headline
            ? h(RTE, { data: props.headline, outputTag: "span" })
            : null,
        ],
      }
    );
  },
});

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <div
    v-if="headline"
    class="intro"
    :class="{
      'intro--narrow': narrow,
      'can-run': isVisible,
      'count-intro': trumpet !== '',
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div v-if="trumpet" class="intro__header reveal">
      <div class="ts-tag intro__count"></div>
      <span class="ts-tag">{{ trumpet }}</span>
    </div>

    <div class="intro__content">
      <dynamic-component v-if="headline" />
      <div class="text reveal" v-if="copy || source">
        <p v-if="copy" v-html="copy" class="ts-p1"></p>
        <p v-if="source" class="ts-caption-italic">
          {{ source }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.intro {
  --translate-title: units(1.5);
  --opacity-title: 0;
  --dot-color: var(--loud-indigo);
  padding: units(9) 0 units(6) 0;
  color: var(--grey-4);

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  &__header {
    margin-bottom: units(3);
  }

  &__count {
    display: inline-flex;
    color: var(--grey-2);
    align-items: center;

    &::after {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: var(--dot-color);
      margin: 0 8px;
    }
  }

  &__content {
    display: grid;
    row-gap: units(6);
    @media (--md) {
      grid-template-columns: 1fr 1fr;
      column-gap: units(2);
    }

    .text {
      display: grid;
      gap: units(1);
    }
    .headline {
      word-break: break-word;
      @media (--md) {
        width: 70%;
      }
    }
  }

  &--narrow {
    .intro__content {
      grid-template-columns: 1fr;
      @media (--md) {
        width: 33%;
      }
    }
  }
}
</style>
