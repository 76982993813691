/**
 * A composable function for interacting with the WordPress API.
 * @returns {Object} An object containing various methods for fetching data from the WordPress API.
 */
export default () => {
  const cacheExpiration = 60 * 60 * 1000 // 1 hour
  const route = useRoute()
  const config = useRuntimeConfig()
  const wpApi = config.public.apiWp

  const isPreview = computed(() => !!route.query.preview)

  const get = (endpoint, nuxtApp) => {
    const url = computed(() => `${wpApi}/${endpoint}`)
    return useFetch(url.value, {
      key: `${url.value}${route.query ? JSON.stringify(route.query) : ''}`,
      lazy: false,
      // default: (data) => data[0],
      transform(data) {
        return {
          ...data && data.length ? data[0] : data,
          fetchedAt: new Date(),
          notFound: data.length === 0
        }

      },
      getCachedData(key) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        // If data is not fetched yet
        if (!data) {
          // Fetch the first time
          return
        }

        // Is the data too old?
        const expirationDate = new Date(data.fetchedAt)
        expirationDate.setTime(expirationDate.getTime() + cacheExpiration)
        const isExpired = expirationDate.getTime() < Date.now()
        if (isExpired) {
          // Refetch the data
          return
        }

        return data
      },

    })
  }

  const getPreview = (postType = 'pages') => {
    const id = route.query.id;
    const revision = route.query.revision;
    const preview = route.query.preview;

    // console.log(id, revision, preview)

    const url = `${wpApi}/${postType}/${id}/revisions/${revision}` // `http://wsa-backend.test/wp-json/wp/v2/pages/${id}/revisions/${revision}`
    return useFetch(url, {
      headers: {
        Authorization: `Bearer ${preview}`
      },
      key: `${url}${route.query ? JSON.stringify(route.query) : ''}`,
      lazy: true,
      // default: (data) => data[0],
      // transform: (data) => data && data.length ? data[0] : data,
    })

  }

  const getPageBySlug = (slug, nuxtApp) => { // COMMENT: Only for homepage
    // TODO: Make getPreview for homepage, if that doesn't already happen via getPage preview (as the path is in the url and [...slugs] is used and not index.vue)
    return get(`pages?slug=${slug}`, nuxtApp)
  }

  const getPage = (path, nuxtApp) => { // ... by path
    if (isPreview.value) return getPreview('pages')
    // const { data, error } = get(`pages?path=${path}`)
    return get(`pages?path=${path}`, nuxtApp)
  }

  // COMMENT/NOTE: Any ACF custom post-type gets its own endpoint 🥳
  const getArticle = (path, nuxtApp) => {
    if (isPreview.value) return getPreview('articles')
    return get(`articles?path=${path}`, nuxtApp)
  }

  const getPressRelease = (path, nuxtApp) => {
    if (isPreview.value) return getPreview('press-releases')
    return get(`press-releases?path=${path}`, nuxtApp)
  }

  const getJobsCount = (nuxtApp) => {
    return get('get-jobs', nuxtApp);
  }

  return {
    // getPost,
    getPage,
    getPageBySlug,
    // getPostBySlug,
    getArticle,
    getPressRelease,
    getJobsCount
  }

}
