title
<script setup>
const props = defineProps({
  trumpet: String,
  headline: String,
  published_date: String,
  modified_date: String,
  image: {
    type: Object,
  },
});
const { formatDate } = usePublishedDate();
const formattedDate = computed(() =>
  formatDate({
    value: {
      date: props.published_date,
      // modified: props.modified_date
    },
  })
);
</script>

<template>
  <div class="article-hero">
    <div
      class="article-hero__wrapper"
      :class="{ 'page-margin': !$viewport.isLessThan('desktopView') }"
    >
      <div
        class="article-hero__content"
        :class="{ 'page-margin': $viewport.isLessThan('desktopView') }"
      >
        <div class="titles">
          <p class="ts-caption-italic">{{ trumpet }}</p>
          <h1
            v-if="!$viewport.isLessThan('desktopView')"
            class="ts-h1 title"
            v-html="headline"
          ></h1>
          <h1 v-else class="ts-h2 title" v-html="headline"></h1>
        </div>
        <div class="date">
          <p class="ts-caps">{{ formattedDate }}</p>
        </div>
      </div>
      <div class="article-hero__media" v-if="image?.url">
        <Media
          v-if="image?.url"
          :media="{ image: image }"
          :cover="true"
          :key="image.url"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.article-hero {
  background-color: var(--loud-indigo);
  color: var(--white);
  width: 100%;
  height: units(42);

  margin-bottom: units(1.5);

  @media (--md) {
    height: units(58);
    margin-bottom: units(6);
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 0;

    @media (--md) {
      padding-top: units(6);
      gap: units(10);
      flex-direction: row;
    }
  }

  &__media {
    height: 100%;
    width: 100%;

    @media (--md) {
      width: 50%;
      border-radius: units(0.5) units(0.5) 0 0;
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: units(2);
    padding-top: units(6);
    padding-bottom: units(3);

    @media (--md) {
      padding-top: units(10);
      padding-bottom: units(6);
      width: 50%;
    }
  }

  .titles {
    display: flex;
    flex-direction: column;
    gap: units(2);

    @media (--md) {
      gap: units(3);
    }
  }

  .date {
    display: none;

    @media (--md) {
      display: block;
    }
  }
}
</style>
