<script setup>
const props = defineProps({
  teasers: [Array, Boolean],
});

const images = computed(() => props.teasers.map((teaser) => teaser.image));
const activeIndex = ref(0);

const setActiveIndex = (index) => {
  activeIndex.value = index;
};
const updateIndex = (index) => {
  setActiveIndex(index);
};
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin topic-teasers"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="topic-teasers__inner">
      <div
        v-if="!$viewport.isLessThan('desktopView')"
        class="topic-teasers__images reveal"
      >
        <ElementsMediaStack :images="images" :activeIndex="activeIndex" />
      </div>

      <div class="topic-teasers__content">
        <ElementsAccordionTabs>
          <ElementsAccordionTab
            v-for="(teaser, index) in teasers"
            :key="index"
            :index="index"
            :border="true"
            @update-index="updateIndex"
            class="reveal"
            :style="`transition-delay: ${index * 0.1}s`"
          >
            <template #header>
              <div class="teaser-headline ts-h4">
                {{ teaser.headline }}
              </div>
            </template>

            <template #content>
              <div class="teaser-content ts-p1">
                {{ teaser.copy }}
              </div>
            </template>
          </ElementsAccordionTab>
        </ElementsAccordionTabs>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.topic-teasers {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: flex;
  flex-direction: column;
  @media (--md) {
    padding-bottom: units(9);
    max-height: 810px;
  }

  &__bar {
    overflow: scroll;
    display: flex;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (--md) {
      display: grid;
      column-gap: units(2);
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__images {
    position: relative;
    height: fit-content;
    margin-bottom: units(3);
    @media (--md) {
      order: 1;
      grid-column: span 6;
      margin: 0 3.75rem 0 1.25rem;
    }

    aspect-ratio: 1;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: units(4);
    @media (--md) {
      order: 2;
      grid-column: span 6;
    }

    & .accordion-tab {
      &:after {
        color: var(--grey-3);
      }
      &:hover {
        color: var(--loud-indigo);
        &:after {
          color: var(--grey-3);
        }
      }
    }
  }

  .accordion-tab {
    color: var(--grey-3);

    &.expanded {
      color: var(--loud-indigo);
    }
  }

  .teaser-content {
    color: var(--grey-4);
    padding-top: units(3);
  }
}
</style>
