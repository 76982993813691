<script setup>
const props = defineProps({
  team_members: [Object, Array],
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section meet-the-team"
    :class="{
      'page-margin': !$viewport.isLessThan('desktopView'),
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div
      class="meet-the-team__people"
      v-if="!$viewport.isLessThan('desktopView')"
    >
      <elements-career-team-quote
        v-for="(person, index) in team_members"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
        :key="index"
        :person="person"
      />
    </div>
    <div class="meet-the-team__people" v-else>
      <standard-carousel>
        <template v-slot:slide>
          <elements-career-team-quote
            v-for="(person, index) in team_members"
            :key="index"
            :person="person"
            class="standard-carousel__gallery-item reveal"
            :style="`transition-delay: ${index * 0.1}s`"
          />
        </template>
      </standard-carousel>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.meet-the-team {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  padding-bottom: var(--section-margin);

  display: flex;
  flex-direction: column;
  gap: units(6);
  height: 100%;
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: units(6);
    @media (--md) {
      flex-direction: row;
      & > * {
        flex: 1;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: red;
      @media (--md) {
        display: none;
      }
    }
  }
  &__people {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: units(2);
  }

  &:deep(.standard-carousel__gallery) {
    height: 100%;
  }

  .standard-carousel__gallery-item {
    padding: 0;
    &:deep(.career-team-quote__media) {
      /* flex: 1; */
    }
    &:deep(.youtube-player) {
      height: 100%;
    }
    &:deep(.plyr) {
      height: 100%;
    }
  }
}
</style>
