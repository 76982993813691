<script setup>
const props = defineProps({
  title: String,
});
const close = () => {
  useEvent("overlay:close");
};
</script>

<template>
  <header class="header-overlay solid page-margin">
    <div
      class="header-overlay__title ts-caption-italic"
      v-html="title || ''"
    ></div>

    <div class="header-overlay__btn">
      <SvgClose class="icon" @click="close" />
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.header-overlay {
  --svg-color: var(--white);
  position: sticky;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--loud-indigo);

  &__title {
    color: var(--white);
  }
  &__btn {
    cursor: pointer;
  }
  .icon {
    width: units(2);
    height: units(2);
    fill: var(--svg-color);
  }
}
</style>
