<script setup>
const props = defineProps({
  employee: [Array, Object],
  mobileCard: {
    type: Boolean,
    default: false,
  },
  isManagement: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
//schema is required only for the leadership page
if (route.path === "/about/leadership/") {
  if (props.isManagement && props.employee) {
    useSchemaOrg([
      definePerson({
        "@context": "https://schema.org/",
        "@id": props.employee?.name,
        "@type": "OrganizationRole",
        namedPosition: props.employee?.role,
        name: props.employee?.name,
        image: props.employee?.image?.url,
        description: props.employee?.copy,
      }),
    ]);
  }
}
</script>

<template>
  <div
    v-if="employee"
    class="employee-card"
    :class="{ 'mobile-card': mobileCard }"
  >
    <div class="employee-card__media">
      <Media :media="{ image: employee?.image }" :cover="true" />
    </div>
    <div
      class="employee-card__content"
      :class="{ 'is-management': isManagement }"
    >
      <div class="titles">
        <p class="ts-caption">{{ employee?.role }}</p>
        <h3 class="ts-h2">{{ employee?.name }}</h3>
      </div>
      <div v-if="isManagement" class="copy">
        <p class="ts-p1" v-html="employee.copy" />
      </div>
      <div v-else class="contact">
        <CustomLink
          v-if="employee?.phone"
          class="ts-caption link"
          :to="`tel:` + employee?.phone"
          :target="'blank'"
          :prefetch="true"
        >
          <template #label>
            <span
              class="ts-caption"
              :href="`tel:${employee?.phone}`"
              aria-label="Employee phone number"
            >
              {{ employee?.phone }}
            </span></template
          >
        </CustomLink>
        <CustomLink
          v-if="employee?.email"
          class="ts-caption link"
          :to="`mailto:` + employee?.email"
          :target="'blank'"
          :prefetch="true"
        >
          <template #label>
            <span class="ts-caption">
              {{ employee?.email }}
            </span></template
          >
        </CustomLink>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.employee-card {
  display: flex;
  flex-direction: column;
  border-radius: units(1);
  overflow: hidden;
  background-color: var(--grey-0);
  min-height: units(42);

  @media (--md) {
    flex-direction: row;
  }

  &.mobile-card {
    flex-direction: column;

    @media (--md) {
      min-height: units(50);
    }

    .employee-card__media {
      height: units(27);
      width: auto;

      @media (--md) {
        height: units(35);
      }
    }

    .employee-card__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: units(2);
      flex: 1;
      gap: units(2);

      &.is-management {
        justify-content: unset;
      }
    }
  }

  &__media {
    @media (--md) {
      width: 50%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: units(2);
    gap: units(2);

    @media (--md) {
      width: 50%;
      padding: units(8);
    }
  }

  .titles {
    display: flex;
    flex-direction: column;
    gap: units(1);
    color: var(--grey-4);
  }

  .contact {
    color: var(--loud-indigo);

    &:deep(.link) {
      justify-content: flex-start;
    }
  }

  .copy {
    color: var(--grey-4);
  }
}
</style>
