<script setup>
const props = defineProps({
  headline: String,
  copy: String,
  source: String,
  numbers: Array,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin numbers-cards"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="numbers-cards__content">
      <div class="numbers-cards__content-main">
        <h2 v-if="headline" class="ts-h4">
          {{ headline }}
        </h2>
        <p v-if="copy" class="ts-p1">
          {{ copy }}
        </p>
      </div>

      <div class="numbers-cards__content-small">
        <p class="ts-caption-italic">
          {{ source }}
        </p>
      </div>
    </div>
    <div class="numbers-cards__cards">
      <div
        class="numbers-cards__card reveal"
        v-for="(number, index) in numbers"
        :style="`transition-delay: ${index * 0.1}s`"
      >
        <div class="numbers-cards__card-number ts-numbers">
          {{ number.number }}
        </div>
        <div class="numbers-cards__card-text ts-p1">
          {{ number.description }}
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.numbers-cards {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  gap: units(3);
  display: grid;
  background: var(--grey-0);
  padding-top: units(6);
  padding-bottom: units(6);

  @media (--md) {
    grid-template-columns: repeat(12, 1fr);
    gap: units(6);
    padding-top: units(9);
    padding-bottom: units(9);
  }

  &__content {
    display: flex;
    flex-direction: column;
    /* gap: units(3); */
    @media (--md) {
      grid-column: 4 span;
    }
    &-main {
      display: flex;
      flex-direction: column;
      gap: units(3);
    }
    &-small {
      padding-top: units(4);
      @media (--md) {
        margin-top: auto;
      }
    }
  }

  &__cards {
    display: grid;
    gap: units(2);
    @media (--md) {
      grid-column: 8 span;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__card {
    background-color: var(--white);
    border-radius: units(1);
    padding: units(3);

    @media (--until-md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: units(2);
    }

    &-number {
      color: var(--loud-indigo);
    }
    &-text {
    }
  }
}
</style>
