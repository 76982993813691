<script setup>
</script>

<template>
  <svg width="20px" height="20px" viewBox="0 0 21.2 21.2" style="fill: currentColor;">
    <path d="M10.6 21.2C4.8 21.2 0 16.5 0 10.6S4.8 0 10.6 0s10.6 4.8 10.6 10.6-4.7 10.6-10.6 10.6zm0-20c-5.2 0-9.4 4.2-9.4 9.4S5.4 20 10.6 20s9.4-4.2 9.4-9.4c0-5.1-4.2-9.4-9.4-9.4z"/>
    <path d="M10.6 15c-.2 0-.3-.1-.4-.2L4.8 9.4l.9-.9 5 5 5-5 .9.9-5.4 5.4c-.3.1-.4.2-.6.2z"/>
  </svg>
</template>

<style lang="postcss" scoped>

</style>
