<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  border: {
    type: Boolean,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["update-index"]);

const register = inject("accordion-register");

const { expanded, toggle, unregister } = register();
const content = ref(null);
onUnmounted(unregister);

watch(
  () => expanded.value,
  () => {
    if (expanded.value) {
      content.value.style.height = `${content.value.scrollHeight + 20}px`;
      emit("update-index", props.index);
    } else {
      content.value.style.height = `0px`;
    }
  }
);

const isHeader = inject("is-header", null);
</script>

<template>
  <div class="accordion-tab" :class="{ expanded, 'has-border': border }" :role="!isHeader ? 'tab' : null">
    <div v-if="title" class="accordion-tab__header ts-h4" @click="toggle()">
      {{ title }}
      <span class="accordion-tab__icon">
        <SvgAccordionArrow />
      </span>
    </div>
    <div v-else class="accordion-tab__header ts-h4" @click="toggle()">
      <slot name="header" />
      <span class="accordion-tab__icon">
        <SvgAccordionArrow />
      </span>
    </div>
    <div role="tabpanel" ref="content" class="accordion-tab__body ts-p1"
      :class="{ 'accordion-tab__body-expanded': expanded }">
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.accordion-tab {
  display: flex;
  flex-direction: column;

  &__header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: color 0.3s ease-in-out;
  }

  &__icon {
    transition: rotate 0.3s ease-in-out, fill 0.3s ease-in-out;
    height: fit-content;
    line-height: 0;
    fill: currentColor;
  }

  &__body {
    overflow: auto;
    height: 0;
    transition: height 0.3s ease-in-out, color 0.3s ease-in-out,
      margin 0.3s ease-in-out;
    overflow-y: hidden;
  }

  &.has-border {
    padding: units(2) 0;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      background: currentColor;
    }

    &:before {
      top: 0;
    }

    &:after {
      display: none;
    }

    &:last-of-type {
      &:after {
        bottom: 0;
        display: block;
      }
    }

  }

}

.expanded {
  .accordion-tab__icon {
    rotate: 180deg;
  }

  .accordion-tab__body {
    height: 100%;
  }
}
</style>
