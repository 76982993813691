<script setup>
const props = defineProps({
  items: [Array, Object],
  headline: String,
});

const overviewLimit = 6;

const showMore = ref(true);

const showAll = () => {
  showMore.value = false;
};
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>
<template>
  <section
    class="section downloads-list page-margin"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="title ts-h2">{{ headline }}</div>
    <div class="downloads-list__grid" v-if="items?.length">
      <ElementsDownloadCard
        v-for="(download, index) in items"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
        :key="index"
        :download="download"
        v-show="index < overviewLimit || !showMore"
      />
    </div>
    <div class="downloads-list__cta reveal">
      <ButtonCTA
        class="cta"
        type="primary"
        label="Show all"
        :hasIcon="true"
        to=""
        icon="arrowsimple"
        @click="showAll"
        v-if="items?.length > overviewLimit && showMore"
      />
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.downloads-list {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  padding-top: units(9);
  padding-bottom: units(3);
  display: grid;
  gap: units(6);
  &__grid {
    display: grid;
    gap: units(2);
    @media (--md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
