<script setup>
const props = defineProps({
  media: Object,
});

const container = ref(null);
const video = ref(null);

const loop = inject("loop", null);
const paused = inject("paused", null);
const isNotLazyVideo = inject("isNotLazyVideo", null);
const posterVisible = inject("posterVisible", null);
const poster = inject("poster", null);
const videoId = inject("videoId", null);
const src = computed(() => props.media.mp4_video.url);
const injectSrc = ref(null);
const videoProps = computed(() => ({
  // ...props.media,
  poster: posterVisible ? poster : null,
  controls: null,
  autoplay: true,
  loop,
  src: null,
  muted: true,
  playsinline: true,
}));

const preloadMarginDesktop = ref("500px");
const preloadMarginMobile = ref("150px");

const viewport = useViewport();

onMounted(() => {
  if (video.value && videoId) {
    useEvent("video:loaded", {
      video: video.value,
      id: videoId,
    });
  }

  const options = {
    rootMargin: viewport.isLessThan("desktopView")
      ? preloadMarginMobile.value
      : preloadMarginDesktop.value,
  };
  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (video.value) {
        if (entry.isIntersecting) {
          // console.log("intersecting");
          injectSrc.value = src.value;
          video.value.src = src.value;
          video.value.load();
        } else {
          // console.log("not intersecting");
          video.value.pause();
          video.value.src = "";
          video.value.removeAttribute("src");
        }
      }
    });
  }, options);
  isNotLazyVideo ? null : observer.observe(container.value);

  if (paused && video.value) {
    video.value?.pause();
    // console.log("paused");
  }
});

onBeforeUnmount(() => {
  if (video.value) {
    video.value.pause();
    video.value.src = "";
    video.value.removeAttribute("src");
  }
});

useListen("overlay:open", () => {
  video.value?.pause();
});

useListen("overlay:close", () => {
  video.value?.play();
});
</script>

<template>
  <div class="video-container" ref="container">
    <YoutubePlayer v-if="media.youtube_url" :videoId="media.youtube_url" />
    <video
      v-else
      ref="video"
      v-bind="{ ...videoProps }"
      preload="metadata"
      :src="isNotLazyVideo ? src : injectSrc"
    />
  </div>
</template>

<style lang="postcss" scoped>
.video-container {
  height: 100%;
  width: 100%;

  &:deep(.youtube-player) {
    height: 100%;
  }

  &:deep(.plyr) {
    height: 100%;
  }
}

video {
  width: 100%;
  height: 100%;
}
</style>
