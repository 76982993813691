<script setup>
const expanded = ref(null);

provide("accordion-register", () => {
  const id = Symbol();

  return {
    expanded: computed(() => expanded.value === id),

    toggle() {
      expanded.value = expanded.value === id ? null : id;
    },

    unregister() {
      if (expanded.value === id) {
        expanded.value = null;
      }
    },
  };
});
</script>

<template>
  <div class="accordion-tabs">
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
.accordion-tabs {
  display: flex;
  flex-direction: column;
}
</style>
