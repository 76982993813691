<script setup>
// import { camelCase, pascalCase, paramCase } from 'change-case'
import { paramCase } from "change-case";
const props = defineProps({
  modules: {
    type: Object,
    default: {},
  },
});

const components = {
  articles_list: resolveComponent("ArticlesList"),
  banner: resolveComponent("Banner"),
  billboard: resolveComponent("Billboard"),
  breaker: resolveComponent("Breaker"),
  chart: resolveComponent("chart"),
  employee_list: resolveComponent("EmployeeList"),
  management_list: resolveComponent("ManagementList"),
  management: resolveComponent("Management"),
  employee: resolveComponent("Employee"),
  brand_promotion: resolveComponent("BrandPromotion"),
  brand_portfolio: resolveComponent("BrandPortfolio"),
  brand_ecosystem: resolveComponent("BrandEcosystem"),
  cta_row: resolveComponent("CTARow"),
  fact_flip_cards: resolveComponent("FactFlipCards"),
  hero: resolveComponent("Hero"),
  intro: resolveComponent("Intro"),
  link_list: resolveComponent("LinkList"),
  list: resolveComponent("List"),
  logo_wall: resolveComponent("LogoWall"),
  media_text: resolveComponent("MediaText"),
  meet_the_team: resolveComponent("MeetTheTeam"),
  numbers: resolveComponent("Numbers"),
  numbers_cards: resolveComponent("NumbersCards"),
  split_content: resolveComponent("SplitContent"),
  split_quote: resolveComponent("SplitQuote"),
  standard_carousel: resolveComponent("StandardCarousel"),
  story_flip_cards: resolveComponent("StoryFlipCards"),
  tabs: resolveComponent("Tabs"),
  topic_teasers: resolveComponent("TopicTeasers"),
  triple_list: resolveComponent("TripleList"),
  all_articles: resolveComponent("AllArticles"),
  latest_articles: resolveComponent("LatestArticles"),
  all_press_releases: resolveComponent("AllPressReleases"),
  latest_press_releases: resolveComponent("LatestPressReleases"),
  latest_news: resolveComponent("LatestArticles"),
  compliance: resolveComponent("Compliance"),
  reports: resolveComponent("Reports"),
  downloads_list: resolveComponent("DownloadsList"),
  card_showcase: resolveComponent("CardShowcase"),
  rich_text: resolveComponent("RichText"),
  history_timeline: resolveComponent("HistoryTimeline"),
  newsletter: constants.ENABLE_NEWSLETTER
    ? resolveComponent("Newsletter")
    : null,
  places: resolveComponent("Places"),
  article_layer: resolveComponent("ArticleLayer"),
  soundbar_carousel: resolveComponent("SoundbarCarousel"),
  soundbar_quote_carousel: resolveComponent("SoundbarCarousel"),
  static_list: resolveComponent("StaticList"),
};

onMounted(() => {
  const allIntros = document.querySelectorAll(".count-intro");
  allIntros.forEach((intro, index) => {
    intro.querySelector(".intro__count").innerHTML =
      index + 1 < 10 ? `0${index + 1}` : index + 1;
  });
});
</script>

<template>
  <!-- <div class="page-modules"> -->
  <component
    v-for="(module, index) in modules"
    :key="`${paramCase(module.acf_fc_layout)}-${index}`"
    :is="components[module.acf_fc_layout]"
    :class="['page-module', `page-module--${paramCase(module.acf_fc_layout)}`]"
    :data-id="index"
    v-bind="module"
  ></component>
  <!-- </div> -->
</template>

<style lang="postcss" scoped>
div {
}
</style>
