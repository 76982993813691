<script setup>
const props = defineProps({
  options: {
    type: [Array, Object],
  },
  label: {
    type: String,
    default: "Select an option",
  },
});
const emit = defineEmits(["dropdown-value"]);
const selectedValue = ref(null);

const handleChange = (event) => {
  const newValue = event.target.value;
  selectedValue.value = newValue;
  emit("dropdown-value", newValue);
};

watch(
  () => props.value,
  (newValue) => {
    selectedValue.value = newValue;
  }
);
</script>

<template>
  <select @change="handleChange" class="dropdown-select ts-p1">
    <option selected disabled v-if="label">{{ label }}</option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option.value?.url"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<style lang="postcss" scoped>
.dropdown-select {
  color: inherit;
  width: 100%;
  border: none;
  padding-bottom: 2px;
  border-bottom: 1px solid var(--grey-1);

  &:deep(+ .button-cta) {
    margin-top: units(3);
  }
}

*:focus {
  outline: none;
}
</style>
