import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        // console.log("scrollBehavior");
        // if (savedPosition) {
        //     return savedPosition;
        // } else {
        //     return {
        // top: 0
        //     };
        // }
    }
};