<script setup>
const props = defineProps({
  items: [Object, Array],
  headline: String,
  hasHeading: {
    type: Boolean,
    default: true,
  },
  heading_tag: [Object, Boolean, null],
  call_to_action: [Object, Array],
  revealProgress: {
    type: Number,
    default: 0.15,
  },
});

// console.log("props.heading_tag: ", props.heading_tag);

const headingTag = computed(() =>
  props.heading_tag ? props.heading_tag.value : "h2"
);

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > props.revealProgress
    ? (isVisible.value = true)
    : null;
}

const noResults = computed(() => !props.items?.length);
</script>

<template>
  <section
    class="section page-margin reports"
    :class="{ 'can-run': isVisible }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <component
      v-if="hasHeading"
      :is="headingTag"
      class="reports__headline ts-h2 reveal"
      >{{ headline }}</component
    >
    <div class="reports__list">
      <div
        v-for="(report, index) in items"
        :key="`report-${index}`"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
      >
        <ElementsReport
          v-if="report.item !== null"
          :item="report?.item[0]"
          :acf_fc_layout="report?.acf_fc_layout"
          :is-highlighted="report?.highlight"
        />
      </div>
    </div>
    <ButtonCTA
      v-if="call_to_action?.label && items.length > 0"
      class="reports__cta"
      type="primary"
      :cta="call_to_action"
      :hasIcon="true"
      icon="arrowsimple"
    />
    <transition name="fade">
      <div class="no-results" v-if="noResults">No results</div>
    </transition>
  </section>
</template>

<style lang="postcss">
.reports {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  padding-bottom: units(6);
  display: flex;
  flex-direction: column;

  .no-results {
    text-align: center;
    padding: units(6);
  }

  &__headline {
    padding: units(9) 0 units(6) 0;
    margin-bottom: units(1);
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: units(4);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--grey-0);
      top: units(-2);
      left: 0;
    }
  }
  &__cta {
    margin-top: units(5);
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
