<script setup>
const props = defineProps({
  headline: String,
  tabs: [Object, Array],
});
const activeTabIndex = ref(0);

const setActiveTab = (index) => {
  activeTabIndex.value = index;
};

const flattenedImages = ref([]);

for (const tab of props.tabs) {
  if (tab.tab_item.image) {
    flattenedImages.value.push(tab.tab_item.image);
  }
}

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section class="page-margin section tabs" :class="{ 'can-run': isVisible }" v-view="(e) => runIntroAnimation(e)">
    <ElementsIntro v-bind="{ headline }" class="tabs__intro" />
    <nav class="tabs__bar" role="tablist">
      <button class="tabs__button ts-h5 reveal" v-for="(tab, index) in tabs" :class="{ active: activeTabIndex === index }"
        @click="setActiveTab(index)" :id="`tab-${tab.tab_item.headline
          .toLowerCase()
          .trim()
          .replaceAll(' ', '')}-${index}`" :key="'tab-button-' + index" role="tab"
        :style="`transition-delay: ${index * 0.1}s`">
        {{ tab.tab_item.headline }}
      </button>
    </nav>

    <div class="tabs__inner">
      <div class="tabs__images reveal">
        <ElementsMediaStack :images="flattenedImages" :activeIndex="activeTabIndex" />
      </div>

      <div class="tabs__content reveal">
        <div v-for="(tab, index) in tabs" :key="'tab-content-' + index" role="tabpanel" :aria-labelledby="`tab-${tab.tab_item.headline
          .toLowerCase()
          .trim()
          .replaceAll(' ', '')}-${index}`" :aria-hidden="activeTabIndex == index ? false : true">
          <div class="tabs__body" v-show="activeTabIndex === index">
            <div class="ts-large-serif" v-html="tab.tab_item.copy">
            </div>

            <div class="tabs__ctas" v-if="tab?.tab_item?.call_to_actions">
              <div v-for="(cta, index) in tab?.tab_item?.call_to_actions" :key="index" class="tabs__ctas-item">
                <!-- {{ cta }} -->
                <ButtonCTA v-if="cta?.call_to_action_download?.label &&
                  (cta?.call_to_action_download?.url ||
                    cta?.call_to_action_download?.page_link) &&
                  cta?.call_to_action_download?.file === null
                  " :cta="cta?.call_to_action_download" :has-icon="true" type="primary" icon="arrowsimple" />
                <ButtonCTA v-if="cta?.call_to_action_download?.label &&
                  cta?.call_to_action_download?.file?.length > 0
                  " :cta="cta?.call_to_action_download" :has-icon="true" type="primary-outline" icon="arrowsimple"
                  :is-download="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.tabs {
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  display: flex;
  flex-direction: column;
  padding-bottom: units(6);

  @media (--md) {
    padding-bottom: units(9);
    /* max-height: 810px; */
  }

  &__intro {
    padding: units(9) 0 units(6) 0;
  }

  &__bar {
    display: flex;

    @media (--until-md) {
      overflow: scroll;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: units(3);

    @media (--md) {
      display: grid;
      column-gap: units(2);
      grid-template-columns: repeat(12, 1fr);
      padding-top: units(9);
    }
  }

  &__images {
    position: relative;
    height: fit-content;
    margin-bottom: units(3);

    @media (--md) {
      order: 2;
      grid-column: 8/13;
      margin-bottom: unset;
    }

    aspect-ratio: 1;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    word-wrap: break-word;
    word-break: break-word;
    height: fit-content;
    min-height: 70vh;

    @media (--md) {
      min-height: unset;
      order: 1;
      grid-column: 1/7;
    }
  }

  &__body {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: units(3);
  }

  &__ctas {
    margin-top: auto;
    /* & > :nth-child(n + 2) {
      margin-top: units(2);

      @media (--md) {
        margin-left: units(1);
      }
    } */

    display: flex;
    gap: units(2);
    flex-direction: column;

    @media (--md) {
      flex-direction: row;
    }
  }

  &__button {
    background: none;
    border: none;
    position: relative;
    cursor: pointer;
    flex: 1 0 auto;
    padding: units(2) units(1.5);

    @media (--md) {
      padding: units(1.6);
    }

    &::after {
      content: "";
      height: 1px;
      background: var(--grey-1);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.active {
      color: var(--loud-indigo);

      &::after {
        height: 2px;
        background: var(--loud-indigo);
      }
    }
  }
}
</style>
