<script setup>
const props = defineProps({
  data: {
    type: [Array, Object],
  },
  items: Array,
});

// console.log(props.items);

const carousel = ref(null);
const container = ref(null);
const activeIndex = ref(0);
const elements = ref([]);

function handleIntersect(entries) {
  const entry = entries?.find((e) => e.isIntersecting);
  if (entry) {
    const index = elements.value?.findIndex((e) => e === entry.target);
    activeIndex.value = index;
  }
}

function goPrevious() {
  if (activeIndex.value > 0) {
    const prevSlide = elements.value[activeIndex.value - 1];
    const prevSlideRect = prevSlide.getBoundingClientRect();
    const containerRect = container.value.getBoundingClientRect();
    if (prevSlideRect.left < containerRect.left) {
      container.value.scrollLeft -= containerRect.width;
    }
    activeIndex.value -= 1;
  }
}

function goNext() {
  if (activeIndex.value < elements.value.length - 1) {
    const nextSlide = elements.value[activeIndex.value + 1];
    const nextSlideRect = nextSlide.getBoundingClientRect();
    const containerRect = container.value.getBoundingClientRect();
    if (nextSlideRect.right > containerRect.right) {
      container.value.scrollLeft += containerRect.width;
    }
    activeIndex.value += 1;
  }
}

const dragState = ref({
  isDragging: false,
  startX: 0,
  scrollLeft: 0,
});

const handleDragStart = (e) => {
  dragState.value.isDragging = true;
  container.value.classList.add("active");
  dragState.value.startX = e.pageX - container.value.offsetLeft;
  dragState.value.scrollLeft = container.value.scrollLeft;
};

const handleDragMove = (e) => {
  if (!dragState.value.isDragging) return;
  e.preventDefault();
  const x = e.pageX - container.value.offsetLeft;
  const walk = (x - dragState.value.startX) * 3;
  container.value.scrollLeft = dragState.value.scrollLeft - walk;
};

const handleDragEnd = () => {
  dragState.value.isDragging = false;
  container.value.classList.remove("active");
};

onMounted(() => {
  elements.value = [...container.value.children];
  const observer = new IntersectionObserver(handleIntersect, {
    root: container.value,
    rootMargin: "0px",
    threshold: 0.75,
  });

  elements.value.forEach((el) => {
    observer.observe(el);
  });
});
const slots = useSlots();

const emit = defineEmits(["active-index"]);

watch(
  activeIndex,
  (index) => {
    emit("active-index", index);
  },
  {
    immediate: true,
  }
);

//handle click on non active slides and scroll to them
watch(
  () => elements.value,
  (elements) => {
    elements.forEach((element, index) => {
      element.addEventListener("click", () => {
        if (index !== activeIndex.value) {
          const containerRect = container.value.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          if (elementRect.left < containerRect.left) {
            container.value.scrollLeft -= containerRect.width;
          } else if (elementRect.right > containerRect.right) {
            container.value.scrollLeft += containerRect.width;
          }
          activeIndex.value = index;
        }
      });
    });
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <section class="standard-carousel section" ref="carousel">
    <elements-carousel-controls
      class="standard-carousel__nav"
      @go-previous="goPrevious"
      @go-next="goNext"
    />
    <div
      class="standard-carousel__gallery"
      :class="{ 'has-slot': slots.slide }"
      ref="container"
      @mousedown="handleDragStart"
      @mousemove="handleDragMove"
      @mouseup="handleDragEnd"
      @mouseleave="handleDragEnd"
    >
      <slot v-if="slots.slide" name="slide" />

      <div
        v-else
        class="standard-carousel__gallery-item"
        :class="{ active: index === activeIndex, 'no-slot': !slots.slide }"
        v-for="(slide, index) in items"
        :key="index"
      >
        <span class="trumpet ts-h5">{{ slide.trumpet }}</span>
        <div class="infos">
          <h3 class="infos-title ts-h3">
            {{ slide.headline }}
          </h3>
          <p class="infos-copy ts-p1">{{ slide.copy }}</p>
        </div>
        <div
          class="background"
          v-if="slide.media.image || slide.media.video.mp4_video"
        >
          <Media
            :media="slide.media"
            :cover="true"
            :loop="true"
            :is-not-lazy-video="true"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
.standard-carousel {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: units(2);

  width: 100vw;
  padding-top: units(2);
  padding-bottom: units(9);
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - var(--page-margin) * 2);
    height: 1px;
    background: var(--grey-1);
  }

  &__nav {
    z-index: 1;
    margin-left: auto;
    margin-right: var(--page-margin);
  }

  &__gallery {
    scroll-snap-type: x mandatory;

    scroll-padding-left: var(--page-margin);
    padding-left: var(--page-margin);
    scroll-padding-right: var(--page-margin);
    padding-right: var(--page-margin);

    overflow-x: scroll;
    overflow-y: hidden;

    display: flex;
    gap: units(1);
    align-items: center;

    width: 100%;
    height: fit-content;

    height: units(43);
    @media (--md) {
      gap: units(2);
      height: 100%;
    }

    & .has-slot {
      height: unset;
    }

    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    user-select: none;
    & div {
      scroll-snap-align: start;
      position: relative;
    }
    &-item {
      min-width: 90%;
      height: 100%;
      padding: units(3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: units(1);
      background: var(--black);
      color: var(--white);
      overflow: hidden;
      gap: units(2);

      &.no-slot {
        transition: opacity 0.8s ease-in-out;
        @media (--md) {
          aspect-ratio: 2/1;
          height: auto;
        }
        &:not(.active) {
          opacity: 0.5;
          cursor: pointer;
        }
      }

      &:last-child {
        margin-right: var(--page-margin);
      }
      @media (--md) {
        min-width: 85%;
        padding: units(6);
      }

      .trumpet {
        z-index: 1;
      }
      .infos {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        z-index: 1;
        @media (--md) {
          flex-direction: row;
          gap: units(20);
          align-items: flex-end;
        }
        @media (--lg) {
          gap: units(40);
        }
        &-title {
        }
        &-copy {
        }
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;

        & img {
          object-fit: cover;
        }
        & video {
          border-radius: units(1);
        }
      }
    }
    &-items {
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
