<script setup>
const props = defineProps({
  items: Array,
  layout: String,
  has_background_color: {
    type: Boolean,
    default: false,
  },
  heading_tag: [Object, Boolean, null],
});

// :class="{ 'no-title': !title }"
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin list"
    :class="{
      'three-columns': layout === 'three-columns',
      'list--bg-color': has_background_color,
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div
      class="list__item reveal"
      v-for="(item, index) in items"
      :style="`transition-delay: ${index * 0.1}s`"
      :key="item?.headline?.replace(' ', '')"
    >
      <div v-if="item?.icon" class="icon">
        <Media :media="{ image: item?.icon }" />
      </div>

      <div class="list__content">
        <component
          v-if="item?.headline"
          :is="item.heading_tag.value || 'h2'"
          class="ts-list-title"
          >{{ item?.headline }}</component
        >
        <p v-if="item?.copy" class="desc ts-p1">
          {{ item?.copy }}
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.list {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: grid;
  color: var(--grey-4);
  &--bg-color {
    background-color: var(--grey-0);
  }
  @media (--md) {
    gap: units(2);
    grid-template-columns: repeat(2, 1fr);

    &.three-columns {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: units(2);
    padding-bottom: units(3);
    padding-top: units(2);

    @media (--md) {
      padding: units(6) 0;
    }

    &.no-title {
      align-items: flex-start;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background: var(--grey-1);
    }

    .icon {
      width: 100%;
      max-width: units(5);
    }
  }

  &__content {
    h2 + p {
      margin-top: units(2);
    }
  }
}
</style>
