<script setup>
const props = defineProps({
  numbers: Array,
  headline: String,
  source: String,
  quote: Object,
});

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="numbers"
    :class="{ 'has-content': quote.headline, 'can-run': isVisible }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="numbers__table">
      <ElementsNumber
        v-for="(number, index) in numbers"
        v-bind="{ data: number }"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
      />
      <span v-if="source" class="numbers__source ts-caption reveal">{{
        source
      }}</span>
    </div>
    <div v-if="quote.headline" class="numbers__content reveal">
      <div class="numbers__quote-icon"><nuxt-icon name="quote" /></div>
      <h2 class="ts-large-serif numbers__quote-title">
        {{ quote.headline }}
      </h2>
      <p class="numbers__quote-copy ts-p1 reveal">
        {{ quote.copy }}
      </p>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.numbers {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  /* background-color: var(--grey-0); */

  display: flex;
  flex-direction: column;

  .headline {
    color: var(--grey-4);
  }
  &__table {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));
    grid-column-gap: units(1);
    grid-row-gap: units(3);
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    @media (--md) {
      grid-template-columns: repeat(auto-fit, minmax(calc(25% - 20px), 1fr));
      grid-column-gap: units(2);
      grid-row-gap: units(3);
    }

    padding-bottom: units(3);
    padding-top: units(6);
    @media (--md) {
      padding-bottom: units(9);
    }
  }

  &__source {
    grid-column: 1/3;
    color: var(--grey-4);
  }

  &.has-content {
    @media (--md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .numbers__table {
      background-color: var(--white);
      grid-template-columns: 1fr 1fr;
      padding-bottom: units(6);
      padding-left: var(--page-margin);
      padding-right: var(--page-margin);
      @media (--md) {
        padding-right: units(2);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    background-color: var(--grey-0);
    gap: units(2);
    padding: units(6) var(--page-margin) units(6) var(--page-margin);

    @media (--md) {
      padding: units(9) var(--page-margin) units(6) var(--page-margin);
    }
  }

  &__quote {
    &-icon {
      color: var(--loud-indigo);
    }
    &-title,
    &-copy {
      color: var(--grey-4);
    }
  }
}
</style>
