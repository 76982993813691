<script setup>
</script>

<template>
  
  
<svg viewBox="0 0 22 22">
  <path d="M11 21.6c-2.8 0-5.5-1.1-7.5-3.1-4.1-4.1-4.1-10.9 0-15s10.9-4.1 15 0 4.1 10.9 0 15c-2 2-4.7 3.1-7.5 3.1zm0-20c-2.4 0-4.8.9-6.6 2.7C.7 8 .7 14 4.4 17.6c1.8 1.8 4.1 2.7 6.6 2.7s4.9-1 6.6-2.7c3.7-3.7 3.7-9.6 0-13.3-1.8-1.8-4.2-2.7-6.6-2.7z"/>
  <path d="m6.475 7.323.92-.919 8.271 8.274-.919.92z"/>
  <path d="m6.472 14.675 8.278-8.268.919.92-8.278 8.268z"/>
</svg>

</template>

<style lang="postcss" scoped>

</style>

