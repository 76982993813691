<script setup>

</script>

<template>
  <div class="card-news">
    <div class="card-news-wrapper">
      <div class="header">
        <div class="tag ts-caps-bold">
          <span class="ghost-date"></span>
        </div>
      </div>
      <div class="content">
        <div class="text">
          <div class="date ts-caps">
            <span class="ghost-copy" style="--word-width: 80px;"></span>
            <span class="ghost-copy" style="--word-width: 30px;"></span>
            <span class="ghost-copy" style="--word-width: 40px;"></span>
          </div>
          <div class="title ts-h4">
            <span class="ghost-copy" style="--word-width: 120px;"></span>
            <span class="ghost-copy" style="--word-width: 70px;"></span>
            <span class="ghost-copy" style="--word-width: 80px;"></span>
          </div>
        </div>
      </div>
      <div class="background">
        <div class="gradient"></div>
        <div class="ghost-image"></div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.card-news {
  height: 100%;
  width: 100%;
  aspect-ratio: 35/40;
  color: var(--white);

  @media (--md) {
    aspect-ratio: 4/5;
  }

  .card-news-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: units(2);
    border-radius: units(1);
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tag {
      border: 1px solid var(--white);
      border-radius: units(1);
      padding: units(0.8);
      margin-left: auto;
    }
  }

  .content {
    .text {
      display: flex;
      flex-direction: column;
      gap: units(2);
    }
  }

  .header,
  .content {
    z-index: 2;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-4);

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 35%,
          rgba(0, 0, 0, 0) 70%,
          rgba(0, 0, 0, 0.4) 100%);
    }

    .media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
      transition: scale 0.3s ease-in-out;

      &:hover {
        scale: 1.05;
      }
    }
  }
}
</style>
