<script setup>
</script>

<template>

  <svg width="100%" height="100%" viewBox="0 0 21.25 21.25" style="fill: currentColor;">
    <path d="m10.62,0C4.77,0,0,4.77,0,10.62s4.77,10.62,10.62,10.62,10.62-4.77,10.62-10.62S16.48,0,10.62,0Zm0,20c-5.17,0-9.38-4.21-9.38-9.38S5.46,1.25,10.62,1.25s9.38,4.21,9.38,9.38-4.21,9.38-9.38,9.38Z"/>
    <path d="m15.58,14.7l-7.98-7.97h6.17v-1.25h-7.68c-.35,0-.62.28-.62.62v7.67h1.25v-6.16l7.98,7.97.88-.88Z"/>
  </svg>

</template>

<style lang="postcss" scoped>

</style>
