<script setup>
const nuxtApp = useNuxtApp();

const props = defineProps({
  isOverlay: Boolean,
});
const { newsletter } = await useGlobals(nuxtApp);
const formData = ref({
  name: "",
  email: "",
});

const submitted = ref(false);

const submitForm = () => {
  submitted.value = true;
};
</script>

<template>
  <section
    class="section page-margin newsletter"
    :class="{ 'is-not-overlay': !isOverlay }"
  >
    <div class="content">
      <h1 class="ts-large-serif">{{ newsletter.headline }}</h1>
      <p class="ts-p1">
        {{ newsletter.copy }}
      </p>
    </div>
    <div v-if="!submitted" class="form">
      <h2 class="ts-p1-bold">What are you interested in?</h2>
      <form @submit.prevent="submitForm">
        <div class="checkboxes">
          <div class="checkbox">
            <input type="checkbox" name="reports" id="reports" /><label
              for="reports"
              class="ts-caption"
              >Annual reports</label
            >
          </div>

          <div class="checkbox">
            <input type="checkbox" name="news" id="news" /><label
              for="news"
              class="ts-caption"
              >Latest news</label
            >
          </div>
          <div class="checkbox">
            <input type="checkbox" name="press" id="press" /><label
              for="press"
              class="ts-caption"
              >Press release</label
            >
          </div>
        </div>
        <input
          class="email ts-caps-bold"
          type="email"
          id="email"
          placeholder="Email"
          v-model="formData.email"
          required
        />
        <div class="checkbox">
          <input
            type="checkbox"
            name="terms"
            id="terms"
            required
            class="ts-caption"
          /><label for="terms"
            >I have read and agree to the Terms & Conditions.</label
          >
        </div>
        <ButtonCTA
          class="submit"
          :has-background="true"
          type="third"
          label="Subscribe"
          :hasIcon="false"
        />
      </form>
    </div>
    <div v-else class="content">
      <h1 class="ts-h4">Great!</h1>
      <p class="ts-p1">
        You will receive our first newsletter soon. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit.
      </p>
      <div class="ts-caps">
        email:
        <div class="ts-p1-bold">{{ formData.email }}</div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.newsletter {
  --show-content-top-line: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--deep-indigo);
  color: var(--white);
  padding-top: units(9);
  padding-bottom: units(9);

  .content {
    display: flex;
    flex-direction: column;
    gap: units(3);
    position: relative;
    padding-bottom: units(6);
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: units(3);
    align-items: flex-start;
    position: relative;
    padding-top: units(6);
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: var(--grey-4);
    }
    & form {
      display: flex;
      flex-direction: column;
      gap: units(3);
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: units(1);
    }

    .checkbox {
      display: inline-flex;
      gap: units(1.5);
    }

    .email {
      color: var(--white);
    }
  }

  &.is-not-overlay {
    @media (--md) {
      flex-direction: row;
    }

    .content {
      @media (--md) {
        padding-right: units(15);
        width: 50%;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: var(--grey-4);
        }
      }
    }
    .form {
      @media (--md) {
        padding-left: units(20);
        padding-top: 0;
        &:before {
          content: unset;
        }
      }
    }
  }
}

input[type="email"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--grey-0);
  color: var(--white);
  text-decoration: none;
  cursor: text;
  z-index: 1;
  padding: units(0.7) 0 units(0.7) 0;

  &:focus {
    outline: none;
  }
}
</style>
