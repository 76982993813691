<script setup>
const props = defineProps({
  data: String,
  outputTag: [String, null],
});

const rteTag = computed(() => (props.outputTag ? props.outputTag : "div"));

const DynamicComponent = defineComponent({
  render() {
    return h(rteTag.value, { class: "rte", innerHTML: props.data });
  },
});
</script>

<template>
  <dynamic-component v-if="outputTag"></dynamic-component>
  <div v-else class="rte" v-html="data"></div>
</template>

<style lang="postcss">
.rte {
  word-wrap: break-word;
  /* line-height: 1.5; */
  padding-bottom: var(--section-margin);

  h1 {
    font-family: var(--sans-serif);
    font-size: var(--ts-h1-fs);
    line-height: var(--ts-h1-lh);
    letter-spacing: var(--ts-h1-ls);
    font-weight: normal;
  }

  h2 {
    font-family: var(--sans-serif);
    font-size: var(--ts-h2-fs);
    line-height: var(--ts-h2-lh);
    font-weight: normal;
  }

  h3 {
    font-family: var(--sans-serif);
    font-size: var(--ts-h3-fs);
    line-height: var(--ts-h3-lh);
    font-weight: normal;
  }

  h4 {
    font-family: var(--sans-serif);
    font-size: var(--ts-h4-fs);
    line-height: var(--ts-h4-lh);
    font-weight: normal;
  }

  h5 {
    font-family: var(--sans-serif);
    font-size: var(--ts-h5-fs);
    line-height: var(--ts-h5-lh);
    font-weight: 600;
  }

  h6 {
    font-family: var(--sans-serif);
    font-size: var(--ts-caption-fs);
    line-height: var(--ts-caption-lh);
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: units(3);
  }

  blockquote {
    font-family: var(--serif);
    font-size: var(--ts-large-serif-fs);
    line-height: var(--ts-large-serif-lh);
    font-weight: 400;
    padding: units(4) 0;
    margin-top: units(3);
    position: relative;

    &:before {
      font-family: inherit;
      font-size: 55px;
      content: "“";
      font-weight: 900;
      position: absolute;
      top: -20px;
      color: var(--loud-indigo);
    }
  }

  img {
    width: 100%;
    height: auto;
    padding: units(3) 0;
  }

  a {
    color: var(--loud-indigo);
  }

  *+* {
    margin-top: 1em;
  }

  ul,
  ol {
    list-style: none;

    li {
      &:before {
        color: var(--grey-2);
        padding-right: units(2);
      }
    }
  }

  ul {
    li {
      &:before {
        content: "•";
      }
    }
  }

  ol {
    counter-reset: section;

    li {
      counter-increment: section;

      &:before {
        font-weight: bold;
        font-size: 12px;
        content: counter(section, decimal-leading-zero);
      }
    }
  }

  li {
    margin-top: units(1);

    ul,
    ol {
      margin-left: units(3.5);
      list-style-type: lower-latin;

      li {
        &:before {
          content: "";
        }
      }
    }
  }
}
</style>
