<script setup>
import { gsap } from "gsap";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  slides: [Object, Array],
  activeIndex: Number,
  acf_fc_layout: String,
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const slider = ref(null);
const onSwiper = (swiper) => {
  slider.value = swiper;
};

onMounted(() => {
  if (slider.value) {
  }
});
const emit = defineEmits(["update-activeIndex"]);

watch(
  () => slider.value,
  (newVal) => {
    if (newVal) {
      newVal.on("slideChange", function (e) {
        emit("update-activeIndex", newVal.realIndex);
      });
    }
  }
);

watch(
  () => props.activeIndex,
  (newVal) => {
    if (slider.value) {
      slider.value.slideTo(newVal);
    }
  }
);
const activeSlideBackground = ref(null);
const infos = ref(null);
const active = ref(null);
const viewport = useViewport();

function activeSlideAnimation(newIndex, oldIndex) {
  if (newIndex < oldIndex) {
    //to right
    gsap.fromTo(
      activeSlideBackground.value[props.activeIndex],
      {
        clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
      },
      {
        duration: 0.5,
        delay: viewport.isLessThan("desktopView") ? 0.15 : 0.3,
        clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
        ease: "power2.inOut",
      }
    );
  } else {
    //   to left
    gsap.fromTo(
      activeSlideBackground.value[props.activeIndex],
      {
        clipPath: "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)",
      },
      {
        duration: 0.5,
        delay: viewport.isLessThan("desktopView") ? 0.15 : 0.3,

        clipPath: "polygon(100% 0, 0% 0, 0% 100%, 100% 100%)",
        ease: "power2.inOut",
      }
    );
  }
}
watch(
  () => props.activeIndex,
  (newIndex, oldIndex) => {
    if (!process.client) return;
    viewport.isLessThan("desktopView")
      ? (active.value = middle.value.querySelector(".middle-active"))
      : null;
    activeSlideAnimation(newIndex, oldIndex);
  }
);

const infosContent = ref(null);
const expandInfos = ref(false);
const middle = ref(null);

watch(
  () => active.value,
  () => {
    shouldExpand.value = false;
    expandInfos.value = false;
    checkIfContentOverflows();
  }
);
const shouldExpand = ref(false);
const infosHeight = ref(0);
const expandHeight = ref(0);
const checkIfContentOverflows = () => {
  if (!process.client) return;
  setTimeout(() => {
    infos.value[props.activeIndex].scrollHeight >
      infos.value[props.activeIndex].clientHeight ||
    infos.value[props.activeIndex].scrollWidth >
      infos.value[props.activeIndex].clientWidth
      ? (shouldExpand.value = true)
      : (shouldExpand.value = false);
    infosHeight.value = infos.value[props.activeIndex].scrollHeight;
  }, 100);
};
onMounted(() => {
  checkIfContentOverflows();
});
</script>

<template>
  <section class="middle-soundbar-carousel" ref="middle">
    <swiper
      @swiper="onSwiper"
      :speed="800"
      v-if="!$viewport.isLessThan('desktopView')"
      class="swiper-desktop"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        class="slide active"
        :class="{
          'middle-active': index === activeIndex,
        }"
      >
        <div
          class="infos"
          ref="infos"
          v-if="acf_fc_layout === 'soundbar_carousel'"
        >
          <h2 class="infos-title ts-h3">
            {{ slide.headline }}
          </h2>
          <p class="infos-copy ts-p1" v-html="slide.copy" />
        </div>
        <div class="infos infos-quote" ref="infos" v-else>
          <div class="infos-top">
            <p class="infos-title ts-caps-bold">
              {{ slide.headline }}
            </p>
            <p class="infos-trumpet ts-caps">
              {{ slide.trumpet }}
            </p>
          </div>
          <div class="infos-bottom">
            <div class="icon icon-quote">
              <SvgQuote />
            </div>
            <div class="infos-copy ts-large-serif">{{ slide.quote }}</div>
          </div>
        </div>
        <div
          class="background"
          ref="activeSlideBackground"
          v-if="slide.media.image || slide.media.video.mp4_video"
        >
          <Media
            :media="slide.media"
            :is-not-lazy-video="true"
            :cover="true"
            :loop="slide.media.video.mp4_video.url !== ''"
            :key="index"
            :paused="false"
          />
        </div>
      </swiper-slide>
    </swiper>
    <swiper @swiper="onSwiper" :speed="800" v-else class="swiper-mobile">
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        class="slide active"
        :class="{
          'middle-active': index === activeIndex,
        }"
      >
        <div
          class="infos"
          :class="{ open: expandInfos }"
          ref="infos"
          :style="`--infos-height: ${infosHeight}px`"
          v-if="acf_fc_layout === 'soundbar_carousel'"
        >
          <div class="infos-content" ref="infosContent">
            <h2 class="infos-title ts-h3">
              {{ slide.headline }}
            </h2>
            <p class="infos-copy ts-p1" v-html="slide.copy" />
          </div>
          <div
            class="infos-expand"
            :class="{ open: expandInfos }"
            v-if="shouldExpand"
            @click="expandInfos = !expandInfos"
          >
            <div class="icon">
              <SvgAccordionArrow class="svg" />
            </div>
          </div>
        </div>
        <div class="infos infos-quote" ref="infos" v-else>
          <div class="infos-top">
            <p class="infos-title ts-caps-bold">
              {{ slide.headline }}
            </p>
            <p class="infos-trumpet ts-caps">
              {{ slide.trumpet }}
            </p>
          </div>
          <div class="infos-bottom">
            <div class="icon icon-quote">
              <SvgQuote />
            </div>
            <div class="infos-copy ts-large-serif">{{ slide.quote }}</div>
          </div>
        </div>
        <div
          class="background"
          ref="activeSlideBackground"
          v-if="slide.media.image || slide.media.video.mp4_video"
        >
          <Media
            :media="slide.media"
            :is-not-lazy-video="true"
            :cover="true"
            :loop="slide.media.video.mp4_video.url !== ''"
            v-if="index === activeIndex"
            :paused="false"
          />
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<style lang="postcss" scoped>
.middle-soundbar-carousel {
  --soundbar-desktop-active-height: units(50);
  --soundbar-mobile-active-height: units(35);

  .swiper {
    .slide {
      border-radius: units(1);
      overflow: hidden;

      &:not(.active) {
        width: units(7.5);
        height: var(--random-height);

        .infos {
          display: none;
        }

        .background {
          height: 100%;
        }
      }

      &.active {
        width: 100%;
        height: var(--soundbar-mobile-active-height);
        background-color: var(--orange);
        color: var(--white);
        display: flex;
        flex-direction: row;

        @media (--md) {
          /* height: var(--soundbar-desktop-active-height); */
          height: 100%;
        }

        .infos {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: units(3);
          width: 50%;
          overflow: hidden;
          word-break: break-word;

          .icon {
            width: units(2);
            height: units(2);
            line-height: 0;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          &-quote {
            @media (--lg) {
              padding: units(6);
            }
          }
        }

        .background {
          width: 50%;
          aspect-ratio: 1/1;
        }
      }
    }

    &-desktop {
      border-radius: units(1);
      overflow: hidden;

      &:deep(.swiper-wrapper) {
        align-items: flex-end;
      }
    }

    &-mobile {
      border-radius: units(1);
      overflow: hidden;

      &:deep(.swiper-wrapper) {
        align-items: flex-end;
      }

      .slide {
        &:not(.active) {
          width: units(7.5);
          height: var(--random-height);

          .infos {
            display: none;
          }

          .background {
            height: 100%;
          }
        }

        &.active {
          width: 100%;
          height: 100%;
          background-color: var(--orange);
          color: var(--white);
          display: grid;

          .infos {
            width: 100%;

            padding: units(3) units(2) units(3) units(2);
            overflow: hidden;
            height: var(--soundbar-mobile-active-height);
            position: relative;

            .icon {
              width: units(1.5);
              height: units(1.5);
              line-height: 0;

              svg {
                width: 100%;
                height: 100%;
              }
            }

            &-quote {
              padding: units(2);
              display: flex;
              flex-direction: column;
              /* justify-content: space-between; */
              gap: units(3);
            }
            &-title {
              word-break: break-word;
            }
            &-copy {
              word-break: break-word;
            }
            &-content {
              position: relative;

              display: flex;
              flex-direction: column;
              word-break: break-all;
              gap: units(3);
            }

            &-expand {
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 2;
              height: 25%;
              width: 100%;
              background: linear-gradient(
                rgba(255, 255, 255, 0.05),
                var(--orange) 50%
              );
              transition: all 0.3s ease-in-out;
              line-height: 0;

              & .icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }

              &.open {
                background: transparent;

                & .icon {
                  transform: translate(-50%, 50%);
                }

                &:deep(.svg) {
                  rotate: 180deg;
                }
              }
            }

            &.open {
              height: calc(var(--infos-height) + units(3));
              padding: units(3) units(2) units(6) units(2);
            }
          }

          .background {
            aspect-ratio: 33/25;
            width: 100%;
            overflow: hidden;
          }
        }
      }

      .middle {
        grid-area: active;
        position: relative;
        height: 100%;

        .slide {
          display: none;

          & .infos {
            /* opacity: 0; */
          }
        }

        & .middle-active {
          display: grid;

          & .infos {
            /* opacity: 1; */
          }
        }
      }
    }
  }

  .swiper-slide {
    &-prev,
    &-next {
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    &-active {
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
    }
  }
}
</style>
