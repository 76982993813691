<script setup>
const props = defineProps({
  search: [Object, String],
});
const searchInput = ref(null);
const searchTerm = ref(null); // v-model
const emit = defineEmits(["perform-search", "input-change"]);
const inputChange = (evt) => {
  emit("input-change", searchTerm.value);
};
const performSearch = (evt) => {
  evt.preventDefault();
  emit("perform-search", searchTerm.value);
};
</script>
<template>
  <div class="search">
    <div class="search__inner">
      <input
        placeholder="Search"
        ref="searchInput"
        type="search"
        v-model="searchTerm"
        @keyup="inputChange($event)"
        @input="inputChange($event)"
      />
      <button
        class="search-button"
        type="submit"
        @click="performSearch($event)"
      >
        <SvgMagnifierGlass />
      </button>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.search {
  input {
    background-color: var(--grey-0);
    min-width: 100%;
    height: 40px;
    line-height: 40px;
    padding: units(1) units(4) units(1) units(2);
    border-radius: 20px;
    border: 0;
    font-weight: bold;
    color: var(--dark-grey);

    &:focus {
      outline: none;
    }

    @media (--md) {
      min-width: 120px;
    }

    &[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      transition: all 0.25s ease;
      width: units(1);
      height: units(1);
      cursor: pointer;
      background: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 43%,
          var(--grey-3) 45%,
          var(--grey-3) 55%,
          rgba(0, 0, 0, 0) 57%,
          rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
          135deg,
          transparent 0%,
          transparent 43%,
          var(--grey-3) 45%,
          var(--grey-3) 55%,
          transparent 57%,
          transparent 100%
        );
    }
  }

  &__inner {
    position: relative;
    display: block;
  }

  .search-button {
    position: absolute;
    top: 50%;
    right: units(1);
    transform: translateY(-50%);
    appearance: none;
    display: flex;
    place-items: center;
    padding: units(1);
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: var(--loud-indigo);
  }
}
</style>
