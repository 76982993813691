/**
 * Fetches an ACF option page from the server.
 * 
 * @param {string} option - The option to fetch.
 * @returns {Promise<{ option: any }>} - The fetched option.
 */
export default async (option, nuxtApp) => {
    const cacheExpiration = 60 * 60 * 1000 // 1 hour

    const config = useRuntimeConfig().public
    const ssApi = config.apiSs

    const { data } = await useFetch(`${ssApi}/${option}`, {
        key: `${ssApi}/${option}`,
        immediate: true,
        lazy: false,
        transform(data) {
            return {
                ...data,
                fetchedAt: new Date()
            }

        },
        getCachedData(key) {
            const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
            // If data is not fetched yet
            if (!data) {
                // Fetch the first time
                return
            }

            // Is the data too old?
            const expirationDate = new Date(data.fetchedAt)
            expirationDate.setTime(expirationDate.getTime() + cacheExpiration)
            const isExpired = expirationDate.getTime() < Date.now()
            if (isExpired) {
                // Refetch the data
                return
            }

            return data
        },
    })

    return {
        option: data.value
    }
}
