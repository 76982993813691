<script setup>
const props = defineProps({
  rich_text: String,
});

const isArticle = inject("is-article", null);
</script>

<template>
  <section class="rich-text page-margin" :class="{ 'is-article': isArticle }">
    <div class="rich-text__inner">
      <div class="rich-text__content">
        <RTE :data="rich_text" />
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.rich-text {
  padding-top: var(--section-margin);
  &.is-article {
    padding-top: 0;
  }
  color: var(--grey-4);
  &__inner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (--md) {
      column-gap: units(2);
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__content {
    grid-column: 4/10;
  }
}
</style>
