/**
 * A composable function that formats a date value.
 * @param {Object} data - The data object containing the date value.
 * @param {string} data.value.date - The date value to be formatted.
 * @param {string} data.value.modified - The modified date value (optional).
 * @returns {string} The formatted date string.
 */
export default () => {
  const formatDate = (data) => {
    let inputDate = data.value?.date

    if (data.value?.modified) {
      inputDate = data.value?.modified
    }

    let output = new Date(inputDate)

    return `${output.getDate()} ${new Intl.DateTimeFormat("en-US", { month: 'short' }).format(output)} ${output.getFullYear()}`
  }

  return {
    formatDate,
  }
}
