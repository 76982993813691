<script setup>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  article: [Object, Array],
  number_of_modules: [Number, String],
  headline: String,
});
const nuxtApp = useNuxtApp();

const openOverlay = async (path) => {
  const { getArticle } = useWpApi();
  const { data, error } = await getArticle(path, nuxtApp);
  useEvent("overlay:open", {
    data: data.value,
    type: "article",
  });
};
const { height, width } = useScreen();
const articleLayer = ref(null);
const sectionTop = ref(0);
const isActive = ref(false);
const numberOfModules = ref(parseInt(props.number_of_modules));
const media = ref(null);
const mediaWidth = ref(0);

const endingArticleTop = ref(0);
const page = ref(null);
const currentArticleId = ref(null);
const articleIds = ref(null);
const endingArticle = ref(null);
const layer = ref(null);
const viewport = useViewport();
onMounted(() => {
  if (!process.client) return;
  if (!viewport.isLessThan("desktopView")) {
    mediaWidth.value = media.value.getBoundingClientRect().width;
  }
  sectionTop.value = articleLayer.value.getBoundingClientRect().top;
  page.value = document.querySelector(".page");
  currentArticleId.value = parseInt(articleLayer.value.getAttribute("data-id"));
  articleIds.value = Array.from(
    page.value.querySelectorAll("[data-id]")
  ).filter((el) => el.getAttribute("data-id") !== null);
  endingArticle.value =
    articleIds.value[currentArticleId.value + numberOfModules.value + 1];
  endingArticleTop.value = Math.abs(
    endingArticle.value?.getBoundingClientRect()?.top - height.value
  );

  for (
    let i = currentArticleId.value + 1;
    i < currentArticleId.value + numberOfModules.value + 1;
    i++
  ) {
    if (
      articleIds.value[i]?.getAttribute("acf_fc_layout") === "article_layer"
    ) {
      endingArticleTop.value = Math.abs(
        articleIds.value[i].getBoundingClientRect().top - height.value * 1.5
      );
    }
  }

  ScrollTrigger.create({
    trigger: articleLayer.value,
    start: `center ${
      height.value - layer.value.getBoundingClientRect().height
    }px`,
    end: "bottom bottom",
    endTrigger: endingArticle.value,
    // markers: true,
    onEnter: () => {
      isActive.value = true;
    },
    onLeave: () => {
      isActive.value = false;
    },
    onEnterBack: () => {
      isActive.value = true;
    },
    onLeaveBack: () => {
      isActive.value = false;
    },
    onUpdate: (e) => {
      if (e.progress.toFixed(2) === "1.00") {
        isActive.value = false;
      }
    },
  });
});

watch(width, () => {
  if (!process.client) return;
  if (viewport.isLessThan("desktopView")) return;
  mediaWidth.value = media.value?.getBoundingClientRect().width;
});
watch([width, height], () => {
  if (!process.client) return;
  if (viewport.isLessThan("desktopView")) return;
  mediaWidth.value = media.value?.getBoundingClientRect().width;
  nextTick(() => {
    ScrollTrigger.refresh();
    mediaWidth.value = media.value?.getBoundingClientRect().width;
  });
});
watch(mediaWidth, () => {
  if (!process.client) return;
  if (viewport.isLessThan("desktopView")) return;
  mediaWidth.value = media.value?.getBoundingClientRect().width;
});
watch(articleLayer, () => {
  if (!process.client) return;
  if (viewport.isLessThan("desktopView")) return;

  nextTick(() => {
    mediaWidth.value = media.value?.getBoundingClientRect().width;
  });
  setTimeout(() => {
    mediaWidth.value = media.value?.getBoundingClientRect().width;
  }, 1000);
});
watch([width, height], () => {
  if (!process.client) return;
  nextTick(() => {
    ScrollTrigger.refresh();
  });
});
</script>

<template>
  <section
    class="article-layer"
    ref="articleLayer"
    :style="`--section-top: ${sectionTop}; --media-width: ${mediaWidth}px;`"
    v-if="article !== null"
  >
    <div
      class="article-layer__layer"
      ref="layer"
      :class="{ active: isActive }"
      @click="openOverlay(article[0]?.relative_link)"
    >
      <div
        class="article-layer__layer-media"
        ref="media"
        v-if="!$viewport.isLessThan('desktopView')"
      >
        <Media
          :media="{ image: article[0]?.image }"
          :cover="true"
          :useThumbnail="true"
        />
      </div>
      <div class="article-layer__layer-content">
        <div class="article-layer__layer-content-inner">
          <div class="article-layer__layer-content-icon">
            <SvgArrow class="icon" />
          </div>
          <div class="article-layer__layer-content-text">
            <p class="ts-caps">{{ headline }}</p>
            <p class="ts-caps-bold">{{ article[0]?.title }}</p>
          </div>
          <div
            class="article-layer__layer-media"
            v-if="$viewport.isLessThan('desktopView')"
          >
            <Media :media="{ image: article[0]?.image }" :cover="true" />
          </div>
        </div>
      </div>
      <div class="article-layer__layer-outline"></div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.article-layer {
  position: relative;
  --media-clipX: 100%;
  --layer-translate: calc(var(--page-margin) + 101%);
  --layer-opacity: 0;
  --layer-bg-color: var(--loud-indigo);
  --layer-txt-color: var(--white);
  --layer-icon-rotation: -135deg;
  --layer-border-radius: units(0.5);
  --outline-color: var(--grey-2);
  --media-clip: calc(var(--media-width) - 1px);

  &__layer {
    position: fixed;
    transform: translateY(var(--layer-translate)) translateX(-50%);
    opacity: var(--layer-opacity);
    transition: all 0.3s ease-in-out;
    bottom: var(--page-margin);

    display: flex;
    flex-direction: row;
    z-index: 99;
    left: 50%;

    @media (--md) {
      transform: translateY(var(--layer-translate));
      left: unset;
      right: var(--page-margin);
      border: none;
      /* transform: unset; */
      height: units(10);
      justify-content: unset;
    }

    @media (--until-md) {
      height: units(8);
      width: calc(100% - var(--page-margin) * 2);
    }

    &.active {
      --layer-translate: 0;

      @media (--max) {
        --layer-translate: 100%;
      }

      --layer-opacity: 1;
    }

    &:hover {
      cursor: pointer;
      --media-clipX: 0;
      --layer-txt-color: var(--loud-indigo);
      --layer-bg-color: var(--grey-0);
      --clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
      --layer-icon-rotation: -90deg;
      --layer-border-radius: 0;
      --outline-color: var(--grey-0);
      --media-clip: -1px;
    }

    &-outline {
      position: absolute;
      inset: -1px;
      background-color: var(--outline-color);
      z-index: -2;
      border-radius: units(0.5);

      @media (--md) {
        display: block;
        position: absolute;
        inset: -1px -1px -1px var(--media-clip);
        background-color: var(--outline-color);
        z-index: -2;
        border-radius: units(0.5);
        transition: all 0.3s ease-in-out;
      }
    }

    &-media {
      aspect-ratio: 1/1;
      height: 100%;
      border-radius: units(0.5);
      overflow: hidden;

      @media (--md) {
        pointer-events: none;
        transform-origin: right;
        transition: clip-path 0.3s ease-in-out;

        clip-path: polygon(
          var(--media-clipX) 0,
          100% 0,
          100% 100%,
          var(--media-clipX) 100%
        );
        border-radius: units(0.5) 0 0 units(0.5);
      }
    }

    &-content {
      width: 100%;

      @media (--md) {
        background-color: var(--layer-bg-color);
        height: units(8);
        width: units(35);
        padding: units(1);
        border-radius: var(--layer-border-radius) units(0.5) units(0.5)
          var(--layer-border-radius);
      }

      &-inner {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        gap: units(2);
        color: var(--layer-txt-color);
        align-items: center;

        @media (--until-md) {
          transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
            border-radius 0.2s ease-in-out;

          background-color: var(--layer-bg-color);
          padding: units(1);
          transition: background-color 0.2s ease-in-out,
            transform 0.3s ease-in-out;
          justify-content: space-between;
          border-radius: units(0.5);
        }
      }

      @media (--md) {
        height: units(10);
      }

      &-icon {
        padding-left: units(1);

        .icon {
          transition: rotate 0.2s ease-in-out;
          rotate: var(--layer-icon-rotation);
          fill: var(--layer-txt-color);
          height: units(2);
          width: units(2);
        }
      }
    }
  }
}
</style>
