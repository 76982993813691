<script setup></script>

<template>
  <svg width="23" height="19" viewBox="0 0 23 19" fill="currentColor">
    <path
      d="M17.625 18.55C15.7917 18.55 14.38 17.9267 13.39 16.68C12.4367 15.3967 11.96 13.6917 11.96 11.565C11.96 9.21833 12.7483 7.07333 14.325 5.13C15.9383 3.18667 18.6333 1.73833 22.41 0.784997V4.03C21.4567 4.39666 20.6317 4.745 19.935 5.075C19.2383 5.36833 18.7067 5.71666 18.34 6.12C18.01 6.52333 17.845 7.09167 17.845 7.825C17.845 8.22833 17.9733 8.55833 18.23 8.815C18.5233 9.035 18.8717 9.27333 19.275 9.53C19.6783 9.75 20.0633 10.0433 20.43 10.41C20.8333 10.74 21.1633 11.2167 21.42 11.84C21.7133 12.4633 21.86 13.2883 21.86 14.315C21.86 15.6717 21.4567 16.7167 20.65 17.45C19.8433 18.1833 18.835 18.55 17.625 18.55ZM5.745 18.55C3.91167 18.55 2.5 17.9267 1.51 16.68C0.556667 15.3967 0.0800001 13.6917 0.0800001 11.565C0.0800001 9.21833 0.868334 7.07333 2.445 5.13C4.05833 3.18667 6.75333 1.73833 10.53 0.784997V4.03C9.57667 4.39666 8.75167 4.745 8.055 5.075C7.35833 5.36833 6.82667 5.71666 6.46 6.12C6.13 6.52333 5.965 7.09167 5.965 7.825C5.965 8.22833 6.09333 8.55833 6.35 8.815C6.64333 9.035 6.97333 9.27333 7.34 9.53C7.74333 9.75 8.14667 10.0433 8.55 10.41C8.95333 10.74 9.28333 11.2167 9.54 11.84C9.83333 12.4633 9.98 13.2883 9.98 14.315C9.98 15.6717 9.57667 16.7167 8.77 17.45C7.96333 18.1833 6.955 18.55 5.745 18.55Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="postcss" scoped>
svg {
}
</style>
