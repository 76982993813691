<script setup>
const props = defineProps({
  post_title: String,
  acf: [Object, null],
  link: String,
});
//put link value in a propety called page_link inside an object called data
const data = reactive({
  //if props.link starts with the same domain as the current domain, then it's an internal link and we need to remove the domain from the link
  page_link:
    process.client && props.link.startsWith(window.location.origin)
      ? props.link.replace(window.location.origin, "")
      : props.link,
  // page_link: props.link,
});
</script>

<template>
  <CustomLink :data="data" :prefetch="true" class="card-news">
    <template v-slot:label>
      <div class="card-news-wrapper">
        <div class="header">
          <div class="tag ts-caps-bold">{{ acf?.taxonomy?.name }}</div>
        </div>
        <div class="content">
          <div class="text">
            <div class="date ts-caps">{{ acf?.trumpet }}</div>
            <div class="title ts-h4">{{ post_title }}</div>
          </div>
        </div>
        <div class="background">
          <div class="gradient"></div>
          <Media
            v-if="acf?.image"
            :media="{ image: acf?.image }"
            :cover="true"
            class="media"
          />
        </div>
      </div>
    </template>
  </CustomLink>
</template>

<style lang="postcss" scoped>
.card-news {
  height: 100%;
  width: 100%;
  aspect-ratio: 35/40;
  @media (--md) {
    aspect-ratio: 4/5;
  }
  .card-news-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: units(2);
    border-radius: units(1);
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }
  color: var(--white);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .tag {
      border: 1px solid var(--white);
      border-radius: units(1);
      padding: units(0.8);
      margin-left: auto;
    }
  }

  .content {
    .text {
      display: flex;
      flex-direction: column;
      gap: units(2);
    }
  }
  .header,
  .content {
    z-index: 2;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-4);

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 35%,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }

    .media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
      transition: scale 0.3s ease-in-out;
      &:hover {
        scale: 1.05;
      }
    }
  }
}
</style>
