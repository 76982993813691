<script setup>
const props = defineProps({
  media: Object,
  trumpet: String,
  headline: String,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section media-text"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div v-if="media" class="media-text__media">
      <Media :media="media" :cover="true" :loop="true" />
    </div>

    <div class="media-text__content page-margin">
      <div class="media-text__trumpet reveal">{{ trumpet }}</div>
      <p class="ts-large-serif media-text__headline reveal">{{ headline }}</p>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.media-text {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: flex;
  flex-direction: column;
  padding-bottom: units(5);
  @media (--md) {
    padding-bottom: units(9);
  }

  &__media {
    width: 100%;
    height: 60dvh;
  }

  &__content {
    color: var(--grey-4);
    width: 100vw;
    padding: units(5) units(2) 0;
    text-align: left;
    @media (--md) {
      padding: units(6) units(15) 0 units(15);
      text-align: center;
    }
  }

  &__trumpet {
    margin-bottom: units(2);
  }
  &__headline {
    transition-delay: 0.2s;
  }
}
</style>
