<script setup>
const props = defineProps({
  headline: String,
  rich_text: String,
  call_to_action: Object,
  media: Object,
  layout: String,
  caption: String,
  heading_tag: [Object, Boolean, null],
});

const headingTag = computed(() =>
  props.heading_tag ? props.heading_tag.value : "h2"
);
const captionRef = ref(null);
const getCaptionHeight = computed(() => {
  if (captionRef.value) {
    return captionRef.value.offsetHeight;
  }
  return 0;
});
const isArticle = inject("is-article", null);
const isOverlay = inject("is-overlay", null);

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="split-content section"
    :class="{
      'left-image': layout === 'left-image',
      'is-article': isArticle,
      'can-run': isVisible,
      'is-overlay': isOverlay,
    }"
    :style="`--caption-height:${getCaptionHeight}px`"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="split-content__content reveal">
      <div class="split-content__content-text">
        <component v-if="headline" :is="headingTag" class="title ts-h4">{{
          headline
        }}</component>
        <RTE :data="rich_text" />
      </div>
      <ButtonCTA
        type="primary"
        v-if="
          call_to_action?.label &&
          (call_to_action?.url || call_to_action?.page_link)
        "
        :cta="call_to_action"
        :has-icon="true"
        icon="arrowsimple"
      />
    </div>
    <div class="split-content__image reveal">
      <Media
        :media="media"
        :cover="true"
        class="media"
        :class="{ 'media-left': layout === 'left-image' }"
      ></Media>
      <p class="split-content__image-legend ts-caption-italic" ref="captionRef">
        {{ caption }}
      </p>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.split-content {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  &.is-overlay {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: grid;

  @media (--until-md) {
    gap: calc(units(6) + var(--caption-height));
  }
  @media (--md) {
    grid-template-columns: 1fr 1fr;
  }
  padding-bottom: calc(var(--section-margin) + var(--caption-height));

  &.is-article {
    padding-top: var(--section-margin);
  }
  &.left-image {
    .split-content__image {
      @media (--md) {
        order: 1;
      }
      &-legend {
        padding-left: var(--page-margin);
        padding-right: 0;
      }
    }
    .split-content__content {
      @media (--md) {
        order: 2;
        padding-right: var(--page-margin);
        padding-left: units(6);
      }
    }
  }

  &__image {
    position: relative;
    height: 100%;
    order: 1;
    transition-delay: 0.1s;
    @media (--md) {
      order: 2;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .media {
      @media (--md) {
        border-radius: units(0.5) 0px 0px units(0.5);
        overflow: hidden;

        &-left {
          border-radius: 0px units(0.5) units(0.5) 0px;
        }
      }
    }

    &-legend {
      padding-right: var(--page-margin);
      padding-top: units(1);
      @media (--until-md) {
        padding-left: var(--page-margin);
        padding-right: 0;
      }
    }
  }

  &__content {
    order: 2;
    @media (--md) {
      order: 1;
      padding-right: units(6);
      padding-left: 0;
    }
    @media (--until-md) {
      padding-right: var(--page-margin);
      padding-left: 0;
    }
    .title {
      color: var(--loud-indigo);
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      gap: units(3);
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: units(6);
    padding-left: var(--page-margin);
  }
}
</style>
