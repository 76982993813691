<script setup>
const error = useError();

const handleError = () => {
  if (!process.client) return;
  window.location.href = "/";
};
</script>

<template>
  <NuxtLayout name="clean" class="layout">
    <main class="page-margin">
      <div class="error" v-if="error">
        <div class="error__content">
          <div class="ts-caption trumpet">Error {{ error.statusCode }}</div>
          <h1 class="ts-h1 title">Sorry, we can’t find the page</h1>

          <ButtonCTA
            @click="handleError"
            label="Back to homepage"
            type="primary"
            :has-icon="true"
            icon="arrowsimple"
            class="cta"
          >
            Back to homepage
          </ButtonCTA>
        </div>
        <div v-if="!$viewport.isLessThan('desktopView')" class="error__status">
          {{ error.statusCode }}
        </div>
      </div>
      <DevOnly>
        <div style="margin-bottom: 150px">
          (dev only)
          <br />
          {{ error.message }}
          <br />
          <br />
          <div v-html="error.stack"></div>
          <!-- {{ error.stack }} -->
        </div>
      </DevOnly>
    </main>
  </NuxtLayout>
</template>
<style lang="postcss">
@import "@/assets/css/global.css";
</style>

<style lang="postcss" scoped>
main {
  position: relative;
  height: 100vh;
}

.error {
  display: grid;
  align-items: flex-start;
  padding-top: units(9);
  padding-bottom: units(9);
  @media (--md) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: units(2);
  }
  &__content {
    @media (--md) {
      grid-column: 1/6;
    }
    .title {
      color: var(--grey-4);
      margin-bottom: units(12);
      @media (--md) {
        margin-bottom: units(8);
      }
    }

    .trumpet {
      color: var(--loud-indigo);
      margin-bottom: units(2);
    }
    .cta {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__status {
    @media (--md) {
      grid-column: 8/12;
      color: var(--Loud-Indigo, #7000ff);
      text-align: center;
      font-family: Noto Serif;
      font-size: 240px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -9.6px;
    }
  }
}
</style>
