<script setup>
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  slides: [Object, Array],
});
const videoId = Math.random();
provide("videoId", videoId);

const video = ref(null);
const videoDuration = ref(0);
useListen("video:loaded", (data) => {
  if (data.id !== videoId) return;
  video.value = data.video;
});
let myInterval = 0;
const slider = ref(null);
const onSwiper = (swiper) => {
  slider.value = swiper;
};

const slideNext = () => {
  clearInterval(myInterval);
  if (slider.value.slides.length - 1 === slider.value.realIndex) {
    slider.value.update();
    slider.value.slideTo(0);
  } else {
    slider.value.update();
    slider.value.slideNext();
  }
};

onMounted(() => {
  if (slider.value) {
    if (video.value) {
      slider.value.update();
      video.value.currentTime = 0;
      video.value.pause();
      video.value.addEventListener("ended", function () {
        slideNext();
        slider.value.update();
      });
    }

    slider.value.on("slideChange", function (e) {
      clearInterval(myInterval);
      slider.value.update();
      const slide = slider.value.slides[slider.value.realIndex];
      if (slide.querySelector("video")) {
        video.value.currentTime = 0;
        video.value.play();
        videoDuration.value = video.value.duration * 1000;
        slider.value.update();
      } else {
        myInterval = setInterval(slideNext, 3000);
        videoDuration.value = 3000;
        slider.value.update();
      }
    });
    slideNext();
  }
});
</script>

<template>
  <section
    class="progress-slider"
    :style="`--animation-duration:${videoDuration}ms`"
  >
    <swiper
      @swiper="onSwiper"
      :slidesPerView="1"
      :loop="true"
      :pagination="{
        el: '.swiper-pagination',
        clickable: true,
      }"
      :speed="1300"
      :watchSlidesProgress="true"
      :modules="[Pagination]"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        style="color: white"
      >
        <div class="slide__inner">
          <div
            class="slide__background"
            v-if="slide.media.image || slide.media.video.mp4_video"
          >
            <Media :media="slide.media" :cover="true" />
          </div>
          <div class="slide__content">
            <Media
              v-if="slide.hasMediaLogo"
              :media="slide.media"
              :cover="true"
              class="media-logo"
            />
            <h1 v-else class="ts-h1">{{ slide.headline }}</h1>
            <p class="ts-p1">
              {{ slide.copy }}
            </p>
            <ButtonCTA
              v-if="
                slide.call_to_action.label &&
                (slide.call_to_action.url || slide.call_to_action.page_link)
              "
              class="btn"
              :has-icon="true"
              icon="arrowsimple"
              :cta="slide.call_to_action"
              type="secondary-outline"
            />
          </div></div
      ></swiper-slide>
    </swiper>
    <div class="swiper-pagination page-margin"></div>
  </section>
</template>

<style lang="postcss">
.progress-slider {
  width: 100%;
  height: 100%;
  position: relative;
  .swiper {
    height: 100%;
  }
  .slide {
    &__inner {
      width: 100%;
      height: 100%;
    }
    &__background {
      width: 100%;
      height: 100%;
    }
    &__content {
      padding: var(--grid-margin);
      color: var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: units(3);
      z-index: 2;
      bottom: 20%;
      position: absolute;
      @media (--md) {
        top: 50%;
        left: 50%;
        transform: translate(0, -40%);
        padding-right: units(20);
      }
    }
  }
  .swiper-slide {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    gap: 1.25rem;
    position: absolute;
    bottom: units(3);
    z-index: 1;
  }

  .swiper-pagination-bullet {
    width: 100%;
    height: 2px;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    background: var(--grey-1);

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .swiper-pagination-bullet-active {
    &::before {
      background-color: var(--loud-indigo);
      animation: slide-progress var(--animation-duration)
        cubic-bezier(0.3, 0, 0.3, 1) forwards;

      .swiper-paused & {
        animation-play-state: paused;
      }
    }
  }

  @keyframes slide-progress {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }
}
</style>
