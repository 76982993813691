<script setup>
const props = defineProps({
  media: Object,
});
const lazyImage = inject("lazyImage", true);
const useThumbnail = inject("useThumbnail", true);

const loaded = () => {
  console.log("loaded");
};

const state = ref(null);

const sizesAttribute = computed(() =>
  Object.entries(state.value.sizes)
    .map(([key, value]) => `(max-width: ${value}px) ${value}w`)
    .join(", ")
);

const srcsetAttribute = computed(() =>
  Object.entries(state.value.imageUrls)
    .map(([key, url]) => `${url} ${state.value.sizes[key] + "w"}`)
    .join(", ")
);

const imageWidth = ref(props.media.width);
const imageHeight = ref(props.media.height);
const handleResize = () => {
  if (width.value >= 300) {
    imageWidth.value = props.media?.sizes["medium-width"];
    imageHeight.value = props.media?.sizes["medium-height"];
  }
  if (width.value >= 768) {
    imageWidth.value = props.media?.sizes["medium_large-width"];
    imageHeight.value = props.media?.sizes["medium_large-height"];
  }
  if (width.value >= 1024) {
    imageWidth.value = props.media?.sizes["large-width"];
    imageHeight.value = props.media?.sizes["large-height"];
  }
  if (width.value >= 1536) {
    imageWidth.value = props.media?.sizes["1536x1536-width"];
    imageHeight.value = props.media?.sizes["1536x1536-height"];
  }
  if (width.value >= 2048) {
    imageWidth.value = props.media?.sizes["2048x2048-width"];
    imageHeight.value = props.media?.sizes["2048x2048-height"];
  } else {
    imageWidth.value = props.media?.sizes["thumbnail-width"];
    imageHeight.value = props.media?.sizes["thumbnail-height"];
  }
};

const { width } = useScreen();

watch(
  () => width.value,
  () => {
    handleResize();
  },
  {
    immediate: true,
  }
);
watch(
  () => props.media,
  () => {
    state.value = {
      imageSrc: props.media?.url,
      imageUrls: {
        thumbnail: props.media?.sizes.thumbnail,
        medium: props.media?.sizes.medium,
        medium_large: props.media?.sizes.medium_large,
        large: props.media?.sizes.large,
        larger: props.media?.sizes["1536x1536"],
        big: props.media?.sizes["2048x2048"],
      },
      sizes: {
        thumbnail: "150",
        medium: "300",
        medium_large: "768",
        large: "1024",
        larger: "1536",
        big: "2048",
      },
      width: {
        thumbnail: 150,
        medium: 300,
        medium_large: 768,
        large: 1024,
        larger: 1536,
        big: 2048,
      },
      height: {
        thumbnail: 150,
        medium: 300,
        medium_large: 768,
        large: 1024,
        larger: 1536,
        big: 2048,
      },
    };
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <NuxtImg
    v-if="media && state"
    class="image"
    :placeholder="[50, 25, 25, 15]"
    :lazy="lazyImage"
    :loading="lazyImage ? 'lazy' : 'eager'"
    :src="useThumbnail ? media.sizes['thumbnail'] : media.url"
    :alt="media.alt"
    :srcset="useThumbnail ? null : srcsetAttribute"
    :width="useThumbnail ? media.sizes['thumbnail-width'] : imageWidth"
    :height="useThumbnail ? media.sizes['thumbnail-height'] : imageHeight"
  />
</template>

<style lang="postcss" scoped>
.image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
