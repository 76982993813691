<script setup>
const props = defineProps({
  headline: String,
  items: [Object, Array],
  heading_tag: [Object, Boolean, null],
});

const headingTag = computed(() =>
  props.heading_tag ? props.heading_tag.value : "h2"
);

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin static-list"
    :class="{ 'can-run': isVisible }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <component
      v-if="headingTag"
      :is="headingTag"
      class="static-list__headline ts-h2 reveal"
      >{{ headline }}</component
    >
    <div class="static-list__list">
      <div
        class="static-list__item reveal"
        v-for="(item, index) in items"
        :key="`report-${index}`"
        :style="`transition-delay: ${index * 0.1}s`"
      >
        <div class="static-list__item-healdine" v-if="item.headline">
          {{ item.headline }}
        </div>
        <div class="static-list__item-copy">
          <p class="ts-caps">{{ item.copy }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.static-list {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: units(8);
  &__headline {
    color: var(--grey-4);
    padding: units(9) 0 units(6) 0;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: units(4);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--grey-0);
      top: units(-2);
      left: 0;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: units(2);
    align-items: left;
    color: var(--grey-4);

    @media (--md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &-copy {
      flex-shrink: 0;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--grey-0);
      bottom: units(-2);
      left: 0;
    }
  }
}
</style>
