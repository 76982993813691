<script setup>
const props = defineProps({});

const emit = defineEmits(["go-previous", "go-next"]);

function goPrevious() {
  emit("go-previous", true);
}

function goNext() {
  emit("go-next", true);
}
</script>

<template>
  <div class="carousel-controls">
    <div
      class="carousel-controls__previous"
      @click="goPrevious"
      aria-label="previous"
    >
      <SvgArrow class="icon" />
    </div>
    <div class="carousel-controls__next" @click="goNext" aria-label="next">
      <SvgArrow class="icon" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.carousel-controls {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: units(2);
  /* justify-content: space-between; */

  &__previous,
  &__next {
    cursor: pointer;
    position: relative;
    align-self: end;
    display: flex;
    flex-direction: row;
    gap: units(2);
  }

  &__next {
    &:hover {
      animation: wiggle-cw 0.5s ease-in-out;
    }
  }

  &__previous {
    transform: rotate(180deg);
    &:hover {
      animation: wiggle-ccw 0.5s ease-in-out;
    }
  }
  .icon {
    width: units(2);
  }
}
</style>
