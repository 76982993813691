<script setup>
useHead({
  meta: [{ name: "theme-color", content: "#7000FF" }],
});
useSchemaOrg([defineWebSite()]);
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const { getLast } = useNavigationHistory(true);

import fallback from "~/transitions/fallback";

const pageTransitions = usePageTransition({
  defaultTransition: fallback,
  transitions: {},
  globalHooks: {
    onBeforeLeave() {
      // console.log("onBeforeLeave", window.scrollY);
    },
    onLeave() {
      // console.log("onLeave", window.scrollY);
      useEvent("v-view-pause");
    },
    onEnter() {
      // console.log("onEnter", window.scrollY);
      useEvent("v-view-unpause");
      getLast();
      window.scrollTo({
        top: getLast().navigatingBack ? getLast().scrollPos : 0,
        behavior: "instant",
      });
      ScrollTrigger.refresh();
    },
  },
});

watch(usePageTransitionRunning, () => {
  scrollLock(usePageTransitionRunning.value);
});

onMounted(() => {
  // Work around for https://github.com/nuxt/nuxt/issues/13350
  // @TODO Remove when fixed
  if (typeof window !== "undefined") {
    window.addEventListener("error", (ev) => {
      const messages = [
        `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // Chromium
        `NotFoundError: Node.insertBefore: Child to insert before is not a child of this node`, // Firefox
        `NotFoundError: The object can not be found here.`, // Safari
      ];

      if (messages.some((message) => ev.message.includes(message))) {
        console.warn(
          "Reloading due to error: https://github.com/nuxt/nuxt/issues/13350"
        );
        ev.preventDefault();
        window.location.reload();
      }
    });
  }
});

const scrollToContent = (e) => {
  if (process.client) {
    const heroElement = document.querySelector(".hero");
    if (heroElement) {
      const contentPosition =
        heroElement.nextElementSibling.getBoundingClientRect().top;
      window.scrollTo({
        top: contentPosition,
        behavior: "smooth",
      });
    }
    e.currentTarget.blur();
  }
};
</script>

<template>
  <div class="app">
    <a
      href="#maincontent"
      @click="scrollToContent($event)"
      class="skip-to-content-link"
      aria-label="Skip to content"
      >Skip to content</a
    >
    <GlobalOverlay />
    <NuxtLayout
      class="layout-from-app"
      :class="{ 'is-transitioning': usePageTransitionRunning }"
    >
      <NuxtPage :transition="pageTransitions" />
    </NuxtLayout>
  </div>
</template>
<style lang="postcss">
@import "@/assets/css/global.css";
@import "plyr/dist/plyr.css";
</style>

<style lang="postcss" scoped>
.skip-to-content-link {
  background: var(--deep-indigo);
  color: var(--white);
  position: absolute;
  top: 0;
  left: 50%;
  padding: units(1);
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  z-index: 2;
}

.skip-to-content-link:focus {
  transform: translate(-50%, 0%);
  opacity: 1;
}

.app {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 45vh;
    z-index: 0;
  }
}

:deep(.layout-from-app) {
  position: relative;
  z-index: 1;
}
</style>
