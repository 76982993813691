import gsap from 'gsap'

const durationLeave = () => (window.innerWidth < 1200 ? 0.5 : 0.8)
const durationEnter = () => (window.innerWidth < 1200 ? 0.5 : 1.1)
const easeLeave = 'ease.in'
const easeEnter = 'ease.out'

let overlay = null


function onBeforeLeave(target, el, payload) {
    // console.log('onBeforeLeave', el)

    // remove any old overlay
    document.querySelector('.overlay-wipe')?.remove()

    overlay = document.createElement('div')
    overlay.classList.add('overlay-wipe')

    gsap.set(overlay, {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 19,
        opacity: 0,
    })

    document.body.append(overlay)
}

function onLeave(el, done, payload) {
    // console.log('onLeave', el)
    gsap.set(overlay, {
        backgroundColor: 'var(--loud-indigo)',
    })

    gsap.to(overlay, {
        duration: durationLeave(),
        ease: easeLeave,
        opacity: 1,
        onComplete: done,
    })
}
function onBeforeEnter(el, done, payload) {
}

/**
 * Handles the onEnter transition for an element.
 * @param {HTMLElement} el - The element to transition.
 * @param {Function} done - The callback function to be called when the transition is complete.
 * @param {any} payload - Additional data for the transition.
 * @returns {Promise<void>} A promise that resolves when the transition is complete.
 */
async function onEnter(el, done, payload) {
    // console.log('onEnter', el)
    setTimeout(() => {

        if (!overlay) {
            done()
            return
        }

        gsap.to(overlay, {
            duration: durationEnter() / 2,
            ease: easeEnter,
            backgroundColor: 'var(--loud-indigo)',
        })
        gsap.to(overlay, {
            duration: durationEnter(),
            ease: easeEnter,
            opacity: 0,
            backgroundColor: 'var(--loud-indigo)',
            onComplete: () => {
                done()
            },
        })
    })
}

function onAfterEnter(el, payload) {
    // console.log('onAfterEnter', el)
    setTimeout(() => el?.removeAttribute('style'))
    overlay?.remove()
}

export default {
    onBeforeLeave,
    onLeave,
    onBeforeEnter,
    onEnter,
    onAfterEnter,
}