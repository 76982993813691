<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  slides: [Object, Array],
  activeIndex: Number,
  side: String,
  maxHeight: { type: Number, default: 450 },
});

const slider = ref(null);
const onSwiper = (swiper) => {
  slider.value = swiper;
};
const emit = defineEmits([
  "update-activeIndex",
  "update-left-activeIndex",
  "update-right-activeIndex",
]);

const setActiveIndex = (index) => {
  if (index !== props.activeIndex) {
    props.side === "left"
      ? emit("update-left-activeIndex", index)
      : emit("update-right-activeIndex", index);
  }
};

watch(
  () => props.activeIndex,
  (newVal) => {
    if (slider.value) {
      slider.value.slideTo(newVal);
    }
  }
);
const viewport = useViewport();
const getMaxHeight = computed(() => {
  if (!process.client) return;
  if (viewport.isLessThan("bigDesktopView")) {
    return 250;
  } else {
    return 450;
  }
});
const randomHeightPerItem = ref([]);
const setRandomHeightPerItem = () => {
  props.slides.forEach((slide, index) => {
    randomHeightPerItem.value[index] = randomRange(60, getMaxHeight.value);
  });
};
const randomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

onMounted(() => {
  setRandomHeightPerItem();
});
const { width } = useScreen();

watch(width, () => {
  if (!process.client) return;
  setRandomHeightPerItem();
  slider.value.update();
});
</script>

<template>
  <section class="side-soundbar-carousel" ref="side">
    <swiper
      @swiper="onSwiper"
      :speed="800"
      :slidesPerView="'auto'"
      :class="{ left: props.side === 'left' }"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        class="slide"
        :class="{
          'left-active': index < activeIndex && props.side === 'left',
          'right-active': index > activeIndex && props.side === 'right',
        }"
        :style="`--random-height: ${
          randomHeightPerItem[index] + randomRange(-15, 15)
        }px;`"
        @click="setActiveIndex(index)"
      >
        <div
          class="background"
          v-if="slide.media.image || slide.media.video.mp4_video"
        >
          <Media
            :media="slide.media"
            :is-not-lazy-video="true"
            :cover="true"
            :paused="true"
          />
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<style lang="postcss" scoped>
.side-soundbar-carousel {
  width: 100%;
  height: 100%;

  .swiper {
    height: 100%;

    &.left {
      &:deep(.swiper-wrapper) {
        justify-content: flex-end;
      }
    }

    .slide {
      transition: all 0.5s ease-in-out;
      height: var(--random-height);
      align-self: flex-end;
      width: units(7.5);
      border-radius: units(1);
      overflow: hidden;
      flex-shrink: 0;
      visibility: hidden;
      width: 0;
      margin-right: 0;
      margin-left: 0;

      .background {
        height: 100%;
      }
    }

    .left-active,
    .right-active {
      width: units(7.5);
      visibility: visible;
      cursor: pointer;
    }

    .right-active {
      margin-right: units(1);
    }

    .left-active {
      margin-left: units(1);
    }
  }
}
</style>
