<script setup>
const props = defineProps({
  heading: String,
  copy: String,
  image: Object,
  trumpet: String,
  promoted_products: [Array, Object],
  call_to_action_card: Object,
  split_content: Object,
});
const numberOfProducts = 3;
</script>

<template>
  <section
    class="brand-promotion section"
    :class="{ 'page-margin': !$viewport.isLessThan('desktopView') }"
    :style="{ '--nb-columns': numberOfProducts + 1 }"
  >
    <SplitContent
      :headline="split_content?.headline"
      :rich_text="split_content?.rich_text"
      :media="split_content?.media"
      :layout="split_content?.layout"
      :call_to_action="split_content?.call_to_action"
      class="brand-promotion__split-content"
    />
    <div class="trumpet ts-caps-bold">{{ trumpet }}</div>
    <div class="products" v-if="!$viewport.isLessThan('desktopView')">
      <ElementsProduct
        v-for="(product, index) in promoted_products"
        class="products-item"
        :key="index"
        :product="product"
      />
      <div class="link-card">
        <div class="link-card__content">
          <h2 class="link-card__title ts-caption-bold">
            {{ call_to_action_card?.card_label }}
          </h2>
          <ButtonCTA
            v-if="
              call_to_action_card.call_to_action.label &&
              (call_to_action_card?.call_to_action?.url ||
                call_to_action_card?.call_to_action?.page_link)
            "
            class="link-card__cta"
            type="secondary"
            :has-icon="true"
            icon="arrowsimple"
            :cta="call_to_action_card?.call_to_action"
          />
        </div>
      </div>
    </div>
    <div class="products" v-else>
      <standard-carousel>
        <template v-slot:slide>
          <ElementsProduct
            v-for="(product, index) in promoted_products"
            class="products-item"
            :key="index"
            :product="product"
          />
          <div class="link-card standard-carousel__gallery-item">
            <div class="link-card__content">
              <h2 class="link-card__title ts-caption-bold">
                {{ call_to_action_card?.card_label }}
              </h2>
              <ButtonCTA
                v-if="
                  call_to_action_card?.call_to_action?.url ||
                  call_to_action_card?.call_to_action?.page_link
                "
                class="link-card__cta"
                type="secondary"
                :has-icon="true"
                icon="arrowsimple"
                :cta="call_to_action_card?.call_to_action"
              />
            </div>
          </div>
        </template>
      </standard-carousel>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.brand-promotion {
  display: flex;
  flex-direction: column;
  gap: units(3);
  padding-top: units(6);
  padding-bottom: units(9);

  &__split-content {
    &:deep(.split-content__content) {
      @media (--md) {
        padding-left: 0;
      }
    }
  }

  .trumpet {
    color: var(--loud-indigo);
    @media (--until-md) {
      padding-left: var(--page-margin);
    }
  }
  .products {
    position: relative;
    @media (--md) {
      display: grid;
      grid-template-columns: repeat(var(--nb-columns), 1fr);
      gap: units(2);
    }
    &-item {
      color: var(--grey-4);
      width: 100%;
      @media (--until-md) {
        height: 100%;
      }
    }

    .link-card {
      height: 100%;
      width: 100%;
      border-radius: units(1);
      background: var(--loud-indigo);
      padding: units(2);
      color: var(--white);
      aspect-ratio: 1/1;
      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &__cta {
        margin-left: auto;
      }
    }
  }
}
</style>
