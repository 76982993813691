export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('page:start', () => {
    const cookies = document.cookie.split('; ');
    const sstCookieValue = cookies.find((row) => row.startsWith('sst_value='))?.split("=")[1];
    const noticeBehavior = cookies.find((row) => row.startsWith('notice_behavior='))?.split("=")[1];
    const cmapiCookiePrivacy = cookies.find((row) => row.startsWith('cmapi_cookie_privacy='))?.split("=")[1];
    const cookieString = noticeBehavior + cmapiCookiePrivacy;
    const regex = /implied|expressed.*3/;
    const domain = window.location.hostname.replace('www.', '');
    if(regex.test(cookieString)) {
      // If the cookie exists, we just update the lifetime
      if(sstCookieValue) {
        const twoYearsFromNow = new Date();
        twoYearsFromNow.setFullYear(twoYearsFromNow.getFullYear() + 2);
        document.cookie = "sst_value=" + sstCookieValue + " ; " + twoYearsFromNow.toUTCString() + "; domain=" + domain + "; path=/; secure";
      }
    }
    else {
      if(sstCookieValue) {
        document.cookie = "sst_value= ; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=" + domain + "; path=/; secure";
      }
    }
  });
});
