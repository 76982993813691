<script setup>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const props = defineProps({
  items: Array,
  total_label: String,
});

const content = ref(null);
const graph = ref(null);
const solutions = ref(null);
const numbersItems = ref([]);
const activeIndex = ref(0);
const ripple_opacity = ref(0);
onMounted(() => {
  ScrollTrigger.create({
    trigger: graph.value,
    start: "top center",
    end: "center center",
    endTrigger: numbersItems.value.at(-1),
    pin: true,
    pinSpacing: false,
    onUpdate: (e) => {
      ripple_opacity.value = 1 - e.progress.toFixed(2);
      if (ripple_opacity.value <= 0.5) {
        ripple_opacity.value = 0.1;
      }
    },
  });

  ScrollTrigger.create({
    trigger: solutions.value,
    start: "top center",
    end: "center center",
    endTrigger: numbersItems.value.at(-1),
    // markers: true,
    pin: true,
    pinSpacing: false,
  });

  numbersItems.value.forEach((item, index) => {
    ScrollTrigger.create({
      trigger: item,
      start: "center center",
      end: "+=110 center",
      pinSpacing: false,
      // markers: true,
      snap: {
        snapTo: 1,
        duration: 1,
        ease: "easeInOut",
      },
      onEnter: () => {
        setActiveIndex(index);
      },
      onEnterBack: () => {
        setActiveIndex(index);
      },
      onSnapComplete: (e) => {
        if (e.progress === 1 && index < numbersItems.value.length - 1) {
          setActiveIndex(index + 1);
        }
      },
    });
  });
});

const setActiveIndex = (index) => (activeIndex.value = index);

const scrollToNumber = (index) => {
  if (!process.client) return;
  const element = numbersItems.value[index];
  let y = (window.innerHeight - element.getBoundingClientRect().height) / 2;
  gsap.to(window, {
    duration: 1,
    scrollTo: {
      y: element,
      offsetY: y,
      autoKill: false,
    },
    ease: "power2.inOut",
    onComplete: () => {
      setActiveIndex(index);
    },
  });
};
</script>

<template>
  <div class="content" ref="content" :style="{
    '--active-color': items[activeIndex].color,
    '--active-size': items[activeIndex].size + '%',
    '--ripple-opacity': ripple_opacity ? ripple_opacity : 1,
  }">
    <ul class="numbers-list">
      <li v-for="(item, index) in items" :key="`chart-numbers-${index}`" class="numbers-list__item" ref="numbersItems"
        :class="{ 'is-active ': index == activeIndex }" @click="scrollToNumber(index)">
        <div class="number">
          <div class="ts-numbers">{{ item.number }}</div>
          <RTE v-bind="{ data: item.unit }" class="rich-text" />
        </div>
      </li>
    </ul>

    <div class="graph" ref="graph">
      <div class="bubble" ref="bubble">
        <div class="bubble__label bubble__label--inner">
          {{ items[activeIndex].number }} {{ items[activeIndex].short_unit }}
        </div>

        <div class="bubble__outer">
          <div class="bubble__outer-circle"></div>
          <div class="bubble__ripple">
            <ElementsRippleShader class="ripple-shader" />
          </div>
        </div>

        <div class="bubble__inner"></div>
        <div class="bubble__label bubble__label--outer">{{ total_label }}</div>
      </div>
    </div>

    <div class="solutions" ref="solutions">
      <div class="solutions__label ts-trumpet">Solution</div>

      <ul class="solutions-list">
        <li v-for="(item, index) in items" :key="`chart-solution-${index}`" class="solutions-list__item"
          :class="{ 'is-active ': index == activeIndex }">
          <h3 class="ts-p1-bold">{{ item.headline }}</h3>
          <RTE v-bind="{ data: item.copy }" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.content {
  color: var(--grey-3);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: units(2);
  padding-bottom: units(25);
}

.rich-text {
  color: inherit;
  padding-bottom: 0;
}

.numbers-list {
  position: relative;
  z-index: 9;

  .number {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;
    padding-left: units(3);
    gap: units(1.4);

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--active-color);
      transition: all 0.4s ease-out;
      position: absolute;
      margin-right: units(1);
      display: none;
    }
  }

  &__item {
    position: relative;
    padding: units(1.8) 0;
    transition: all 0.3s ease-out;
    cursor: pointer;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--grey-1);
      position: absolute;
      top: 0;
      left: 0;
    }

    &:last-child {
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: var(--grey-1);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &.is-active {
      .number {
        &:before {
          display: block;
        }
      }

      color: var(--active-color);

      &:before {
        background: currentColor;
      }
    }
  }
}

.solutions {
  position: relative;
  padding: units(3) 0;
  transition: color 0.3s ease-out;
  position: relative;
  z-index: 9;

  &__label {
    margin-bottom: units(2);
    transition: background 0.3s ease-out;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--active-color);
      display: inline-flex;
      margin-right: units(1);
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: var(--active-color);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.solutions-list {
  position: relative;

  &__item {
    position: absolute;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s ease-in-out;


    &.is-active {
      opacity: 1;
      transition-delay: .4s;
    }
  }

}

.graph {
  padding: 0 units(4) 0 units(4);

  .bubble {
    transform: translateY(-50%);
  }
}

.bubble {
  width: 100%;
  position: relative;

  &__outer-circle,
  &__inner {
    border-radius: 100%;
    background: currentColor;
    display: flex;
    justify-content: center;
    aspect-ratio: 1;
  }

  &__outer {
    position: relative;

    &-circle {
      position: relative;
      color: #ebe9e7;
      width: 100%;
      mix-blend-mode: overlay;
    }
  }

  &__inner {
    background: var(--active-color);
    width: var(--active-size);

    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    transition: width 400ms ease-out;
  }

  &__label {
    padding: units(4) units(2);
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;

    &--inner {
      color: var(--active-color);

      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        bottom: 0;
        background: currentColor;
        transform: translateX(units(-2));
        z-index: 1;
        width: calc(50% + units(2));
        rotate: 90deg;
        left: 50%;
        transform-origin: 0% 0%;
      }
    }

    &--outer {
      color: var(--grey-1);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        transform: translateX(calc(-100% + units(2)));
        background: var(--grey-1);
        z-index: -1;
        height: 1px;
        width: units(2);
        rotate: 90deg;
        left: 50%;
        transform-origin: 0% 0%;
      }
    }
  }

  &__ripple {
    position: absolute;
    inset: -250px;
    opacity: var(--ripple-opacity);
    transition: opacity 0.3s ease-in-out;
    z-index: -2;

    .ripple-shader {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
