<script setup>
const props = defineProps({
  headline: String,
  copy: String,
  call_to_action_download: Object,
  media: Object,
  show_job_count: Boolean,
  text_after_job_count: String,
});
const isVisible = ref(false);
const nuxtApp = useNuxtApp();
let finalHeadline = props.headline;
if (props.show_job_count) {
  const { getJobsCount } = useWpApi();
  const { data, error } = await getJobsCount(nuxtApp);
  const jobCount =
    data && data.value && data.value.meta
      ? data.value.meta["record-count"]
      : null;
  finalHeadline = jobCount + " " + props.text_after_job_count;
}

function runIntroAnimation(e) {
  e.state.isActive ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section billboard"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="billboard__background">
      <Media :media="media" :cover="true" :loop="true" v-view.progress />
    </div>

    <div class="billboard__inner">
      <h1 class="ts-h1 reveal headline">{{ finalHeadline }}</h1>
      <p class="text ts-p1 reveal">
        {{ copy }}
      </p>
      <div class="billboard__ctas reveal">
        <ButtonCTA
          v-if="call_to_action_download.file"
          class="cta"
          :has-icon="true"
          icon="arrowsimple"
          :is-download="true"
          :cta="call_to_action_download"
          type="secondary-outline"
        />
        <ButtonCTA
          v-else-if="
            call_to_action_download.label &&
            (call_to_action_download.url || call_to_action_download.page_link)
          "
          class="cta"
          :has-icon="true"
          icon="arrowsimple"
          :cta="call_to_action_download"
          type="secondary-outline"
        />
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.billboard {
  --parallax: 5rem;

  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (--md) {
    min-height: 780px;
  }

  @media (--lg) {
    min-height: 680px;
  }

  &__inner {
    background: var(--loud-indigo);
    padding: units(4) units(2);

    @media (--md) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      padding: units(6) units(8) units(6) units(6);
      border-radius: units(1) 0 0 0;
    }

    .headline {
    }

    .text {
      margin-bottom: units(6);
      transition-delay: 0.2s;
    }
  }

  &__ctas {
    transition-delay: 0.3s;
  }

  &__background {
    height: 450px;

    @media (--md) {
      height: 780px;
    }

    @media (--lg) {
      height: 680px;
    }

    background: grey;
    overflow: hidden;
    position: relative;

    .media {
      height: 120%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(calc(var(--view-progress) * var(--parallax)));
      transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
    }
  }

  h1 {
    &:has(+ p) {
      margin-bottom: units(3);
    }
  }

  .btn {
    margin-top: units(3);

    @media (--md) {
      margin-top: units(6);
    }
  }
}
</style>
