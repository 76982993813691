<script setup>
const props = defineProps({
  story_flip_card: [Object, Array],
});

const getActiveIndex = (index) => {
  // console.log(index);
};

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.1 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section class="story-flip-cards" :class="{ 'can-run': isVisible }" v-view="(e) => runIntroAnimation(e)">
    <div class="story-flip-cards__desktop page-margin" v-if="!$viewport.isLessThan('desktopView')">
      <ElementsFactFlipCard v-for="(card, index) in story_flip_card" :key="index" bgColor="var(--grey-0);" :data="card"
        :isStory="true" class="reveal" :style="`transition-delay:${index * .2}s`" />
    </div>
    <StandardCarousel v-else class="story-flip-cards__mobile" @active-index="getActiveIndex">
      <template v-slot:slide>
        <ElementsFactFlipCard v-for="(card, index) in story_flip_card" :key="index" bgColor="var(--grey-0);" :data="card"
          :isStory="true" class="standard-carousel__gallery-item reveal" :style="`transition-delay:${index * .2}s`" />
      </template>
    </StandardCarousel>
  </section>
</template>

<style lang="postcss" scoped>
.story-flip-cards {
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  &__desktop {

    display: grid;
    gap: units(2);
    grid-template-columns: repeat(3, 1fr);
  }

  &__mobile {
    position: relative;
    overflow: hidden;
    width: 100vw;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - var(--page-margin) * 2);
      height: 1px;
      background-color: var(--white);
      opacity: 0.4;
      z-index: 1;
      top: units(-2);
      left: 50%;
      transform: translateX(-50%);
    }

    &:deep(.carousel-controls) {
      color: var(--loud-indigo);
    }

    .standard-carousel__gallery-item {
      padding: 0;
      background-color: transparent;
      /* overflow: unset; */
      min-width: 75vw;


    }
  }

  &:deep(.flip-card-back) {
    padding: units(3) units(3) units(5) units(3);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: units(2);

    & .title {
      color: var(--loud-indigo);
    }
  }

  .icon {
    width: units(2);
    height: units(2);
  }

  .cta-link {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: units(2);
    color: var(--white);

    &:after {
      content: "";
      position: absolute;
      bottom: units(-1);
      width: 100%;
      height: 1px;
      background: var(--white);
      transition: width 0.3s;
    }
  }
}
</style>
