<script setup>
const props = defineProps({
  employee: [Array, Boolean],
  headline: String,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive ? (isVisible.value = true) : null;
}
const isOverlay = inject("is-overlay", null);
</script>

<template>
  <section
    class="section employee page-margin"
    :class="{
      'can-run': isVisible,
      'is-overlay': isOverlay,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="title ts-h2 reveal">{{ headline }}</div>
    <ElementsEmployeeCard
      :employee="employee[0]"
      :mobile-card="false"
      class="reveal"
    />
  </section>
</template>

<style lang="postcss" scoped>
.employee {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  &.is-overlay {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: grid;
  /* gap: units(6); */
  padding-top: units(3);
  padding-bottom: units(3);
  @media (--md) {
    padding-top: units(9);
    padding-bottom: units(9);
  }
}
</style>
