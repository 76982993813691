<script setup>
// import { h } from 'vue'
const props = defineProps({
  items: [Object, Array],
  isSubPage: [Boolean, null],
});
const heroItems = computed(() =>
  props.items?.length > 1 ? props.items : null
);

const heroItem = computed(() =>
  props.items?.length === 1 ? props.items[0] : null
);

// const headingTag = computed(()=>{
//   if (heroItem.value && props.isSubPage) return h('div', {class: 'ts-h2'}, heroItem.value?.headline)
//   return h('h1', { class: 'ts-h1' }, heroItem.value?.headline)
// })
const headingTag = computed(() => {
  if (heroItem.value && props.isSubPage) return "div";
  return "h1";
});
const headingClass = computed(() => {
  if (heroItem.value && props.isSubPage) return "ts-h2";
  return "ts-h1";
});

const target = ref(null);
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}

onBeforeUnmount(() => {
  isVisible.value = false;
});
</script>

<template>
  <section
    class="hero"
    :class="{ '-subpage': isSubPage, 'can-run': isVisible }"
    ref="target"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="hero__inner" v-if="heroItems">
      <ElementsProgressSlider :slides="items" />
    </div>
    <div class="hero__inner" v-if="heroItem">
      <div
        class="hero__background"
        v-if="heroItem?.media.image || heroItem?.media.video.mp4_video"
      >
        <Media
          :media="heroItem?.media"
          :cover="true"
          :loop="true"
          v-view.progress
          :poster-visible="false"
          :lazyImage="false"
        />
      </div>
      <div class="hero__content" :class="{ 'text-content': !heroItem?.svg }">
        <div v-if="heroItem?.svg" class="hero__svg">
          <SvgWSFA
            class="media-logo"
            aria-labelledby="hero-title"
            :is-reveal="true"
            :reveal-delay="0.1"
          />
          <h1 id="hero-title" class="visually-hidden" aria-hidden="true">
            Wonderful sound for all
          </h1>
        </div>
        <component
          :is="headingTag"
          v-else
          class="hero__heading reveal"
          :class="headingClass"
          >{{ heroItem.headline }}
        </component>

        <p class="ts-p1 hero__copy reveal" v-html="heroItem?.copy" />
        <ButtonCTA
          v-if="
            heroItem?.call_to_action_download.label &&
            heroItem?.call_to_action_download.file !== null
          "
          class="cta reveal"
          :has-icon="true"
          icon="arrowsimple"
          :is-download="true"
          :cta="heroItem?.call_to_action_download"
          type="primary-outline"
        />
        <ButtonCTA
          v-if="
            heroItem?.call_to_action_download.label &&
            (heroItem?.call_to_action_download.url ||
              heroItem?.call_to_action_download.page_link)
          "
          class="cta reveal"
          :has-icon="true"
          icon="arrowsimple"
          :cta="heroItem?.call_to_action_download"
          type="primary-outline"
        />
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.hero {
  --parallax: 5rem;

  --translate-title: units(1.5);
  --opacity-title: 0;
  position: relative;
  width: 100%;
  height: 90vh;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  @media (--md) {
    min-height: 780px;
    overflow: hidden;
    max-height: 70vh;
    aspect-ratio: 16 / 10;
  }

  @media (--max) {
    min-height: 680px;
  }

  &.-subpage {
    width: 100%;
    height: 50vh;

    @media (--md) {
      min-height: 380px;
      overflow: hidden;
      max-height: 15vh;
      aspect-ratio: 16 / 3;
    }

    @media (--max) {
      min-height: 680px;
    }

    .hero__content {
      @media (--md) {
        transform: translate(0, 0%);
      }
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    background: black;
  }

  &__heading {
    /* padding-inline: 10vw; */
  }

  &__background {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .media {
      height: 120%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(calc(var(--view-progress) * var(--parallax)));
      transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
    }

    /* @media (--md) {
      min-height: 780px;
    }

    @media (--lg) {
      min-height: 680px;
    } */
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: units(3);
    position: absolute;
    left: var(--page-margin);
    bottom: 20%;
    color: var(--white);
    z-index: 2;

    @media (--until-lg) {
      max-width: 92%;
    }

    @media (--md) {
      top: 50%;
      left: 50%;
      transform: translate(0, -40%);
      padding-right: units(20);
    }

    .text-content {
      padding: var(--grid-margin);
    }

    .media-logo {
      --svg-color: var(--loud-indigo);
      flex-shrink: 0;
    }
  }
}
</style>
