import { default as _91_46_46_46slugs_93yPHuplA9ioMeta } from "/app/pages/[...slugs].vue?macro=true";
import { default as _91slug_93sVvQmjEuwuMeta } from "/app/pages/about/[slug].vue?macro=true";
import { default as indexTRqRpIOxLQMeta } from "/app/pages/about/index.vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91slug_930HO97GfwrDMeta } from "/app/pages/article/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93QeTB66CTgaMeta } from "/app/pages/investors/[slug].vue?macro=true";
import { default as index1fFEWg5qPQMeta } from "/app/pages/investors/index.vue?macro=true";
import { default as investorsBFHRwqs7ZcMeta } from "/app/pages/investors.vue?macro=true";
import { default as _91slug_93GP8jY736bZMeta } from "/app/pages/media/[slug].vue?macro=true";
import { default as indexzoHcGDx1NlMeta } from "/app/pages/media/index.vue?macro=true";
import { default as mediavtEUkqAnkiMeta } from "/app/pages/media.vue?macro=true";
import { default as _91slug_93TYt7JhJaRHMeta } from "/app/pages/press-release/[slug].vue?macro=true";
import { default as styleguidea5Wc9pLTy6Meta } from "/app/pages/styleguide.vue?macro=true";
export default [
  {
    name: _91_46_46_46slugs_93yPHuplA9ioMeta?.name ?? "slugs",
    path: _91_46_46_46slugs_93yPHuplA9ioMeta?.path ?? "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93yPHuplA9ioMeta || {},
    alias: _91_46_46_46slugs_93yPHuplA9ioMeta?.alias || [],
    redirect: _91_46_46_46slugs_93yPHuplA9ioMeta?.redirect || undefined,
    component: () => import("/app/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    children: [
  {
    name: _91slug_93sVvQmjEuwuMeta?.name ?? "about-slug",
    path: _91slug_93sVvQmjEuwuMeta?.path ?? ":slug()",
    meta: _91slug_93sVvQmjEuwuMeta || {},
    alias: _91slug_93sVvQmjEuwuMeta?.alias || [],
    redirect: _91slug_93sVvQmjEuwuMeta?.redirect || undefined,
    component: () => import("/app/pages/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTRqRpIOxLQMeta?.name ?? "about",
    path: indexTRqRpIOxLQMeta?.path ?? "",
    meta: indexTRqRpIOxLQMeta || {},
    alias: indexTRqRpIOxLQMeta?.alias || [],
    redirect: indexTRqRpIOxLQMeta?.redirect || undefined,
    component: () => import("/app/pages/about/index.vue").then(m => m.default || m)
  }
],
    name: aboutu8Sb32ihwPMeta?.name ?? undefined,
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930HO97GfwrDMeta?.name ?? "article-slug",
    path: _91slug_930HO97GfwrDMeta?.path ?? "/article/:slug()",
    meta: _91slug_930HO97GfwrDMeta || {},
    alias: _91slug_930HO97GfwrDMeta?.alias || [],
    redirect: _91slug_930HO97GfwrDMeta?.redirect || undefined,
    component: () => import("/app/pages/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    path: investorsBFHRwqs7ZcMeta?.path ?? "/investors",
    children: [
  {
    name: _91slug_93QeTB66CTgaMeta?.name ?? "investors-slug",
    path: _91slug_93QeTB66CTgaMeta?.path ?? ":slug()",
    meta: _91slug_93QeTB66CTgaMeta || {},
    alias: _91slug_93QeTB66CTgaMeta?.alias || [],
    redirect: _91slug_93QeTB66CTgaMeta?.redirect || undefined,
    component: () => import("/app/pages/investors/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1fFEWg5qPQMeta?.name ?? "investors",
    path: index1fFEWg5qPQMeta?.path ?? "",
    meta: index1fFEWg5qPQMeta || {},
    alias: index1fFEWg5qPQMeta?.alias || [],
    redirect: index1fFEWg5qPQMeta?.redirect || undefined,
    component: () => import("/app/pages/investors/index.vue").then(m => m.default || m)
  }
],
    name: investorsBFHRwqs7ZcMeta?.name ?? undefined,
    meta: investorsBFHRwqs7ZcMeta || {},
    alias: investorsBFHRwqs7ZcMeta?.alias || [],
    redirect: investorsBFHRwqs7ZcMeta?.redirect || undefined,
    component: () => import("/app/pages/investors.vue").then(m => m.default || m)
  },
  {
    path: mediavtEUkqAnkiMeta?.path ?? "/media",
    children: [
  {
    name: _91slug_93GP8jY736bZMeta?.name ?? "media-slug",
    path: _91slug_93GP8jY736bZMeta?.path ?? ":slug()",
    meta: _91slug_93GP8jY736bZMeta || {},
    alias: _91slug_93GP8jY736bZMeta?.alias || [],
    redirect: _91slug_93GP8jY736bZMeta?.redirect || undefined,
    component: () => import("/app/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzoHcGDx1NlMeta?.name ?? "media",
    path: indexzoHcGDx1NlMeta?.path ?? "",
    meta: indexzoHcGDx1NlMeta || {},
    alias: indexzoHcGDx1NlMeta?.alias || [],
    redirect: indexzoHcGDx1NlMeta?.redirect || undefined,
    component: () => import("/app/pages/media/index.vue").then(m => m.default || m)
  }
],
    name: mediavtEUkqAnkiMeta?.name ?? undefined,
    meta: mediavtEUkqAnkiMeta || {},
    alias: mediavtEUkqAnkiMeta?.alias || [],
    redirect: mediavtEUkqAnkiMeta?.redirect || undefined,
    component: () => import("/app/pages/media.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TYt7JhJaRHMeta?.name ?? "press-release-slug",
    path: _91slug_93TYt7JhJaRHMeta?.path ?? "/press-release/:slug()",
    meta: _91slug_93TYt7JhJaRHMeta || {},
    alias: _91slug_93TYt7JhJaRHMeta?.alias || [],
    redirect: _91slug_93TYt7JhJaRHMeta?.redirect || undefined,
    component: () => import("/app/pages/press-release/[slug].vue").then(m => m.default || m)
  },
  {
    name: styleguidea5Wc9pLTy6Meta?.name ?? "styleguide",
    path: styleguidea5Wc9pLTy6Meta?.path ?? "/styleguide",
    meta: styleguidea5Wc9pLTy6Meta || {},
    alias: styleguidea5Wc9pLTy6Meta?.alias || [],
    redirect: styleguidea5Wc9pLTy6Meta?.redirect || undefined,
    component: () => import("/app/pages/styleguide.vue").then(m => m.default || m)
  }
]