<script setup>
const props = defineProps({
  employees: [Array, Boolean],
  headline: String,
  heading_tag: [Object, Boolean, null],
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
const headingTag = computed(() =>
  props.heading_tag ? props.heading_tag.value : "h2"
);
</script>

<template>
  <section
    class="section management-list page-margin"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <component v-if="headline" :is="headingTag" class="title ts-h2">{{
      headline
    }}</component>
    <div class="management-list__grid">
      <ElementsEmployeeCard
        v-for="(employee, index) in employees"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
        :key="index"
        :employee="employee.copy ? employee : null"
        :mobile-card="true"
        :is-management="employee.copy ? true : false"
      />
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.management-list {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  .title {
    color: var(--grey-4);
  }
  padding-top: units(9);
  padding-bottom: units(3);
  display: grid;
  gap: units(6);
  &__grid {
    display: grid;
    gap: units(2);
    @media (--md) {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }
  }
}
</style>
