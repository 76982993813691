/**
 * Fetches global data from the server and returns specific properties.
 * @returns {Promise<Object>} An object containing specific properties from the fetched data.
 */
export default async (nuxtApp) => {
  const cacheExpiration = 60 * 60 * 1000 // 1 hour
  const config = useRuntimeConfig().public
  const ssApi = config.apiSs

  const { data, error } = await useFetch(`${ssApi}/globals`, {
    key: `${ssApi}/globals`,
    immediate: true,
    lazy: false,
    transform(data) {
      return {
        ...data,
        fetchedAt: new Date()
      }

    },
    getCachedData(key) {
      const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
      // If data is not fetched yet
      if (!data) {
        // Fetch the first time
        return
      }

      // Is the data too old?
      const expirationDate = new Date(data.fetchedAt)
      expirationDate.setTime(expirationDate.getTime() + cacheExpiration)
      const isExpired = expirationDate.getTime() < Date.now()
      if (isExpired) {
        // Refetch the data
        return
      }

      return data
    },
  })

  if (error.value) {
    throw createError({
      statusCode: 404,
      statusMessage: "Globals data not found"
    });
  }

  return {
    socials: data.value.social_links,
    content404: data.value.content404 || [{ headline: 'it might be gone' }],
    subnavrefs: data.value.sub_nav_refs,
    offices: data.value.offices,
    footer: data.value.footer,
    newsletter: data.value.newsletter,
  }
}
