<script setup>
</script>

<template>
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <path d="M11 21.6C5.1 21.6.4 16.9.4 11S5.1.4 11 .4 21.6 5.1 21.6 11 16.9 21.6 11 21.6zm0-20c-5.2 0-9.4 4.2-9.4 9.4s4.2 9.4 9.4 9.4 9.4-4.2 9.4-9.4-4.2-9.4-9.4-9.4z"/>
    <path d="m18.1 9.4-1.2 1.2c-.2-3.1-2.8-5.5-5.9-5.5-1.6 0-3.1.6-4.2 1.7l.9.9c.9-.9 2-1.4 3.3-1.4 2.5 0 4.5 2 4.7 4.5l-1.4-1.4-.9.9 2.4 2.4c.1.1.3.2.4.2.2 0 .3-.1.4-.2l2.4-2.4-.9-.9zM14.4 14.4c-.9.9-2.1 1.4-3.4 1.4-2.6 0-4.8-2.1-4.8-4.7l1.6 1.6.9-.9-2.4-2.4c-.1-.1-.3-.2-.5-.2s-.3.1-.4.2L3 11.7l.9.9 1.3-1.3c.2 3 2.7 5.5 5.8 5.5 1.6 0 3-.6 4.1-1.7l-.7-.7z"/>
  </svg>
</template>

<style lang="postcss" scoped>

</style>
