// composables/useEventBus.ts
import mitt from 'mitt'


const emitter = mitt()

/**
 * Emits an event using the event emitter.
 * @param {string} eventName - The name of the event to emit.
 * @param {...any} args - Arguments to pass to the event listeners.
 * Ex: useListen("overlay:open", () => {video.value?.pause();});
 * Ex: useEvent("overlay:open");
 */
export const useEvent = emitter.emit
export const useListen = emitter.on
export const useRemove = emitter.off
