<script setup>
const props = defineProps({
  acf_fc_layout: String,
  items: {
    type: [Array, Object],
  },
});

const activeIndex = ref(0);
const activeSlideBackground = ref(null);

const getActiveIndex = (index) => {
  activeIndex.value = index;
};
const setActiveIndex = (index) => {
  activeIndex.value = index;
};
const goPrevious = () => {
  if (activeIndex.value > 0) activeIndex.value -= 1;
};

const goNext = () => {
  if (activeIndex.value < props.items.length - 1) activeIndex.value += 1;
};

const updateActiveIndex = (index) => {
  // console.log("updateActiveIndex", index);
  activeIndex.value = index;
};
const updateLeftActiveIndex = (index) => {
  // console.log("updateLeftActiveIndex", index);
  activeIndex.value = index;
};
const updateRightActiveIndex = (index) => {
  // console.log("updateRightActiveIndex", index);
  activeIndex.value = index;
};
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}

const randomHeightPerItem = ref([]);
const setRandomHeightPerItem = () => {
  props.items.forEach((item, index) => {
    randomHeightPerItem.value[index] = Math.floor(
      Math.random() * (450 - 60 + 1) + 60
    );
  });
};
const randomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const middle = ref(null);
const middleHeight = ref(0)
const getMiddleHeight = () => {
  return middle.value.querySelector(".middle-active").offsetHeight;
};

onMounted(() => {
  setRandomHeightPerItem();
  middleHeight.value = getMiddleHeight.value;
});

const { width } = useScreen();

watch(width, () => {
  if (!process.client) return;
  setRandomHeightPerItem();
  middleHeight.value = getMiddleHeight();
});
</script>

<template>
  <section class="section soundbar-carousel" :class="{
    'can-run': isVisible,
  }" v-view="(e) => runIntroAnimation(e)">
    <div class="soundbar-carousel-wrapper reveal">
      <div class="soundbar-carousel-wrapper__nav page-margin" v-if="items.length > 1">
        <elements-carousel-controls class="soundbar-carousel-wrapper__nav-controls" @go-previous="goPrevious"
          @go-next="goNext" />
      </div>
      <div class="soundbar-carousel-wrapper__slides">
        <div class="left">
          <ElementsSideSoundbarCarousel :key="'left'" :slides="items" :active-index="activeIndex" side="left"
            @update-left-activeIndex="updateLeftActiveIndex" :max-height="middleHeight" />
        </div>
        <div class="middle" ref="middle">
          <ElementsMiddleSoundbarCarousel :slides="items" :active-index="activeIndex" :acf_fc_layout="acf_fc_layout"
            @update-activeIndex="updateActiveIndex" />
        </div>
        <div class="right">
          <ElementsSideSoundbarCarousel :key="'right'" side="right" :slides="items" :active-index="activeIndex"
            @update-right-activeIndex="updateRightActiveIndex" :max-height="middleHeight" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.soundbar-carousel {
  padding-top: units(6);
  padding-bottom: units(9);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - var(--page-margin) * 2);
    height: 1px;
    background-color: var(--grey-1);
  }

  &-wrapper {
    &__nav {
      position: absolute;
      right: 0;
      top: units(2);
      display: flex;

      &-controls {
        margin-left: auto;
      }
    }

    &__slides {
      display: grid;
      grid-template-columns: 15% 70% 15%;
      gap: units(1);
      grid-template-areas: "left active right";

      & .left {
        grid-area: left;
        justify-content: flex-end;
        height: 100%;
        display: flex;
        overflow: hidden;
      }

      & .right {
        grid-area: right;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-right: auto;
      }

      & .middle {
        display: contents;
      }
    }
  }
}
</style>
