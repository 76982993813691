<script setup>
</script>

<template>


  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.58333 7.91667C6.42428 7.91667 7.91667 6.42428 7.91667 4.58333C7.91667 2.74238 6.42428 1.25 4.58333 1.25C2.74238 1.25 1.25 2.74238 1.25 4.58333C1.25 6.42428 2.74238 7.91667 4.58333 7.91667Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.75 8.75L6.9375 6.9375" stroke="currentColor" stroke-width="1.25" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>


</template>

<style lang="postcss" scoped>

</style>

