<script setup>
const props = defineProps({
  small_fact_flip_card: [Object, Array],
  big_fact_flip_card: [Object, Array],
  trumpet: String,
  headline: String,
  has_background_color: Boolean,

});

const getActiveIndex = (index) => {
  // console.log(index);
};

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.1 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section class="flipping-cards" :class="{ 'has-bg': has_background_color, 'can-run': isVisible }"
    v-view="(e) => runIntroAnimation(e)">
    <ElementsIntro v-bind="{ trumpet, headline }" class="flipping-cards__intro reveal" />
    <div v-if="!$viewport.isLessThan('desktopView')" class="flipping-cards__columns">
      <div class="left">
        <ElementsFactFlipCard class="flipping-cards__columns-card reveal"
          v-for="(card, index) in small_fact_flip_card?.slice(0, 2)" :key="index" :style="`order:${index}`" :class="{
            'flipping-cards__columns-card-0': index === 0,
            'flipping-cards__columns-card-1': index === 1,
          }" bgColor="var(--grey-0);" :data="card" />
      </div>
      <div class="middle">
        <ElementsFactFlipCard class="flipping-cards__columns-card reveal" v-for="(card, index) in big_fact_flip_card"
          :key="index" :class="`flipping-cards__columns-card-3`" :style="`order:3`" bgColor="var(--grey-0);"
          :is-flippable="index === 0 ? true : false" :data="card">
        </ElementsFactFlipCard>
      </div>
      <div class="right">
        <ElementsFactFlipCard class="flipping-cards__columns-card reveal"
          v-for="(card, index) in small_fact_flip_card?.slice(2, 4)" :key="index" :style="`order:${index}`" :class="{
            'flipping-cards__columns-card-3': index === 0,
            'flipping-cards__columns-card-4': index === 1,
          }" bgColor="var(--grey-0);" :data="card">
        </ElementsFactFlipCard>
      </div>
    </div>
    <StandardCarousel v-else class="flipping-cards__slider" @active-index="getActiveIndex">
      <template v-slot:slide>
        <ElementsFactFlipCard :data="card" class="flipping-cards__columns-card standard-carousel__gallery-item reveal"
          v-for="(card, index) in small_fact_flip_card?.slice(0, 2)" :key="index" :style="`order:${index}`"
          bgColor="var(--grey-0);" :class="{
            'flipping-cards__columns-card-0': index === 0,
            'flipping-cards__columns-card-1': index === 1,
          }" :can-wiggle="index === 0 ? true : false" :is-flippable="index === 0 ? true : false">
        </ElementsFactFlipCard>
        <ElementsFactFlipCard :data="card" class="flipping-cards__columns-card standard-carousel__gallery-item reveal"
          v-for="(card, index) in big_fact_flip_card" :key="index" :class="`flipping-cards__columns-card-3`"
          :style="`order:3`" bgColor="var(--grey-0);">

        </ElementsFactFlipCard>
        <ElementsFactFlipCard :data="card" class="flipping-cards__columns-card standard-carousel__gallery-item reveal"
          v-for="(card, index) in small_fact_flip_card?.slice(2, 4)" :key="index" :style="`order:${4 + index}`"
          bgColor="var(--grey-0);" :class="{
            'flipping-cards__columns-card-3': index === 0,
            'flipping-cards__columns-card-4': index === 1,
          }">

        </ElementsFactFlipCard>
      </template>
    </StandardCarousel>
  </section>
</template>

<style lang="postcss" scoped>
.flipping-cards {
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  @media (--md) {
    padding-bottom: units(9);
  }

  display: flex;
  flex-direction: column;

  /* gap: units(6); */
  &.has-bg {
    background-color: var(--loud-indigo);
  }

  &__intro {
    color: var(--white);

    &:deep(.intro__count) {
      color: var(--white);

      &:after {
        background: var(--white);
      }
    }

    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
  }

  &__columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: units(2);
    position: relative;

    @media (--md) {
      height: fit-content;
      margin-left: var(--page-margin);
      margin-right: var(--page-margin);
    }

    .left,
    .right {
      display: grid;
      gap: units(2);
    }

    .middle {
      .flip-card {
        height: 75%;
      }
    }



    .rich-text {
      padding-bottom: 0;
    }



    &-card-0 {
      transition-delay: 0.2s;
    }

    &-card-1 {
      transition-delay: 0.4s;
    }

    &-card-2 {
      transition-delay: 0.6s;
    }

    &-card-3 {
      transition-delay: 0.8s;
    }

    &-card-4 {
      transition-delay: 1s;
    }

  }





  &__slider {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - var(--page-margin) * 2);
      height: 1px;
      background-color: var(--white);
      opacity: 0.4;
      z-index: 1;
      top: units(-2);
      left: 50%;
      transform: translateX(-50%);
    }

    &:deep(.carousel-controls) {
      color: var(--white);
    }

    .standard-carousel__gallery-item {
      padding: 0;
      background-color: transparent;
      /* overflow: unset; */
      min-width: 75vw;


    }

    .flipping-cards {
      width: 100%;
      height: 100%;
    }

    .flip-card {
      height: 100%;
    }
  }

  .icon {
    width: units(2);
    height: units(2);
    z-index: 3;
  }

}
</style>
