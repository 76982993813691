<script setup>
// TO OPEN OVERLAY FROM ANOTHER COMPONENT:
// const openOverlay = async (path) => {
//   const { getArticle } = useWpApi();
//   const { data, error } = await getArticle(path);
//   useEvent("overlay:open", {
//     data: data.value,
//      type:'article',
//   });
// };
// AND IF BUTTON CLICK:
// @click="openOverlay(article.relative_link)"

provide("is-overlay", true);
const isOpen = ref(false);
const overlayData = ref(null);
const overlayType = ref("");
const close = () => {
  isOpen.value = false;
  setTimeout(() => {
    useEvent("overlay:close");
  }, 400);
};

useListen("overlay:open", (overlay) => {
  console.log("overlay:open: ", overlay);
  overlayData.value = overlay.data;
  overlayType.value = overlay.type;
  isOpen.value = true;
});
useListen("overlay:close", () => {
  isOpen.value = false;
});

watch(isOpen, (open) => {
  if (open) {
    isOpen.value = true;
    overlay.value.style.visibility = "visible";
    scrollLock(true, document.querySelector("#__nuxt"));
    overlay.value.scrollTop = 0;
  } else {
    scrollLock(false, document.querySelector("#__nuxt"));
    setTimeout(() => {
      overlay.value.style.visibility = "hidden";
    }, 400);
  }
});

const overlay = ref(null);
const { height } = useScreen();
const showNav = ref(false);
const { y, arrivedState, directions } = useScroll(
  process.client ? overlay : null,
  {
    throttle: 30,
  }
);
const articleOverlay = ref(null);
const update = () => {
  if (!process.client) return;
  const titleElement = articleOverlay.value?.querySelector(".title");
  const titleElementRect = titleElement?.getBoundingClientRect();
  const navbar = overlay.value?.querySelector(".navbar");
  const navbarRect = navbar?.getBoundingClientRect();

  if (titleElementRect?.bottom > navbarRect?.bottom) {
    showNav.value = false;
  } else {
    showNav.value = true;
  }
};

watch([y, height], update, {
  immediate: true,
});

const escFunction = (event) => {
  if (event.keyCode === 27) {
    close();
  }
};
onMounted(() => {
  document.addEventListener("keydown", escFunction, false);
  showNav.value = false;
});
</script>
<template>
  <Teleport to="body">
    <ClientOnly>
      <noscript
        ><iframe
          src="https://sst.wsa.com/ns.html?id=GTM-MRJZSZF7"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe
      ></noscript>
    </ClientOnly>
    <div
      ref="overlay"
      class="overlay"
      :class="{
        'is-open': isOpen,
        'is-article': overlayType === 'article',
        'is-newsletter': overlayType === 'newsletter',
        'is-place': overlayType === 'place',
      }"
      role="dialog"
      aria-labelledby="overlayTitle"
      aria-describedby="overlayDescription"
    >
      <div
        class="article-overlay"
        v-if="overlayType === 'article'"
        ref="articleOverlay"
      >
        <GlobalHeaderOverlay
          :title="overlayData?.title?.rendered"
          class="navbar"
          :class="{ show: showNav }"
          ref="navbar"
        />
        <ElementsArticlePage :data="overlayData" class="article" />
      </div>
      <div class="newsletter-overlay" v-if="overlayType === 'newsletter'">
        <GlobalHeaderOverlay class="newsletter-overlay__close" />
        <Newsletter class="newsletter" :is-overlay="true" />
      </div>
      <div class="place-overlay" v-if="overlayType === 'place'">
        <GlobalHeaderOverlay
          class="place-overlay__close"
          :style="`--svg-color: ${
            overlayData.image ? 'var(--white)' : 'var(--grey-4)'
          }`"
        />
        <ElementsPlaceCard
          :place="overlayData"
          class="place-card"
          :id="overlayData.id.toLowerCase()"
        />
      </div>
    </div>
    <div
      class="backdrop"
      :class="{ 'is-open': isOpen }"
      @click="close"
      :data-state="isOpen ? 'open' : 'closed'"
    ></div>
  </Teleport>
</template>

<style lang="postcss">
.overlay {
  --header-height: units(6);
  --transform-slide: 100%;
  --overlay-width: 100vw;

  @media (--md) {
    --overlay-width: 80vw;
  }

  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 102;
  pointer-events: none;
  overflow-y: auto;
  width: var(--overlay-width);
  height: 100dvh;
  background-color: var(--white);
  transform: translateY(var(--transform-slide));
  visibility: hidden;

  @media (--md) {
    top: 0;
    width: var(--overlay-width);
    height: 100vh;
  }

  transition: transform 0.4s cubic-bezier(0.45, 0, 0.55, 1);

  &.is-article {
  }

  &.is-newsletter {
    background-color: var(--deep-indigo);

    @media (--md) {
      --overlay-width: 50vw;
    }
  }

  &.is-place {
    height: fit-content;
    border-radius: units(1) units(1) 0 0;
  }

  &.is-open {
    --transform-slide: 0%;
    pointer-events: auto;
  }

  .icon {
    width: units(2);
    height: units(2);
  }

  .article-overlay {
    .navbar {
      width: var(--overlay-width);
      height: var(--header-height);

      & .header-overlay__title {
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      }

      &.show {
        .header-overlay__title {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    .article {
      margin-top: calc(-1 * var(--header-height));
    }
  }

  .newsletter-overlay {
    height: 100%;

    &__close {
      position: absolute;
      top: units(3);
      z-index: 1;
      width: 100%;
      height: units(2);
      background-color: transparent;
    }

    & .newsletter {
      height: 100%;
    }
  }

  .place-overlay {
    /* height: 100%; */
    &__close {
      position: absolute;
      top: units(2);
      z-index: 1;
      width: 100%;
      height: units(2);
      background-color: transparent;
      --svg-color: var(--grey-4);
    }
  }
}

.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;

  --end-blur: 2px;
  --duration: 0.4s;
  --easing: ease-in-out;
  --overlay-bg: rgba(0, 0, 0, 0);
  inset: 0;
  pointer-events: none;

  &.is-open {
    pointer-events: all;
  }
}

@property --overlay-blur {
  syntax: "<length>";
  inherits: false;
  initial-value: 0px;
}

@supports not (-moz-appearance: none) {
  .backdrop {
    transition: var(--duration) --overlay-blur var(--easing),
      var(--duration) background-color var(--easing);
    -webkit-backdrop-filter: blur(var(--overlay-blur));
    backdrop-filter: blur(var(--overlay-blur));
    background-color: var(--overlay-bg);
  }

  .backdrop[data-state="open"] {
    --overlay-blur: var(--end-blur);
    --overlay-bg: rgba(0, 0, 0, 0.2);
  }

  .backdrop[data-state="closed"] {
    --overlay-blur: 0px;
    --overlay-bg: rgba(0, 0, 0, 0);
  }
}
</style>
