const { y, directions } = useScroll(process.client ? window : null, { throttle: 30 })
const { width, height } = useWindowSize()

/**
 * Returns an object with screen-related properties from VueUse.
 * @returns {Object} The screen object.
 * @property {number} width - The width of the screen.
 * @property {number} height - The height of the screen.
 * @property {number} currentScroll - The current scroll position of the screen.
 * @property {Array} directions - The available scroll directions.
 */
export default () => {
    return {
        width,
        height,
        currentScroll: computed(() => y.value),
        directions,
    }
}
