<script setup>
const props = defineProps({
    data: [Object, Array],
    isFlippable: { type: Boolean, default: false },
    bgColor: { type: String, default: "white" },
    isStory: { type: Boolean, default: false },
});
const isFlipped = ref(false);
const front_btn = ref(null);
const back_btn = ref(null);

const handleClick = () => {
    isFlipped.value = !isFlipped.value;
};

onMounted(() => {
    front_btn.value.style.visibility = "visible";
    back_btn.value.style.visibility = "visible";
});

const isVisible = ref(false);

function runIntroAnimation(e) {
    if (e.state.isActive && e.state.progress > 0.2) {
        isVisible.value = true;
        if (props.isFlippable) {
            setTimeout(() => {
                isFlipped.value = true;
            }, 1000);
        }
    }
}
</script>
<template>
    <div class="flip-card" :style="`--card-bg-color:${bgColor}`"
        :class="{ 'can-run': isVisible, 'has-background': data.card_front.media?.image || data.card_front.media?.mp4_video, 'is-story': isStory }"
        v-view="(e) => runIntroAnimation(e)">
        <div class="flip-card__inner" :class="{ 'do-flip': isFlipped }">
            <div class="flip-card__inner-front" @click="handleClick">
                <div class="content-front">
                    <div class="title" :class="` ${isStory
                        ? 'ts-h4'
                        : 'ts-numbers'
                        }`">
                        {{ data.card_front.title }}
                    </div>

                    <RTE v-if="data.card_front.copy" :data="data.card_front.copy" class="ts-p1 rich-text" />
                </div>
                <div class="icon" v-if="data.card_front.icon">
                    <Media :media="{ image: data.card_front.icon }" />
                </div>
                <div class="background" v-if="data.card_front.media?.image || data.card_front.media?.mp4_video">
                    <div class="gradient"></div>
                    <Media :media="data.card_front.media" />
                </div>
                <div ref="front_btn" class="flip-card__btn-front">
                    <SvgFlipIcon />
                </div>
            </div>
            <div class="flip-card__inner-back">
                <div class="content-back">
                    <div class="text" @click="handleClick">
                        <div class="ts-h4 back-title">{{ data.card_back.title }}</div>
                        <RTE :data="data.card_back.copy" class="ts-p1 rich-text" />
                    </div>
                    <div class="ctas">
                        <div class="cta" v-for="(cta, index) in data.card_back.call_to_actions" :key="index">
                            <CustomLink :data="cta.call_to_action">
                                <template v-slot:label>
                                    <div class="cta-link">
                                        <span class="ts-cta">
                                            {{ cta.call_to_action.label }}
                                        </span>
                                        <SvgArrowSimple class="icon" />
                                    </div>
                                </template>
                            </CustomLink>
                        </div>
                    </div>
                </div>
                <div ref="back_btn" class="flip-card__btn-back" @click="handleClick">
                    <SvgFlipIcon />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.flip-card {
    --title-color: var(--loud-indigo);
    --copy-color: var(--grey-4);
    --icon-color: var(--loud-indigo);
    /*need to find a way to overwrite the slider overflow*/
    perspective: unest;

    @media (--md) {
        perspective: 1000px;
    }

    min-width: fit-content;
    min-height: units(32);
    width: 100%;
    position: relative;

    &.has-background {
        --title-color: var(--white);
        --copy-color: var(--white);
        --icon-color: var(--white);
    }

    &.is-story {
        &:deep(.flip-card__inner-front) {
            .icon {
                width: units(3.5);
                height: units(3.5);
            }
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 0.5s ease-in-out;
        transform-style: preserve-3d;
        pointer-events: none;
    }

    .do-flip {
        transform: rotateY(-180deg);
    }

    &__btn-front,
    &__btn-back {
        cursor: pointer;
        pointer-events: all;
        position: absolute;
        top: units(3);
        right: units(3);
        visibility: hidden;
        fill: var(--icon-color);
        z-index: 3;
        line-height: 0;
        transition: rotate 0.35s ease-in-out;

        &:hover {
            rotate: 180deg;
        }
    }

    &__btn-back {
        fill: var(--white);
    }

    &__inner-front,
    &__inner-back {
        background-color: var(--card-bg-color);
        border-radius: units(1);
        overflow: hidden;
        padding: units(3);
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;
    }

    &__inner-front {
        cursor: pointer;
        pointer-events: all;
    }

    &__inner-back {
        transform: rotateY(180deg);
        background-color: var(--deep-indigo);
        color: var(--white);
    }


    .content-front {
        color: var(--copy-color);
    }

    .content-front,
    .content-back {
        display: flex;
        flex-direction: column;
        gap: units(2);
        z-index: 3;

        & .title {
            color: var(--title-color);
        }

        &:deep(.icon) {
            fill: var(--icon-color);
        }
    }

    &-card-0 {
        transition-delay: 0.2s;
    }

    &-card-1 {
        transition-delay: 0.4s;
    }

    &-card-2 {
        transition-delay: 0.6s;
    }

    &-card-3 {
        transition-delay: 0.8s;
    }

    &-card-4 {
        transition-delay: 1s;
    }

    .icon {
        width: units(2);
        height: units(2);
        color: var(--white);
    }


    .content-back {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .text {
            display: flex;
            flex-direction: column;
            gap: units(2);
            cursor: pointer;
            pointer-events: all;
        }

        .back-title {
            padding-right: units(2);
        }

        .ctas {
            display: grid;
            gap: units(2);
            pointer-events: all;
        }
    }

    .cta-link {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: units(2);
        color: var(--white);
        pointer-events: all;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            bottom: units(-1);
            width: 100%;
            height: 1px;
            background: var(--white);
            transition: width 0.3s;
        }
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.4) 35%,
                    rgba(0, 0, 0, 0) 75%);
        }

        &:deep(.image) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
}
</style>
