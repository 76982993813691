<script setup>
const props = defineProps({
  links: Array,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin link-list"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <CustomLink
      :to="link.url"
      class="link-list__item reveal"
      v-for="(link, index) in links"
      :style="`transition-delay: ${index * 0.1}s`"
    >
      <template v-slot:label>
        <div class="link-list__content">
          <span v-if="link.label" class="ts-h4">{{ link.label }}</span>
        </div>
        <div class="icon">
          <SvgArrow />
        </div>
      </template>
    </CustomLink>
  </section>
</template>

<style lang="postcss" scoped>
.link-list {
  --rotate-icon: -45deg;
  --txt-color: var(--grey-4);
  --line-color: var(--grey-1);

  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  display: grid;
  padding-bottom: units(3);
  @media (--md) {
    column-gap: units(2);
    grid-template-columns: repeat(3, 1fr);
  }
  &__item {
    /* border: solid 1px red; */
    color: var(--txt-color);
    position: relative;
    padding-bottom: units(2);
    padding-top: units(2);
    /* transition: color 0.3s ease-in-out; */

    &:deep(.link) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: units(2);
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background: var(--line-color);
      transition: background 0.3s ease-in-out;
    }

    &:nth-child(3n + 1):nth-last-child(-n + 3),
    &:nth-child(3n + 1):nth-last-child(-n + 3) ~ & {
      @media (--md) {
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0;
          background: var(--grey-1);
        }
      }
    }

    &:hover {
      --rotate-icon: 0deg;
      --txt-color: var(--loud-indigo);
      --line-color: var(--loud-indigo);
    }

    .icon {
      width: units(2);
      height: units(2);
      transform: rotate(var(--rotate-icon));
      color: var(--loud-indigo);
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
