<script setup>
</script>

<template>
  <svg viewBox="0 0 21.2 21.2" width="100%" height="100%" style="fill:currentColor">
    <path d="m10.6,21.2C4.7,21.2,0,16.5,0,10.6S4.7,0,10.6,0s10.6,4.7,10.6,10.6-4.7,10.6-10.6,10.6Zm0-20C5.4,1.2,1.2,5.4,1.2,10.6s4.2,9.4,9.4,9.4,9.4-4.2,9.4-9.4S15.8,1.2,10.6,1.2Z"/>
    <path d="m4.2,10h12.8v1.2H4.2v-1.2Z"/>
    <path d="m12,16.5l-.9-.9,5-5-5-5,.9-.9,5.4,5.4c.2.2.2.6,0,.9l-5.4,5.5Z"/>
</svg>
</template>

<style lang="postcss" scoped>

</style>
  