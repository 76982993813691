<script setup>
import gsap from "gsap";
const nuxtApp = useNuxtApp();
const { option } = await useOptions("historical-events", nuxtApp);

const props = defineProps({
  trumpet: String,
  headline: String,
});
const allMedias = ref([]);
const events = option.years.flatMap((yearData, yearIndex) => {
  return yearData.events.map((event, eventIndex) => {
    return {
      year: parseInt(yearData.year),
      headline: event.headline,
      copy: event.copy,
      media: event.image,
    };
  });
});
events.forEach((event, index) => {
  event.id = index;
  allMedias.value.push(event.media);
});
const timelineContainer = ref(null);
const currentYear = new Date().getFullYear();
const oldestYear = Math.min(...events.map((event) => event.year));
const years = ref(
  Array.from({ length: currentYear - oldestYear + 1 }, (_, i) => oldestYear + i)
);
const lastEvent = ref(null);
const currentEvent = ref(null);
const timeline = ref(null);

const yearContainer = ref(null);
const mediaElement = ref(null);

const goNextEvent = () => {
  if (!process.client) return;
  const currentEventIndex = events?.findIndex(
    (event) => event.id === currentEvent.value?.id
  );
  const nextEventIndex = (currentEventIndex + 1) % events.length;
  currentEvent.value = events[nextEventIndex];
};

const goPreviousEvent = () => {
  if (!process.client) return;

  const currentEventIndex = events?.findIndex(
    (event) => event.id === currentEvent.value?.id
  );
  const previousEventIndex =
    (currentEventIndex - 1 + events.length) % events.length;
  currentEvent.value = events[previousEventIndex];
};

const getLastEvent = () => {
  lastEvent.value = events[events.length - 1];
  return lastEvent.value;
};

const titleElement = ref(null);
const copyElement = ref(null);
const numberElement = ref(null);

function findObjectWithYear(data, defaultYear) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].year === parseInt(defaultYear)) {
      return data[i];
    }
  }
  return null;
}
const currentImage = ref(null);
onMounted(() => {
  getLastEvent();
  currentEvent.value = findObjectWithYear(events, option.default_year);
  // currentImage.value = currentEvent.value.media;
  currentImage.value = allMedias.value[currentEvent.value.id];

  //preload the next 2 images and the previous 2 images:
  const nextImage = allMedias.value[currentEvent.id + 1];
  const nextNextImage = allMedias.value[currentEvent.id + 2];
  const previousImage = allMedias.value[currentEvent.id - 1];
  const previousPreviousImage = allMedias.value[currentEvent.id - 2];
  if (nextImage) {
    const img = new Image();
    img.src = nextImage.url;
  }
  if (nextNextImage) {
    const img = new Image();
    img.src = nextNextImage.url;
  }
  if (previousImage) {
    const img = new Image();
    img.src = previousImage.url;
  }
  if (previousPreviousImage) {
    const img = new Image();
    img.src = previousPreviousImage.url;
  }
  watch(currentEvent, (newEvent, oldEvent) => {
    if (newEvent && oldEvent) {
      const timeline = gsap.timeline();
      const direction = newEvent.id > oldEvent.id ? 1 : -1;

      timeline.to(titleElement.value, {
        opacity: 0,
        x: 20 * direction,
        duration: 0.3,
      });
      timeline.to(
        copyElement.value,
        { opacity: 0, x: 20 * direction, duration: 0.3 },
        "-=0.3"
      );

      titleElement.value.innerText = newEvent.year;
      copyElement.value.innerText = newEvent.copy;
      numberElement.value.innerText = newEvent.year;

      timeline.to(titleElement.value, { opacity: 1, x: 0, duration: 0.3 });
      timeline.to(
        copyElement.value,
        { opacity: 1, x: 0, duration: 0.3 },
        "-=0.3"
      );
      timeline.to(
        numberElement.value,
        { opacity: 1, x: 0, duration: 0.3 },
        "-=0.3"
      );
      setTimeout(() => {
        // currentImage.value = newEvent.media;
        currentImage.value = allMedias.value[newEvent.id];
      }, 200);
    }
  });
});
const updateEvent = (newEvent) => (currentEvent.value = newEvent);

const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
const isVisibleTopLeft = computed(() => {
  return currentEvent.value && currentEvent.value.visibleCorner === "top-left";
});

const isVisibleBottomLeft = computed(() => {
  return (
    currentEvent.value && currentEvent.value.visibleCorner === "bottom-left"
  );
});

const isVisibleTopRight = computed(() => {
  return currentEvent.value && currentEvent.value.visibleCorner === "top-right";
});

const isVisibleBottomRight = computed(() => {
  return (
    currentEvent.value && currentEvent.value.visibleCorner === "bottom-right"
  );
});

let previousCorner = null;
const mediaImage = ref(null);
watch(currentEvent, (newEvent) => {
  if (newEvent) {
    let randomCorner;
    do {
      randomCorner = ["top-left", "bottom-left", "top-right", "bottom-right"][
        Math.floor(Math.random() * 4)
      ];
    } while (randomCorner === previousCorner);
    newEvent.visibleCorner = randomCorner;
    previousCorner = randomCorner;
  }
  //preload the next 2 images and the previous 2 images
  const nextImage = allMedias.value[newEvent.id + 1];
  const nextNextImage = allMedias.value[newEvent.id + 2];
  const previousImage = allMedias.value[newEvent.id - 1];
  const previousPreviousImage = allMedias.value[newEvent.id - 2];
  if (nextImage) {
    const img = new Image();
    img.src = nextImage.url;
  }
  if (nextNextImage) {
    const img = new Image();
    img.src = nextNextImage.url;
  }
  if (previousImage) {
    const img = new Image();
    img.src = previousImage.url;
  }
  if (previousPreviousImage) {
    const img = new Image();
    img.src = previousPreviousImage.url;
  }
});

const topLeftCorner = ref(null);
const bottomLeftCorner = ref(null);
const topRightCorner = ref(null);
const bottomRightCorner = ref(null);
</script>

<template>
  <section
    class="history-timeline"
    :class="{
      'can-run': isVisible,
    }"
    ref="timeline"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="history-timeline__intro page-margin">
      <ElementsIntro v-bind="{ trumpet, headline }" class="el-intro" />
    </div>
    <div class="history-timeline__timeline">
      <div
        class="history-timeline__current-event-year ts-numbers"
        ref="yearContainer"
      >
        <div class="corner top-left" ref="topLeftCorner">
          <div
            class="media-element"
            ref="mediaElement"
            :class="{ 'is-active': isVisibleTopLeft }"
          >
            <Media
              v-if="currentImage"
              :media="{ image: currentImage }"
              class="image"
              ref="mediaImage"
              :lazy-image="false"
            />
          </div>
        </div>

        <div class="corner bottom-left" ref="bottomLeftCorner">
          <div
            class="media-element"
            ref="mediaElement"
            :class="{ 'is-active': isVisibleBottomLeft }"
          >
            <Media
              v-if="currentImage"
              :media="{ image: currentImage }"
              class="image"
              ref="mediaImage"
              :lazy-image="false"
            />
          </div>
        </div>

        <div
          class="history-timeline__current-event-number reveal middle"
          ref="numberElement"
        >
          {{ currentEvent?.year }}
        </div>

        <div class="corner top-right" ref="topRightCorner">
          <div
            class="media-element"
            ref="mediaElement"
            :class="{ 'is-active': isVisibleTopRight }"
          >
            <Media
              v-if="currentImage"
              :media="{ image: currentImage }"
              class="image"
              ref="mediaImage"
              :lazy-image="false"
            />
          </div>
        </div>

        <div class="corner bottom-right" ref="bottomRightCorner">
          <div
            class="media-element"
            ref="mediaElement"
            :class="{ 'is-active': isVisibleBottomRight }"
          >
            <Media
              v-if="currentImage"
              :media="{ image: currentImage }"
              class="image"
              ref="mediaImage"
              :lazy-image="false"
            />
          </div>
        </div>
      </div>

      <div
        class="history-timeline__years"
        ref="timelineContainer"
        :class="{ 'page-margin': !$viewport.isLessThan('desktopView') }"
      >
        <ElementsTimeline
          :years="years"
          :events="events"
          :current-event="currentEvent"
          @clicked-event="updateEvent"
        />
      </div>
      <div class="history-timeline__content page-margin">
        <div
          class="history-timeline__content-title"
          :class="{
            'ts-h4': $viewport.isLessThan('desktopView'),
            'ts-h2': !$viewport.isLessThan('desktopView'),
          }"
          ref="titleElement"
        >
          {{ currentEvent?.headline }}
        </div>
        <div class="history-timeline__content-copy ts-p1" ref="copyElement">
          {{ currentEvent?.copy }}
        </div>
        <elements-carousel-controls
          class="history-timeline__content-nav"
          @go-previous="goNextEvent"
          @go-next="goPreviousEvent"
        />
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.history-timeline {
  --toast-opacity: 0;
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  background-color: var(--loud-indigo);
  color: var(--white);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: var(--section-margin);

  @media (--md) {
    padding-bottom: 0;
  }

  &__timeline {
    display: flex;
    flex-direction: column;

    /* gap: units(2); */

    @media (--until-md) {
      & > * {
        &:first-of-type {
          margin-bottom: units(2);
        }

        &:last-of-type {
          padding-top: units(2);
        }
      }
    }

    @media (--md) {
      gap: units(3);
    }
  }

  &__intro {
    .el-intro {
      color: var(--white);
      padding: units(9) 0 units(3) 0;

      &:deep(.intro__count) {
        color: var(--white);

        &:after {
          background: var(--white);
        }
      }
    }
  }

  &__current-event {
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: units(50);

    &-year {
      position: relative;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .corner {
        width: units(11);
        height: units(8);

        @media (--md) {
          width: units(27);
          height: units(18);
        }
      }

      .media-element {
        position: absolute;
        width: auto;
        height: 100%;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;

        &.is-active {
          opacity: 1;
          transition-delay: 0.2s;
        }

        &:deep(.image) {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }

      .top-left {
        grid-area: 1 / 1 / 2 / 2;
        position: relative;
        width: 100%;

        .media-element {
          right: units(-3);
          padding-left: var(--page-margin);
        }
      }

      .bottom-left {
        grid-area: 2 / 1 / 3 / 2;
        position: relative;
        width: 100%;

        .media-element {
          right: units(-3);
          padding-left: var(--page-margin);
        }
      }

      .middle {
        grid-area: 1 / 2 / 3 / 3;
        text-align: center;
        font-size: units(14);
        display: flex;
        align-items: center;

        @media (--md) {
          font-size: units(30);
        }
      }

      .top-right {
        grid-area: 1 / 3 / 2 / 4;
        position: relative;
        width: 100%;

        .media-element {
          left: units(-3);
          padding-right: var(--page-margin);
        }
      }

      .bottom-right {
        grid-area: 2 / 3 / 3 / 4;
        position: relative;
        width: 100%;

        .media-element {
          left: units(-3);
          padding-right: var(--page-margin);
        }

        /* .media {
          position: absolute;
          top: 0;
          left: 0;
          width: units(11);
          height: units(8);
          z-index: 1;
          border-radius: units(0.5);
          overflow: hidden;
          line-height: 0;
          @media (--md) {
            width: units(27);
            height: units(18);
          }

          &:deep(.image) {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        } */
      }
    }

    &-number {
      position: relative;
      text-shadow: 0 0 units(10) var(--grey-4);
      z-index: 2;
    }
  }

  &__content {
    display: grid;
    gap: units(3);
    position: relative;
    width: 100%;
    min-height: units(20);
    padding-top: units(3);

    @media (--md) {
      gap: units(2);
      grid-template-columns: repeat(12, 1fr);
    }

    &-title {
      margin-top: units(3);

      @media (--md) {
        margin-top: 0;
        width: 100%;
        grid-column: span 4;
      }
    }

    &-copy {
      @media (--md) {
        width: 100%;
        grid-column: span 7;
      }
    }

    &-nav {
      position: absolute;
      top: units(3);
      right: var(--page-margin);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - var(--page-margin) * 2);
      height: 1px;
      background-color: var(--grey-1);
    }
  }

  &__years {
    width: 100%;
    position: relative;
    scroll-behavior: smooth;
  }

  &__year {
    position: relative;
    height: fit-content;

    &:hover {
      --toast-opacity: 1;
    }

    &.has-event {
      cursor: pointer;
    }
  }

  .line {
    width: 2px;
    background-color: #ccc;
    border-radius: units(1) units(1) 0 0;
    /* transition: transform 0.1s ease-in-out; */
    /* transition: all 0.2s ease-in-out; */
  }

  .year-toast {
    position: absolute;
    opacity: var(--toast-opacity);
    background-color: var(--white);
    border-radius: units(5);
    padding: units(0.8);
    color: var(--black);
    top: units(-5);
    left: 50%;
    transform: translate(-50%, 0);
    transition: opacity 0.1s ease-in-out;
  }

  .active {
  }
}
</style>
