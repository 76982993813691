<script setup>
const props = defineProps({
  data: Object,
});

// const seo = useWpSeo(props?.data);
provide("is-article", true);
</script>

<template>
  <div class="article" v-if="data">
    <ArticleHero v-if="data" :trumpet="data.acf?.trumpet" :headline="data.title?.rendered" :published_date="data.date"
      :modified_date="data.modified" :image="data.acf?.image" />
    <div class="article__meta">
      <div class="article__inner page-margin">
        <div class="article__tags">
          <TaxonomyTag :label="data?.acf?.taxonomy?.name" />
        </div>
      </div>
    </div>
    <PageModules v-if="data?.acf" :modules="data.acf?.article_modules" />
  </div>
</template>

<style lang="postcss" scoped>
.article {
  &__meta {
    position: relative;
  }

  &__inner {
    @media (--md) {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: units(2);
    }
  }

  &__tags {
    margin-bottom: units(3);

    @media (--md) {
      grid-column: 1/4;
      order: 1;
    }
  }
}
</style>
