<script setup>
const props = defineProps({
  items: Array,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <div class="list" :class="{
    'can-run': isVisible,
  }" v-view="(e) => runIntroAnimation(e)">
    <ElementsAccordionTabs role="tablist">
      <ElementsAccordionTab v-for="(item, index) in items" class="list-item reveal" :style="{
        '--active-color': item.color,
        '--active-size': item.size + '%',
        'transition-delay': index * 0.1 + 's',
      }" :key="index" role="tab">
        <template #header>
          <div class="list-item__header">
            <div class="bubble">
              <div class="bubble__outer"></div>
              <div class="bubble__inner"></div>
            </div>

            <div class="number">
              <div class="ts-numbers">{{ item.number }}</div>
              <RTE class="ts-p1" v-bind="{ data: item.unit }" />
            </div>
          </div>
        </template>
        <template #content>
          <div class="list-item__content">
            <div class="ts-trumpet list-item__label">Solution</div>
            <h4 class="ts-p1-bold list-item__headline">{{ item.headline }}</h4>
            <RTE v-bind="{ data: item.copy }" />
          </div>
        </template>
      </ElementsAccordionTab>
    </ElementsAccordionTabs>
  </div>
</template>

<style lang="postcss" scoped>
.list {
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  --line-color: var(--grey-1);

  &-item {
    padding: units(3) 0 units(2) 0;
    position: relative;

    &__header {
      color: var(--grey-3);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: units(2);
      width: 100%;
      transition: color 0.3s ease-in-out;

      .bubble {
        grid-column: 1;
        aspect-ratio: 1;
      }

      .number {
        grid-column: span 2;
        color: inherit;

        .rich-text {
          color: unset;
        }
      }
    }

    &__label {
      margin-bottom: units(2);

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        display: inline-flex;
        margin-right: units(1);
        background: var(--active-color);
      }
    }

    &__headline {
      margin-bottom: units(1);
    }

    &__content {
      padding-top: units(3);
    }

    &.expanded {
      --line-color: var(--active-color);

      .list-item__header {
        color: var(--active-color);
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--line-color);
      transition: background 0.3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:last-child {
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: var(--grey-1);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .bubble {
    width: 100%;
    position: relative;

    &__outer,
    &__inner {
      border-radius: 100%;
      background: currentColor;
      display: flex;
      justify-content: center;
      aspect-ratio: 1;
    }

    &__outer {
      color: #ebe9e7;
      width: 100%;
    }

    &__inner {
      background: var(--active-color);
      width: var(--active-size);
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
}
</style>
