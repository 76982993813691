<script setup>
const props = defineProps({
  employees: [Array, Boolean],
  headline: String,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section employee-list page-margin"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="title ts-h2" v-if="headline">{{ headline }}</div>
    <div class="employee-list__grid">
      <ElementsEmployeeCard
        v-for="(employee, index) in employees"
        :key="index"
        :employee="employee"
        :mobile-card="true"
        :is-management="true"
        class="reveal"
        :style="`transition-delay: ${index * 0.1}s`"
      />
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.employee-list {
  padding-top: units(9);
  padding-bottom: units(3);
  display: grid;
  gap: units(6);

  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  &__grid {
    display: grid;
    gap: units(2);
    @media (--md) {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }
  }
}
</style>
