<script setup>
const props = defineProps({
  headline: String,
  trumpet: String,
  quote: Boolean,
  call_to_action_download: Object,
  media: Object,
});

const target = ref(null);
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section class="section breaker" :class="{
    'can-run': isVisible,
  }" ref="target" v-view="(e) => runIntroAnimation(e)">
    <div class="breaker__content page-margin">
      <div class="breaker__content-inner">
        <div class="breaker__trumpet ts-trumpet reveal">
          {{ trumpet }}
        </div>
        <ElementsBlockQuote v-if="quote" class="breaker__quote ts-large-serif reveal" :quote="headline" />
        <h2 v-else class="breaker__title reveal" :class="{
          'ts-h3': $viewport.isLessThan('desktopView'),
          'ts-h1': !$viewport.isLessThan('desktopView'),
        }">
          {{ headline }}
        </h2>
      </div>
      <div class="breaker__ctas reveal">
        <ButtonCTA v-if="call_to_action_download?.file && call_to_action_download?.label" class="breaker__cta"
          :has-icon="true" icon="arrowsimple" :is-download="true" :cta="call_to_action_download" type="primary" />
        <ButtonCTA v-else-if="(call_to_action_download?.page_link ||
            call_to_action_download?.url) &&
          call_to_action_download?.label
          " class="breaker__cta" :has-icon="true" icon="arrowsimple" :cta="call_to_action_download" type="primary" />
      </div>
    </div>

    <div class="breaker__background" v-if="media?.image || media?.video.mp4_video">
      <Media :media="media" :cover="true" :loop="true" v-view.progress />
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.breaker {
  --parallax: 5rem;
  --translate-title: units(1.5);
  --opacity-title: 0;

  position: relative;
  max-width: 100%;
  min-height: 50vh;
  text-align: center;
  color: var(--white);

  @media (--md) {
    aspect-ratio: 16 / 7;
  }

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }

  &__quote {
    transition-delay: 0.2s;
  }

  &__title {
    transition-delay: 0.4s;
  }

  &__ctas {
    transition-delay: 0.8s;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;

    &-inner {

      /* padding-left: units(5);
      padding-right: units(5); */
      @media (--md) {
        /* padding-left: units(10);
        padding-right: units(10); */
      }
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    z-index: 0;
    background: var(--grey-1);
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%);
    }

    .media {
      height: 120%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(calc(var(--view-progress) * var(--parallax)));
      transition: transform .5s cubic-bezier(0.61, 1, 0.88, 1);
    }
  }

  &__trumpet {
    margin-bottom: units(2);
  }

  &__cta {
    margin-top: units(6);
  }
}
</style>
