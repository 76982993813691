<script setup>
const { formatDate } = usePublishedDate();

const props = defineProps({
  post_date: String,
  post_modified: String,
  post_title: String,
  link: [URL, String],
  // location: [String, null], // inside acf
  // rich_text: [String, null], // inside acf
  // image: [String, null], // inside acf
  acf: [Object, null], // might not be applicable

  // COMMENT: just so it doesn't show in the DOM
  id: [String, null],
  post_author: [String, null],
  post_date_gmt: [String, null],
  post_content: [String, null],
  post_content_filtered: [String, null],
  post_name: [String, null],
  post_type: [String, null],
  menu_order: [Number, String, null],
  post_mime_type: [String, null],
  comment_count: [String, null],
  comment_status: [String, null],
  filter: [String, null],
  post_excerpt: [String, null],
  post_status: [String, null],
  ping_status: [String, null],
  post_modified_gmt: [String, null],
  post_password: [String, null],
  pinged: [String, null],
  ping_to: [String, null],
  to_ping: [String, null],
  guid: [String, null],
  post_parent: [Number, String, null],
});

const formattedDate = computed(() =>
  formatDate({
    value: {
      date: props.post_date,
      // modified: props.post_modified
    },
  })
);
// const formattedDate = computed(()=>'13 Jan 2023')
const data = reactive({
  //if props.link starts with the same domain as the current domain, then it's an internal link and we need to remove the domain from the link
  page_link:
    process.client && props.link.startsWith(window.location.origin)
      ? props.link.replace(window.location.origin, "")
      : props.link,
  // page_link: props.link,
});
</script>
<template>
  <custom-link :data="data" class="press-release-card">
    <template #label>
      <div class="press-release-card__content">
        <div class="media">
          <Media
            :media="{ image: acf.image }"
            :cover="true"
            v-if="acf.image"
            :useThumbnail="$viewport.isLessThan('desktopView') ? false : true"
          />
        </div>
        <div class="infos">
          <div class="infos-location ts-caps" v-if="acf.location">
            {{ acf.location }}
          </div>
          <div class="infos-date ts-caps">{{ formattedDate }}</div>
        </div>
        <div
          class="copy"
          :class="{
            'ts-p1-bold': $viewport.isLessThan('desktopView'),
            'ts-h4': !$viewport.isLessThan('desktopView'),
          }"
        >
          {{ post_title }}
        </div>
      </div></template
    >
  </custom-link>
</template>

<style lang="postcss">
.press-release-card {
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: units(1);
    width: 100%;
    padding-top: units(2);
    padding-bottom: units(2);

    @media (--md) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: units(2);
      padding-bottom: unset;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--grey-0);
      top: 0;
      left: 0;
    }

    .media {
      width: 100%;
      height: units(20);
      margin-bottom: units(2);
      border-radius: units(1);
      overflow: hidden;
      background-color: var(--grey-4);

      @media (--md) {
        flex-shrink: 0;
        width: units(13);
        height: units(13);
        grid-column: 1/3;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .infos {
      display: flex;
      flex-direction: row;
      gap: units(1);

      @media (--md) {
        flex-direction: column;
        gap: units(0.5);
        width: units(10);
        grid-column: 3/5;
      }

      /* &-location {} */
      &-date {
        color: var(--grey-4);
      }
    }

    .copy {
      @media (--md) {
        grid-column: 5/13;
      }
    }
  }
}
</style>
