<script setup>
const props = defineProps({
  hasIcon: { type: Boolean, default: true },
  isCard: { type: Boolean, default: false },
  to: String,
  href: String,
  target: String,
  rel: String,
  prefetch: Boolean,
  label: { type: String, default: "button label" },
  icon: { type: String, default: "SvgPlay" },
  isDownload: { type: Boolean, default: false },
  fileToDownload: [Object, String],
  isDisabled: { type: Boolean, default: false },
  cta: Object,
  type: {
    type: String,
    required: false,
    default: "primary",
    validator: (val) =>
      [
        "primary",
        "secondary",
        "third",
        "fourth",
        "primary-outline",
        "secondary-outline",
        "third-outline",
        "disabled-primary",
        "disabled-primary-outline",
        "disabled-secondary",
        "disabled-secondary-outline",
        "disabled-third",
        "disabled-third-outline",
      ].includes(val),
  },
});

const className = () => {
  let classname = "button-cta";

  if (props.type) {
    classname += "__" + props.type;
  }
  if (props.isCard) {
    classname += " button-cta__card";
  }
  if (props.isDownload) {
    classname += " button-cta__download";
  }

  return classname;
};

const components = {
  burger: resolveComponent("SvgBurger"),
  accordionarrow: resolveComponent("SvgAccordionArrow"),
  close: resolveComponent("SvgClose"),
  openoverlay: resolveComponent("SvgOpenOverlay"),
  logo: resolveComponent("SvgLogo"),
  quote: resolveComponent("SvgQuote"),
  switch: resolveComponent("SvgSwitch"),
  arrow: resolveComponent("SvgArrow"),
  arrowsimple: resolveComponent("SvgArrowSimple"),
};
</script>

<template>
  <div class="button-cta" :class="className()">
    <div class="content">
      <CustomLink
        :to="to"
        :target="target"
        :rel="rel"
        :file="fileToDownload"
        :data="cta"
        class="link"
      >
        <template v-slot:label>
          <span class="ts-cta">
            {{ cta?.label || label }}
          </span>
        </template>
      </CustomLink>
      <div class="icons">
        <component
          v-if="hasIcon"
          class="icon icon-1"
          :class="`'-${icon}`"
          :is="components[icon]"
        />
        <component
          v-if="hasIcon"
          class="icon icon-2"
          :class="`'-${icon}`"
          :is="components[icon]"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.button-cta {
  --line-scale: 0;
  --line-origin: bottom right;
  --color-btn: var(--white);
  --bg-color-btn: var(--loud-indigo);
  --border-color-btn: var(--loud-indigo);
  --border-btn: 0px;

  --icon-1-left: -100%;
  --icon-1-opacity: 0;
  --icon-2-left: 0%;
  --icon-2-opacity: 1;
  cursor: pointer;
  padding: units(1.6) units(2);
  display: inline-flex;
  border-radius: units(10);

  width: fit-content;

  background-color: var(--bg-color-btn);
  color: var(--color-btn);
  border: solid var(--border-btn) var(--border-color-btn);
  transition: all 0.3s ease-in-out;
  &__primary {
    --color-btn: var(--white);
    --bg-color-btn: var(--loud-indigo);
    --border-color-btn: transparent;
    --border-btn: 1px;

    &-outline {
      --color-btn: var(--loud-indigo);
      --bg-color-btn: transparent;
      --border-color-btn: var(--loud-indigo);
      --border-btn: 1px;
      &:hover {
        --color-btn: var(--white);
        --bg-color-btn: var(--loud-indigo);
        --border-color-btn: transparent;
        --border-btn: 1px;
      }
    }

    &:hover {
      --color-btn: var(--loud-indigo);
      --bg-color-btn: var(--white);
      --border-color-btn: var(--loud-indigo);
      --border-btn: 1px;
    }
  }

  &__secondary {
    --color-btn: var(--loud-indigo);
    --bg-color-btn: var(--white);
    --border-color-btn: transparent;
    --border-btn: 1px;

    &-outline {
      --bg-color-btn: transparent;
      --color-btn: var(--white);
      --border-color-btn: var(--white);
      --border-btn: 1px;
      &:hover {
        --color-btn: var(--loud-indigo);
        --bg-color-btn: var(--white);
        --border-color-btn: transparent;
        --border-btn: 1px;
      }
    }
    &:hover {
      --bg-color-btn: transparent;
      --color-btn: var(--white);
      --border-color-btn: var(--white);
      --border-btn: 1px;
    }
  }
  &__third {
    --color-btn: var(--grey-4);
    --bg-color-btn: var(--white);
    --border-color-btn: transparent;
    --border-btn: 1px;
    &-outline {
      --color-btn: var(--grey-4);
      --bg-color-btn: transparent;
      --border-color-btn: var(--grey-4);
      --border-btn: 1px;
      &:hover {
        --color-btn: var(--grey-4);
        --bg-color-btn: var(--white);
        --border-color-btn: transparent;
        --border-btn: 1px;
      }
    }

    &:hover {
      --color-btn: var(--grey-4);
      --bg-color-btn: transparent;
      --border-color-btn: var(--grey-4);
      --border-btn: 1px;
    }
  }

  &__fourth {
    --color-btn: var(--grey-4);
    --bg-color-btn: transparent;
    &:hover {
    }
  }

  &__disabled-primary,
  &__disabled-secondary,
  &__disabled-third {
    opacity: 0.5;
    pointer-events: none;
    &-outline {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__disabled-primary {
    background-color: var(--loud-indigo);
    color: var(--white);
    --color-btn: var(--white);
    &-outline {
      background-color: transparent;
      color: var(--loud-indigo);
      --color-btn: var(--loud-indigo);
      border: solid 1px var(--loud-indigo);
    }
  }
  &__disabled-secondary {
    background-color: var(--white);
    color: var(--loud-indigo);
    --color-btn: var(--loud-indigo);
    &-outline {
      background-color: transparent;
      color: var(--white);
      --color-btn: var(--white);
      border: solid 1px var(--white);
    }
  }
  &__disabled-third {
    background-color: var(--white);
    color: var(--grey-4);
    --color-btn: var(--grey-4);
    &-outline {
      background-color: transparent;
      color: var(--grey-4);
      --color-btn: var(--grey-4);
      border: solid 1px var(--grey-4);
    }
  }

  &__card {
    border-radius: units(1.5);
    padding: units(8);
  }
  &__download {
    .icon {
      rotate: 90deg;
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: units(1);
    flex-direction: row;
    color: var(--color-btn);
  }

  .link {
    text-decoration: none;
    position: relative;
    color: var(--color-btn);

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(var(--line-scale));
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: var(--color-btn);
      transform-origin: var(--line-origin);
      transition: transform 0.3s ease-out;
    }
  }

  .icons {
    width: units(2);
    height: units(2);
    position: relative;

    .icon-1 {
      position: absolute;
      left: 0;
      transform: translateX(var(--icon-1-left));
      opacity: var(--icon-1-opacity);
      transition: all 0.3s ease-in-out;
    }
    .icon-2 {
      position: absolute;
      left: 0;
      transform: translateX(var(--icon-2-left));
      opacity: var(--icon-2-opacity);
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    .icon {
      --icon-1-left: 0%;
      --icon-1-opacity: 1;
      --icon-2-left: 100%;
      --icon-2-opacity: 0;
    }
  }
}
</style>
