<script setup>
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
const props = defineProps({
  is_innovation: Boolean,
});
const nuxtApp = useNuxtApp();

const { option } = await useOptions("places", nuxtApp);
const placesMap = ref(null);
const placesMapPoints = ref([]);
const container = ref(null);
const clickedId = ref(null);
let desiredSpacing = 100;
const viewport = useViewport();
const windowWidth = ref(null);
const cardWidth = ref(0);
const cardHeight = ref(0);
const containerWidth = ref(0);
const cards = ref(null);
const beginX = ref();
const beginY = ref();
const endX = ref();
const endY = ref();
const lineWidth = ref(0);
const currentActive = ref(null);
const isOpen = ref(false);

const openOverlay = (place) => {
  useEvent("overlay:open", {
    data: place,
    type: "place",
  });
};

const getPointsCoordinates = () => {
  const pointsCoordinates = [];
  placesMapPoints.value.forEach((point) => {
    const pointCoordinates = point?.getBoundingClientRect();
    pointsCoordinates.push({
      id: point.id,
      x: pointCoordinates.x,
      y: pointCoordinates.y,
    });
  });
  return pointsCoordinates;
};

const setPlaceCardPosition = () => {
  if (!process.client) return;
  const pointsCoordinates = getPointsCoordinates();
  const placeCards = document.querySelectorAll(".place");
  placeCards.forEach((card) => {
    const cardId = card.id.toLowerCase();
    const cardCoordinates = pointsCoordinates?.find(
      (point) => point.id === cardId
    );

    if (!cardCoordinates) {
      return;
    }

    const containerPosition = container.value?.getBoundingClientRect();
    cardWidth.value = card.offsetWidth;
    cardHeight.value = card.offsetHeight;
    containerWidth.value = container.value?.getBoundingClientRect().width;

    if (window.innerWidth <= 1000) {
      desiredSpacing = 50;
    }

    const containerPadding = 20;
    const containerWidthWithPadding =
      containerWidth.value - containerPadding * 2;

    let relativeTop =
      cardCoordinates.y - containerPosition?.top - cardHeight.value / 2;
    let relativeLeft = cardCoordinates.x - containerPosition?.left;
    if (
      relativeLeft + cardWidth.value + desiredSpacing >
      containerWidthWithPadding
    ) {
      relativeLeft =
        cardCoordinates.x -
        containerPosition?.left -
        cardWidth.value -
        desiredSpacing;
    } else {
      relativeLeft =
        cardCoordinates.x - containerPosition?.left + desiredSpacing;
    }

    card.style.top = `${relativeTop}px`;
    card.style.left = `${relativeLeft}px`;

    // add 'left' or 'right' class to card depending on which side it is from the corresponding point
    if (relativeLeft < cardCoordinates.x - containerPosition?.left) {
      card.classList.add("left");
      card.classList.remove("right");
    } else {
      card.classList.add("right");
      card.classList.remove("left");
    }
  });
};

const close = () => {
  isOpen.value = false;
  clickedId.value = null;
};

const handleClick = (point) => {
  clickedId.value = clickedId.value === point.id ? null : point.id;

  if (
    !clickedId.value ||
    !cards.value.querySelector(`#${point?.id?.toLowerCase()}`)
  ) {
    return;
  }
  const place = cards.value.querySelector(`#${point.id.toLowerCase()}`);
  const containerPosition = container.value?.getBoundingClientRect();
  currentActive.value = point.id.toLowerCase();
  beginX.value = point?.getBoundingClientRect().x - containerPosition?.left;
  beginY.value = point?.getBoundingClientRect().y - containerPosition?.top;
  place.classList.contains("left")
    ? (endX.value =
        place?.getBoundingClientRect().x -
        containerPosition?.left +
        place.offsetWidth)
    : (endX.value = place?.getBoundingClientRect().x - containerPosition?.left);
  endY.value =
    place?.getBoundingClientRect().y +
    place.offsetHeight / 2 -
    containerPosition?.top;
  lineWidth.value =
    endX.value - beginX.value <= 0
      ? Math.abs(endX.value - beginX.value)
      : endX.value - beginX.value;
  isOpen.value = true;
  if (viewport.isLessThan("desktopView") && clickedId.value === point.id) {
    const place = option?.places?.find(
      (place) => place.id.toLowerCase() === point.id
    );
    place ? openOverlay(place) : null;
  }
};

const addClickEventListener = (selector) => {
  placesMap.value.querySelectorAll(selector).forEach((point) => {
    point.addEventListener("click", (e) => {
      setPlaceCardPosition();
      handleClick(point);
      setPlaceCardPosition();
    });
  });
};

const recalculateValues = (point) => {
  const place = cards.value.querySelector(`#${point.id.toLowerCase()}`);
  const containerPosition = container.value?.getBoundingClientRect();
  beginX.value = point?.getBoundingClientRect().x - containerPosition?.left;
  beginY.value = point?.getBoundingClientRect().y - containerPosition?.top;
  place.classList.contains("left")
    ? (endX.value =
        place?.getBoundingClientRect().x -
        containerPosition?.left +
        place.offsetWidth)
    : (endX.value = place?.getBoundingClientRect().x - containerPosition?.left);
  endY.value =
    place?.getBoundingClientRect().y +
    place.offsetHeight / 2 -
    containerPosition?.top;
  lineWidth.value =
    endX.value - beginX.value <= 0
      ? Math.abs(endX.value - beginX.value)
      : endX.value - beginX.value;
};

const handleResize = () => {
  windowWidth.value = window.innerWidth;
  setPlaceCardPosition();
  if (clickedId.value) {
    const point = placesMap.value.querySelector(
      `#${clickedId.value.toLowerCase()}`
    );
    recalculateValues(point);
  }
};

onMounted(() => {
  if (!process.client) return;
  windowWidth.value = window.innerWidth;
  placesMapPoints.value = placesMap.value.querySelectorAll([
    ".point",
    ".multipoint",
  ]);

  setPlaceCardPosition();

  addClickEventListener(".multipoint");
  addClickEventListener(".point");
  window.addEventListener("resize", handleResize);

  setPlaceCardPosition();
  setTimeout(() => {
    setPlaceCardPosition();
  }, 100);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

watch([windowWidth, cardWidth, containerWidth], () => {
  nextTick(() => {
    setPlaceCardPosition();
  });
  setPlaceCardPosition();
});
setTimeout(() => {
  setPlaceCardPosition();
}, 1000);
useListen("overlay:close", () => {
  clickedId.value = null;
  setPlaceCardPosition();
});
const orange = "#ff901e";
const coral = "#ff534b";

//module landing reveal animation:
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
const isScrolling = ref(false);
watch([isVisible], () => {
  if (isVisible.value) {
    const getMiddlePoint = (isInnovation) => {
      const pointsCoordinates = getPointsCoordinates();
      const multipoints = isInnovation
        ? placesMap.value.querySelectorAll(".point")
        : placesMap.value.querySelectorAll(".multipoint");
      const multipointsCoordinates = [];

      multipoints.forEach((multipoint) => {
        const multipointCoordinates = pointsCoordinates?.find(
          (point) => point.id === multipoint.id
        );
        if (!isInnovation) {
          if (multipoint.id !== "new-york")
            multipointsCoordinates.push(multipointCoordinates);
        } else {
          if (multipoint.id === "lynge" || multipoint.id === "singapore")
            multipointsCoordinates.push(multipointCoordinates);
        }
      });

      const middlePoint = {
        x: 0,
        y: 0,
      };

      multipointsCoordinates.forEach((multipoint) => {
        middlePoint.x += multipoint.x;
        middlePoint.y += multipoint.y;
      });

      middlePoint.x = middlePoint.x / multipointsCoordinates.length;
      middlePoint.y = middlePoint.y / multipointsCoordinates.length;
      return middlePoint;
    };

    const scrollToMiddlePoint = () => {
      let middlePoint;
      if (!props.is_innovation) middlePoint = getMiddlePoint();
      else middlePoint = getMiddlePoint(true);
      const containerPosition = placesMap.value?.getBoundingClientRect();
      const containerWidth = containerPosition?.width;
      const containerHeight = containerPosition?.height;
      const middlePointX = middlePoint.x - containerWidth / 2;
      const middlePointY = middlePoint.y - containerHeight / 2;
      gsap.to(placesMap.value, {
        duration: 4,
        scrollTo: {
          x: middlePointX,
          autoKill: false,
        },
        ease: "power1.out",
        onStart: () => {
          // console.log("start");
          isScrolling.value = true;
        },
        onComplete: () => {
          // console.log("done");
          isScrolling.value = false;
        },
      });
    };

    if (viewport.isLessThan("desktopView")) scrollToMiddlePoint();
  }
});

const pointHasBeenClicked = ref(true);
watch([clickedId], () => {
  if (clickedId.value) {
    pointHasBeenClicked.value = false;
  }
});
</script>

<template>
  <section
    class="places"
    :class="{
      'page-margin': !$viewport.isLessThan('desktopView'),
      'can-run': isVisible,
    }"
    ref="container"
    :style="`--line-width:${lineWidth}px;--line-height:${
      endY - beginY
    }px; --BeginX: ${beginX}px; --BeginY: ${beginY}px; --EndX: ${endX}px; --EndY: ${endY}px;--line-negative:${
      endX - beginX <= 0 ? -1 : 0
    };--line-direction:${
      endX - beginX <= 0 ? 'left' : 'right'
    };--line-begin-side:${endX - beginX <= 0 ? '0px' : '20px'};`"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div
      class="places__tags"
      :class="{ 'page-margin': $viewport.isLessThan('desktopView') }"
      v-if="is_innovation"
    >
      <div class="tag" v-for="(tag, index) in option.tags" :key="index">
        <div
          class="tag__container"
          v-if="tag.color === orange || tag.color === coral"
        >
          <span
            class="tag__circle"
            :style="`--circle-color:${tag.color}`"
          ></span>
          <div class="tag__title ts-caps">{{ tag.title }}</div>
        </div>
      </div>
    </div>
    <div
      class="places__tags"
      :class="{ 'page-margin': $viewport.isLessThan('desktopView') }"
      v-else
    >
      <div
        class="tag"
        v-for="(tag, index) in option.tags.slice(0, 4)"
        :key="index"
      >
        <div class="tag__container">
          <span
            class="tag__circle"
            :style="`--circle-color:${tag.color}`"
          ></span>
          <div class="tag__title ts-caps">{{ tag.title }}</div>
        </div>
      </div>
    </div>
    <div
      class="places__map"
      :class="{
        'page-margin': $viewport.isLessThan('desktopView'),
        'is-scrolling': isScrolling,
      }"
      ref="placesMap"
    >
      <SvgMapWithPoints
        class="svg-map"
        :is-innovation="is_innovation"
        :should-breathe="pointHasBeenClicked"
      />
    </div>
    <div class="places__locations" ref="cards">
      <ElementsPlaceCard
        v-for="(place, index) in option.places"
        :key="index"
        :place="place"
        class="place"
        :class="{ active: clickedId === place.id.toLowerCase() }"
        :id="place.id.toLowerCase()"
      />
      <div
        class="invisible-point begin"
        :class="{ active: clickedId === currentActive }"
      ></div>
      <div class="invisible-point end"></div>
    </div>
    <div
      v-if="!$viewport.isLessThan('desktopView')"
      class="places__backdrop"
      :class="{ 'is-open': isOpen }"
      @click="close"
    ></div>
  </section>
</template>

<style lang="postcss" scoped>
.places {
  --card-line-width: 0;
  background-color: var(--white);
  display: grid;
  gap: units(7);
  padding-bottom: var(--section-margin);
  padding-top: var(--section-margin);
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (--md) {
    overflow: visible;
    gap: units(2);
    grid-template-columns: repeat(12, 1fr);
  }

  .svg-map {
    &:deep(.point) {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        r: 10px;
      }
    }

    &:deep(.multipoint) {
      cursor: pointer;
      position: relative;

      & > * {
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        & > * {
          r: 10px;
        }
      }
    }
  }

  &__tags {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: units(0.5);
    width: 100%;

    order: 2;

    @media (--md) {
      /* order: 1; */
      /* grid-column: span 3; */

      position: absolute;
      left: var(--page-margin);
      bottom: var(--page-margin);
      width: 50%;
    }

    .tag {
      &__container {
        display: flex;
        flex-direction: row;
        gap: units(1);
        align-items: center;
      }

      &__circle {
        height: units(1.2);
        width: units(1.2);
        border-radius: 50%;
        background-color: var(--circle-color);
      }

      &__title {
        color: var(--grey-4);
      }
    }
  }

  &__map {
    /* scroll-behavior: smooth; */
    position: relative;
    min-height: units(40);
    height: 100%;
    width: auto;
    overflow-x: auto;
    order: 1;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      height: 0px;
    }

    @media (--md) {
      width: 100%;
      order: 2;
      overflow-x: none;
      grid-column: span 12;
      text-align: center;
    }

    svg {
      @media (--md) {
        width: 80%;
        height: auto;
      }
    }
  }

  &__locations {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .place {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      height: fit-content;
      display: none;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      @media (--md) {
        display: block;
      }

      &.active {
        opacity: 1;
      }
    }

    .invisible-point {
      /* background-color: red; */
      position: absolute;
      width: units(3);
      height: units(3);
      display: none;

      @media (--md) {
        display: block;
      }
    }

    .begin {
      left: var(--BeginX);
      top: var(--BeginY);
      pointer-events: none;

      &:after {
        transition: width 0.2s ease-in-out;
        content: "";
        background: linear-gradient(
          to var(--line-direction),
          transparent,
          var(--grey-3) 50%
        );
        /* background-color: black; */
        display: inline-block;
        width: var(--card-line-width);
        height: 1px;
        position: absolute;
        transform: translateX(calc(var(--line-width) * var(--line-negative)));
        top: calc(units(2) / 2);
        left: var(--line-begin-side);
        z-index: 1;
      }

      /* background-color: red; */
      &.active {
        --card-line-width: var(--line-width);
      }
    }

    .end {
      pointer-events: none;
      left: calc(var(--EndX));
      top: var(--EndY);
      /* background-color: green; */
    }
  }

  &__backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    /* background-color: var(--black); */
    visibility: hidden;
    /* opacity: 0; */
    z-index: 10;
    top: 0;
    left: 0;

    &.is-open {
      visibility: visible;
      /* opacity: 0.5; */
    }
  }
}
</style>
