<script setup>
const props = defineProps({
  lists: Array,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin triple-list"
    :class="{
      'can-run': isVisible,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div v-if="!$viewport.isLessThan('desktopView')" class="triple-list__row">
      <div
        class="triple-list__row-title ts-p1-bold reveal"
        v-for="(list, index) in lists"
        :key="index"
        :style="`transition-delay: ${index * 0.1}s`"
      >
        <component
          v-if="list.list.headline"
          :is="list.list.heading_tag.value || 'h2'"
          class="ts-p1-bold"
          >{{ list.list.headline }}</component
        >
      </div>
    </div>

    <div class="triple-list__columns">
      <div class="triple-list__columns-column" v-for="list in lists">
        <div
          v-if="$viewport.isLessThan('desktopView')"
          class="triple-list__columns-column-title ts-p1-bold"
        >
          {{ list.list.headline }}
        </div>
        <div
          class="triple-list__list reveal"
          v-for="(el, index) in list.list.items"
          :key="index"
          :style="`transition-delay: ${index * 0.1}s`"
        >
          <div v-if="el.icon" class="triple-list__list-media">
            <Media :media="{ image: el.icon }" :cover="true" />
          </div>
          <div class="triple-list__list-content">
            <component
              v-if="el.headline"
              :is="el.heading_tag.value || 'h2'"
              class="ts-caption-bold"
              >{{ el.headline }}</component
            >
            <p v-if="el.copy" class="ts-caption">{{ el.copy }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
.triple-list {
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
  }
  color: var(--grey-4);
  padding-top: units(3);
  padding-bottom: units(9);

  &__row {
    display: grid;
    gap: units(6);
    align-items: flex-end;
    @media (--md) {
      column-gap: units(2);
      grid-template-columns: repeat(3, 1fr);
    }

    &-title {
      &:after {
        content: "";
        display: block;
        margin-top: units(0.5);
        width: 100%;
        height: 1px;
        background: var(--grey-1);
      }
    }
    margin-bottom: units(3);
  }
  &__columns {
    display: grid;
    color: var(--grey-4);
    gap: units(6);
    @media (--md) {
      column-gap: units(2);
      grid-template-columns: repeat(3, 1fr);
    }
    &-column {
      /* border: solid 1px red; */
      position: relative;
      display: flex;
      flex-direction: column;
      gap: units(3);

      &-title {
        &:after {
          content: "";
          display: block;
          margin-top: units(0.5);
          width: 100%;
          height: 1px;
          background: var(--grey-1);
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: units(3);
    &-media {
      width: units(5);
      height: units(5);
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: units(1);
      }
    }
  }
}
</style>
