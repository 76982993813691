<script setup>
const props = defineProps({
  data: [Array, Object],
});
const dropdownSelectedValue = ref(null);

const getFileFromValue = (value) => {
  const downloads = props.data.downloads;
  const file = downloads?.find((download) => {
    return download.label === value;
  });
  // console.log(file.file[0].file, file, value, downloads);
  return file.file[0].file ? file.file[0].file : file.file[0].file_url;
};

const selectedValue = (value) => {
  dropdownSelectedValue.value = getFileFromValue(value);
};
</script>

<template>
  <div class="download-dropdown">
    <h4 class="download-dropdown__title ts-h4">{{ data.headline }}</h4>
    <!-- {{ data.downloads }} -->
    <DropdownSelect
      v-if="data.downloads"
      :options="data.downloads"
      label="Select language"
      @dropdown-value="selectedValue"
    />

    <ButtonCTA
      v-if="data.downloads"
      class="cta"
      label="Download"
      :has-icon="true"
      icon="arrowsimple"
      type="primary-outline"
      :is-download="true"
      :file-to-download="dropdownSelectedValue"
      :is-disabled="!dropdownSelectedValue"
    />
  </div>
</template>

<style lang="postcss" scoped>
.download-dropdown {
  color: var(--grey-4);

  &__title {
    margin-bottom: units(3.2);
  }
}
</style>
