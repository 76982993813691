<script setup>
</script>

<template>
  
  <svg viewBox="0 0 20 17">
    <path d="M0 15.5h20v1H0zM0 8h20v1H0zM0 .5h20v1H0z"/>
  </svg>
  
</template>

<style lang="postcss" scoped>

</style>

