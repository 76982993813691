<script setup>
const props = defineProps({
  logos: [Array, Boolean],
  call_to_action: Object,
});
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section class="page-margin section logo-wall" :class="{
    'can-run': isVisible,
  }" v-view="(e) => runIntroAnimation(e)">
    <div class="logo-wall__grid">
      <div v-for="(logo, index) in logos" class="logo-wall__item reveal" :style="`transition-delay: ${index * 0.1}s; --item-delay: ${index * 0.2
        }s`" :class="[
    logo.layout !== 'small' ? `logo-wall__item--${logo.layout}` : '',
    { 'has-url': logo.url },
  ]">
        <component class="logo-wall__item-media" :is="logo.url ? 'a' : 'span'"
          v-bind="logo.url ? { href: logo.url, target: '_blank' } : ''" :aria-label="logo.url">
          <Media v-if="logo.image" :media="{ image: logo.image }" />
        </component>
      </div>
    </div>

    <div v-if="call_to_action?.label &&
      (call_to_action?.url || call_to_action?.page_link)
      " class="logo-wall__ctas">
      <ButtonCTA type="primary" :has-icon="true" icon="arrowsimple" :cta="call_to_action" />
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.logo-wall {
  --purple-line-scale: 0;
  --grey-line-scale: 0;
  --translate-title: units(1.5);
  --opacity-title: 0;

  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
    --grey-line-scale: 1;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: units(2);
    row-gap: units(3);
    margin-bottom: units(3);
  }

  &__item {
    grid-column: span 2;
    position: relative;
    display: flex;
    justify-content: center;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background: var(--grey-1);
      scale: var(--grey-line-scale) 1;
      transition: scale 0.5s ease-in-out;
      transition-delay: var(--item-delay);
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      scale: var(--purple-line-scale) 1;
      background: var(--loud-indigo);
      transition: scale 0.3s ease-in-out;
    }

    &--large {
      grid-column: span 3;
    }

    &.has-url {
      &:hover {
        --purple-line-scale: 1;
      }
    }
  }

  &__ctas {
    display: flex;
    justify-content: center;
    padding-bottom: units(9);
  }
}
</style>
