<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import gsap from "gsap";

const props = defineProps({
  activeIndex: Number,
  images: Array,
});

const containerRef = ref(null);
const mediaElementsRef = ref([]);
const zIndexValues = ref([]);
let animationTimeline = null;
const ROTATION = 2;
const OPACITY = .9;
const SCALE = 0.8;
const X = 10;
const DURATION = 0.5;

onMounted(() => {
  createMediaStackAnimation();
});

onUnmounted(() => {
  if (animationTimeline) {
    animationTimeline.kill();
  }
});

watch(
  () => props.activeIndex,
  (newIndex, oldIndex) => {
    updateMediaStackAnimation(newIndex, oldIndex);
  }
);

function createMediaStackAnimation() {
  animationTimeline = gsap.timeline();
  mediaElementsRef.value.forEach((element, index) => {
    const zIndex = index === props.activeIndex ? 1 : 0;
    const scale = SCALE;
    const x = (index - props.activeIndex) * X;
    const rotation =
      index === props.activeIndex ? 0 : ROTATION;
    const opacity = index === props.activeIndex ? 1 : OPACITY;

    zIndexValues.value[index] = zIndex;

    animationTimeline.set(element, { zIndex });
    animationTimeline.to(element, {
      duration: DURATION,
      scale,
      x,
      rotation,
      opacity,
      ease: "power4.out",
    });
  });
}

function updateMediaStackAnimation(newIndex, oldIndex) {
  if (animationTimeline) {
    animationTimeline.kill();
  }
  animationTimeline = gsap.timeline();
  zIndexValues.value[oldIndex] = 0;
  zIndexValues.value[newIndex] = 1;

  mediaElementsRef.value.forEach((element, index) => {
    const zIndex = zIndexValues.value[index];
    const scale = SCALE;
    const x = (index - newIndex) * X;
    const rotation = index === newIndex ? 0 : (index - newIndex) * ROTATION;
    const opacity = index === newIndex ? 1 : OPACITY;

    animationTimeline.to(
      element,
      {
        duration: DURATION,
        zIndex,
        scale,
        x,
        rotation,
        opacity,
        ease: "power4.out",
      },
      0.05 * index
    );
  });
}
</script>

<template>
  <div class="media-stack" ref="container">
    <div class="media-stack__elements" ref="mediaElements">
      <div v-for="(image, index) in images" :key="'media-' + index" :class="[
        'media-stack__element',
        { 'active-media': activeIndex === index },
      ]" :ref="(element) => (mediaElementsRef[index] = element)">
        <media v-if="image" :media="{ image: image }" :cover="true" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.media-stack {

  &__element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: units(1);
    overflow: hidden;
  }
}
</style>
