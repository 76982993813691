<script setup>
const props = defineProps({
  trumpet: String,
  headline: String,
  copy: String,
  categories: [Array, Object],
});

const isLastLargeItem = (index, array) => {
  const nextItem = array[index + 1];
  if (!nextItem) return false;
  if (nextItem.layout !== "large" && array[index].layout === "large")
    return true;
};

onMounted(() => {
  const medias = document.querySelectorAll(
    ".brand-portfolio__media--last-large"
  );
  medias.forEach((item) => {
    const separator = document.createElement("div");
    separator.classList.add("separator");
    separator.style.flexBasis = "100%";
    separator.style.height = "0";
    item.parentNode.insertBefore(separator, item.nextSibling);
  });
});
</script>

<template>
  <section class="section brand-portfolio page-margin">
    <ElementsIntro v-bind="{ trumpet, headline, copy }" />
    <div
      class="brand-portfolio__category"
      v-for="(item, index) in categories"
      :key="index"
    >
      <div class="brand-portfolio__title ts-h4">
        {{ item.title }}
      </div>

      <div class="brand-portfolio__copy ts-p1">
        {{ item.copy }}
      </div>

      <div class="brand-portfolio__medias">
        <div
          v-for="(media, index) in item.logos"
          class="brand-portfolio__media ts-h4"
          :class="{
            'brand-portfolio__media--large': media.layout === 'large',
            'brand-portfolio__media--last-large': isLastLargeItem(
              index,
              item.logos
            ),
            'has-link': media.url !== '' && media.url !== null,
          }"
          :key="index"
        >
          <component
            :is="media.url ? 'a' : 'span'"
            v-bind="media.url ? { href: media.url, target: '_blank' } : ''"
          >
            <Media
              v-if="media.image"
              :media="{ image: media.image }"
              class="image"
            />
          </component>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.brand-portfolio {
  &__category {
    position: relative;
    padding-top: units(2);

    @media (--md) {
      padding-top: units(3);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      align-items: flex-start;
      grid-column-gap: units(2);
      margin: units(3) 0 units(6) 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background: var(--grey-1);
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    gap: units(2);
    align-items: flex-start;
    grid-column: 1/-1;
    margin-bottom: units(3);

    @media (--md) {
      grid-column: span 3;
    }
  }

  &__title {
    grid-column: 1/-1;
    color: var(--loud-indigo);
    margin-bottom: units(2);
  }

  &__copy {
  }

  &__medias {
    flex-wrap: wrap;
    margin-bottom: units(6);
    display: flex;
    gap: units(5);
    flex-direction: row;

    @media (--md) {
      grid-column: 5 / 13;
      flex-wrap: wrap;
    }
  }

  &:deep(.separator) {
    display: none;

    @media (--md) {
      display: block;
    }
  }

  &__media {
    flex-shrink: 0;

    &:deep(img) {
      object-fit: contain;
      height: units(3);
      width: auto;
    }

    &.has-link {
      transition: all 0.4s ease-in-out;

      &:hover {
        scale: 1.04;
      }
    }

    &--large {
      &:deep(img) {
        @media (--md) {
          object-fit: contain;
          height: units(7);
          width: auto;
        }
      }
    }
  }
}
</style>
