<script setup>
const props = defineProps({
  copy: String,
  call_to_action: Object,
});

const isArticle = inject("is-article", null);
const isOverlay = inject("is-overlay", null);
const isVisible = ref(false);

function runIntroAnimation(e) {
  e.state.isActive && e.state.progress > 0.15 ? (isVisible.value = true) : null;
}
</script>

<template>
  <section
    class="section page-margin cta-row"
    :class="{
      article: isArticle,
      'can-run': isVisible,
      'is-overlay': isOverlay,
    }"
    v-view="(e) => runIntroAnimation(e)"
  >
    <div class="cta-row__inner">
      <p v-if="copy" class="ts-p1 reveal">{{ copy }}</p>

      <ButtonCTA
        v-if="
          call_to_action.label &&
          (call_to_action.url || call_to_action.page_link)
        "
        class="btn reveal"
        icon="arrowsimple"
        :cta="call_to_action"
        type="primary"
      />
    </div>
  </section>
</template>

<style lang="postcss">
.cta-row {
  --line-scale: 0;
  --translate-title: units(1.5);
  --opacity-title: 0;
  &.can-run {
    --translate-title: 0;
    --opacity-title: 1;
    --line-scale: 1;
  }
  &.is-overlay {
    --translate-title: 0;
    --opacity-title: 1;
    --line-scale: 1;
  }
  padding-top: units(6);
  padding-bottom: units(9);
  color: var(--grey-4);
  &__inner {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: units(3);

    @media (--md) {
      width: 50%;
      text-align: center;
      align-items: center;
    }

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      scale: var(--line-scale) 1;
      position: absolute;
      top: units(-3);
      background: var(--grey-1);
      transition: scale 0.5s ease-in-out;
    }
  }

  &.article {
    width: 100%;
    .cta-row__inner {
      flex-direction: column;
      gap: units(3);
      @media (--md) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        text-align: unset;
        & p {
          width: 70%;
          text-align: left;
        }
      }
    }
  }
}
</style>
